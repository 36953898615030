export default {
    install(vm, { store }) {
        vm.prototype.$settings = {
            get(key, defaultValue) {
                return store.getters['settings/get'](key) || defaultValue;
            },
            set(key, value) {
                store.dispatch('settings/set', { key, value });
            },
        };
    },
};
