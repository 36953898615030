import type { RouteConfig } from 'vue-router';

import { TeamSidebar } from '@/layout/TeamLayout';

const userRoutes: RouteConfig[] = [
    {
        path: 'team/settings/manage-tokens',
        component: () => import('@/scenes/ManageTokens/ManageTokensScene.vue'),
        meta: { sidebar: TeamSidebar },
        props: {},
        name: 'team.settings.manageTokens',
    },
    {
        path: 'team/settings/manage-tokens/:credentialId/connections',
        component: () => import('@/scenes/ManageTokens/ManageTokenConnectionsScene.vue'),
        meta: { sidebar: TeamSidebar },
        props: true,
        name: 'team.settings.manageTokens.connections',
    },
];

export { userRoutes };
