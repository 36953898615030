<template>
    <SidebarGroup name="Client Settings" v-if="canAccessSettings">
        <template #activator>Configuration</template>

        <template #icon-left>
            <VIcon decorative name="settings-thick" />
        </template>

        <SidebarItem :key="item.name" :name="item.name" sub-item :to="item.to" v-for="item in items">
            {{ item.label }}
        </SidebarItem>
    </SidebarGroup>
</template>

<script>
import PropTypes from 'vue-types';

import UserPermissions from '@/enums/UserPermissions';
import useXavierGlobals from '@/hooks/useXavierGlobals';
import { SidebarGroup, SidebarItem } from '@/layout/Sidebar';
import { hasPermission } from '@/utils/permissions';

import useBaseGroup from '../hooks/useBaseGroup';

export default {
    name: 'ClientSidebarGroupSettings',

    components: { SidebarGroup, SidebarItem },

    props: {
        client: PropTypes.object.isRequired,
    },

    setup(props) {
        const { createItems, isInsightSupported } = useBaseGroup(props.client, props.reportMode, { query: null });
        const canAccessSettings = hasPermission(UserPermissions.ACCESS_CLIENT_SETTINGS);

        return {
            canAccessSettings,
            createItems,
            isInsightSupported,
        };
    },

    computed: {
        items() {
            const globalItems = [
                {
                    name: 'Client General Settings',
                    label: 'General',
                    to: { name: 'client.settings.general' },
                },
                {
                    name: 'Client Visibility Settings',
                    label: 'Visibility',
                    to: { name: 'client.settings.visibility' },
                },
            ];

            let additionalItems = [];

            if (this.client.hasHealthScore) {
                additionalItems = [
                    {
                        name: 'Client Overview Settings',
                        label: 'Overview Configuration',
                        to: { name: 'client.settings.overviewConfiguration' },
                    },
                    {
                        name: 'Client Tax & Control Accounts Settings',
                        label: 'Tax & Control Accounts',
                        to: { name: 'client.settings.accountConfiguration' },
                    },
                    this.client.provider === 'quickbooks' &&
                        this.isInsightSupported('unreconciled-transactions') && {
                            name: 'Client Reconciliation Config',
                            label: 'Reconciliation Configuration',
                            to: { name: 'client.settings.reconciliationConfiguration' },
                        },
                ];
            } else {
                additionalItems = [
                    {
                        name: 'Client Company & Tax Information',
                        label: 'Company & Tax Information',
                        to: { name: 'client.settings.unintegratedTaxConfiguration' },
                    },
                ];
            }

            return this.createItems([...globalItems, ...additionalItems]);
        },
    },
};
</script>
