<template>
    <ModalBase
        :bubble-close="true"
        :dismissable="dismissable"
        ref="base"
        :size="size"
        :title="title"
        @close="requestClose"
    >
        <template slot="content">
            <div class="modal-body">
                <slot name="body" />
            </div>
            <div class="modal-footer" v-if="$slots.footer">
                <slot name="footer" />
            </div>
        </template>
    </ModalBase>
</template>

<script>
import ModalBase from '@/components/ModalBase.vue';

export default {
    components: {
        ModalBase,
    },
    props: {
        title: { type: String, default: null },
        size: { type: String, default: null },
        bubbleClose: { type: Boolean, default: false },
        dismissable: { type: Boolean, default: true },
    },
    methods: {
        show() {
            this.$refs.base.show();
        },
        close() {
            this.$refs.base.close();
        },
        requestClose() {
            if (this.bubbleClose) {
                this.$emit('close');
            } else {
                this.close();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import 'style/dext/includes';

.modal-body,
.modal-footer {
    padding: 0;
}

.modal-body {
    color: get-color(charcoal, lite);
    margin-bottom: 22px;
    margin-top: 22px;
}
</style>
