import isString from 'lodash-es/isString';

export default function useCreateItems(baseRoute = {}) {
    const augmentRouteItem = (routeItem) => {
        if (!routeItem) return routeItem;

        return {
            ...routeItem,
            to: isString(routeItem.to) ? { ...baseRoute, path: routeItem.to } : { ...baseRoute, ...routeItem.to },
        };
    };

    return function (items = []) {
        const augmentedItems = items.map(augmentRouteItem).filter((item) => item);

        return Object.freeze(augmentedItems);
    };
}
