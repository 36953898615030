<template>
    <div class="dext-dropdown" @click="toggle" v-click-outside="away">
        <slot name="toggler">
            <VButton class="dext-dropdown-toggle" name="Dext Account Switcher" secondary>
                Toggle
                <template #icon-right>
                    <VIcon
                        color="lightgray"
                        decorative
                        :name="'dext-arrow-down-filled'"
                        :size="20"
                        :vertical-align="false"
                    />
                </template>
            </VButton>
        </slot>
        <slot />
    </div>
</template>

<script>
import ClickOutside from '@/directives/clickOutside';

export default {
    name: 'Dropdown',
    directives: { ClickOutside },
    provide() {
        return {
            sharedState: this.sharedState,
        };
    },
    data() {
        return {
            sharedState: {
                active: false,
            },
        };
    },
    methods: {
        toggle() {
            this.sharedState.active = !this.sharedState.active;
        },
        away() {
            this.sharedState.active = false;
        },
    },
};
</script>
<style lang="scss" scoped>
@import 'style/dext/includes';

.dext-dropdown {
    display: inline-block;
    position: relative;
}

.dext-dropdown-toggle {
    background: get-color(silver, medium);
    font-size: pxtoem(16);
    font-weight: $font-weight-bold;
}
</style>
