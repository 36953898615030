<template>
    <Component :is="params.component" :params="params" />
</template>

<script>
import { defineComponent } from 'vue';

/**
 * This file exists because AG Grid removed the ability to use
 * `cellRendererFramework` as custom cell renderers, thus leaving us with no
 * dynamic rendering and ESLint errors for unused components.
 *
 * A workaround was created by someone dealing with this exact same issue, and
 * this has been adapted a little bit to fit our needs.
 *
 * Specifically not a TS file since AG Grid doesn't understand what's happening
 * in Vue 2 with the Vue 3 syntax.
 *
 * @link https://github.com/ag-grid/ag-grid/issues/5245
 * @link https://codesandbox.io/s/ag-grid-component-render-cll8en
 */
export default defineComponent({
    name: 'AgGridComponentRenderer',
});
</script>
