<template>
    <div ref="listContainer" :style="innerList" class="container-fluid">
        <div class="list-pad">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ListContainer',
    props: {
        minHeight: { type: Number, default: null },
    },
    data() {
        return {
            windowHeight: window.innerHeight,
            listTop: 0,
        };
    },
    computed: {
        innerList() {
            let styling = {
                maxHeight: `${this.windowHeight - this.listTop}px`,
                overflowY: 'auto',
                padding: '15px 10px 0',
            };

            if (this.minHeight) {
                styling.minHeight = `${this.minHeight}px`;
            }

            return styling;
        },
    },
    created() {
        this.$nextTick(() => this.setListTop());
    },
    mounted() {
        window.addEventListener('resize', this.handleScreenResize);
    },
    methods: {
        handleScreenResize(event) {
            this.windowHeight = event.currentTarget.innerHeight;

            this.setListTop();
        },
        setListTop() {
            const topNavHeight = 55;

            this.listTop = this.$refs.listContainer ? this.$refs.listContainer.offsetTop + topNavHeight : 0;
        },
    },
};
</script>

<style lang="scss" scoped>
.list-pad {
    padding-bottom: 60px;
}
</style>
