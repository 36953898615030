<template>
    <div :class="[pullRight ? 'pull-right' : 'pull-left', 'dext-dropdown-content']" v-if="active">
        <slot />
    </div>
</template>

<script>
import ClickOutside from '@/directives/clickOutside';

export default {
    name: 'DropdownContent',
    props: {
        pullRight: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    directives: { ClickOutside },
    inject: ['sharedState'],
    computed: {
        active() {
            return this.sharedState.active;
        },
    },
};
</script>
<style lang="scss" scoped>
@import 'style/dext/includes';

.pull-left {
    left: 0;
    right: auto;
}

.pull-right {
    left: auto;
    right: 0;
}

.dext-dropdown-content {
    @include shadow($size: large);

    background: $color-white;
    border-bottom: 1px solid get-color(silver, medium);
    border-radius: pxtoem(4);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 10%);
    display: flex;
    flex-direction: column;
    position: absolute;
    width: pxtoem(280);
    z-index: 30;
}
</style>
