module.exports = {
    props: ['user', 'team'],

    /**
     * The component's data.
     */
    data() {
        return {
            form: new SparkForm({
                from: '',
                to: '',
                fromError: false,
                toError: false,
            }),
        };
    },
    computed: {

    },

    mounted() {

    },

    methods: {
        checkForm() {
            if (!this.form.from || this.form.from === undefined) {
                this.form.fromError = true;
            }

            if (!this.form.to || this.form.to === undefined) {
                this.form.toError = true;
            }

            return this.form.from && this.form.to;
        },

        triggerDownload(filename, data) {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');

            link.href = url;

            link.setAttribute('download', filename);

            document.body.appendChild(link);
            link.click();
        },

        generateBilling() {
            let url = `/settings/${window.Spark.teamsPrefix}/${this.team.id}/client-activity/${this.form.from}/${this.form.to}`;

            if (this.checkForm()) {
                this.form.busy = true;

                try {
                    window.axios.get(url, {
                        responseType: 'arraybuffer',
                    }).then(response => {
                        this.triggerDownload('Client Billing Report.xlsx', response.data);
                    });

                    this.$toaster.success('Client Activity Report generated successfully');
                } catch (ex) {
                    this.$toaster.success('Something went wrong. Please try again');
                } finally {
                    this.form.fromError = false;
                    this.form.toError = false;
                    this.form.busy = false;
                }
            }
        },
    }
};
