<template>
    <VButton primary @click="onClickHandle">
        <template #icon-left>
            <VIcon class="sync-icon" name="sync" :spin="isLoading" :vertical-align="false" />
        </template>

        {{ isLoading ? 'Reconnecting...' : `Reconnect with Xero` }}
    </VButton>
</template>

<script setup lang="ts">
import { ref } from 'vue';

import useXavierGlobals from '@/hooks/useXavierGlobals';
import ClientService from '@/services/Api/ClientService';
import { useRequestsStore } from '@/store/requests';

type Props = {
    clientSlug: string;
};

const props = defineProps<Props>();

const requestStore = useRequestsStore();

const isLoading = ref<boolean>(false);

async function onClickHandle() {
    isLoading.value = true;

    const practiceCrn = useXavierGlobals().currentTeam.rbExternalId;
    const result = await requestStore.makeRequest(() =>
        ClientService.getXeroClientReconnectUrl(practiceCrn, props.clientSlug)
    );

    isLoading.value = false;

    window.location.href = result.data;
}
</script>

<style lang="scss" scoped>
@import 'style/dext/includes';

.sync-button-container {
    display: inline-flex;
}

.sync-button--alert {
    color: get-color(orange) !important;
}
</style>
