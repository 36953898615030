<template>
    <PoseTransition>
        <BoxContainer class="dismiss-all-box">
            <slot />
        </BoxContainer>
    </PoseTransition>
</template>

<script>
import posed, { PoseTransition } from 'vue-pose';

export default {
    components: {
        PoseTransition,
        BoxContainer: posed.div({
            enter: { top: 'auto', transition: { duration: 200 } },
            exit: { top: '100%', transition: { duration: 200 } },
        }),
    },
};
</script>

<style lang="scss" scoped>
@import 'style/dext/includes';

.dismiss-all-box {
    background-color: get-color(amber);
    bottom: 0;
    box-shadow: 0 0 15px 0 #00000050;
    display: flex;
    flex-direction: column;
    height: 90px;
    padding: 1.3em;
    place-content: center center;
    position: fixed;
    right: 0;
    text-align: right;
    width: 100%;
}

.dismiss-all-gap.p-3 {
    padding-bottom: 5em !important;
}
</style>
