<template>
    <div class="system-bars" data-qa="page-layout-system-bars-footer">
        <SystemBar bottom type="error" v-if="$xavier.isImpersonating">
            You are currently impersonating a user

            <template v-slot:actions>
                <VButton
                    href="/spark/kiosk/users/stop-impersonating"
                    link
                    name="stop-impersonating"
                    outline
                    primary
                    small
                >
                    Stop Impersonating
                </VButton>
            </template>
        </SystemBar>
    </div>
</template>

<script>
import SystemBar from '@/components/SystemBar';

export default {
    name: 'SystemBarsFooter',

    components: {
        SystemBar,
    },
};
</script>

<style lang="scss" scoped>
.system-bars {
    width: 100%;
}
</style>
