const providerSupportedInsights = {
    xero: window.Xavier.xeroInsights,
    quickbooks: window.Xavier.qboInsights,
    companies_house: [],
    hmrc: [],
    unintegrated: [],
    playground: window.Xavier.playgroundInsights,
};

export function isInsightSupported(provider, insightName) {
    const supportValue = providerSupportedInsights[provider];

    if (!supportValue) {
        throw 'Cannot determine supported insights: Unknown provider';
    }

    return supportValue.includes(insightName);
}
