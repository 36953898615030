import type { PluginObject } from 'vue';
import pluralize from 'pluralize';

import { round } from '@/utils/number';
import { ago, format as formatDate } from '@/utils/date';

pluralize.addPluralRule(/is$/i, 'are');

function number(value: number, decimals = 2) {
    return round(value, decimals).toLocaleString();
}

function plural(text: string, count: number) {
    return pluralize(text, count);
}

const filtersPolyfillPlugin: PluginObject<never> = {
    install(Vue) {
        Vue.prototype.$filters_LEGACY = {
            ago,
            date: formatDate,
            number,
            plural,
        };
    },
};

export { filtersPolyfillPlugin };
