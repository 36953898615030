<template>
    <div class="action-container">
        <template v-if="isClientRoute && client && client.isIntegrated">
            <ReconnectXeroClientButton v-if="isUnlinkedXeroClient" :client-slug="client.slug" />
            <SyncButton v-if="!isUnlinkedXeroClient" ref="syncButtonRef" :client-id="client.id" mode="all">
                <template #activator="{ attrs, isSyncing, on }">
                    <VButton v-bind="attrs" name="Sync Client - Top Nav" primary v-on="on">
                        <template #icon-left>
                            <VIcon class="sync-icon" name="sync" :spin="isSyncing" :vertical-align="false" />
                        </template>
                        <template v-if="isQuickbooksClient">
                            <template v-if="client.linked">
                                {{ isSyncing ? 'Syncing...' : `Sync with ${client.providerName}` }}
                            </template>
                            <template v-else>
                                {{ isSyncing ? 'Syncing...' : `Reconnect with ${client.providerName}` }}
                            </template>
                        </template>
                        <template v-else>
                            {{ isSyncing ? 'Syncing...' : `Sync with ${client.providerName}` }}
                        </template>
                    </VButton>
                </template>
            </SyncButton>
            <span class="last-sync text-muted">Last Sync: {{ lastSync }}</span>
        </template>

        <template v-else-if="isTeamRoute">
            <CreateClientButton primary />
        </template>
    </div>
</template>

<script>
import moment from 'moment';
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import CreateClientButton from '@/components/ClientList/CreateClientButton.vue';
import SyncButton from '@/components/SyncButton';
import ReconnectXeroClientButton from '@/components/SyncButton/ReconnectXeroClientButton.vue';
import { useProductTourTemplateRefs } from '@/composables/tours/useProductTourTemplateRefs';
import ClientProvider from '@/enums/ClientProvider';

export default defineComponent({
    name: 'TopNavActions',

    components: { ReconnectXeroClientButton, CreateClientButton, SyncButton },

    setup() {
        const { syncButtonRef } = useProductTourTemplateRefs();

        return { syncButtonRef };
    },
    computed: {
        ...mapGetters({
            client: 'legacyClients/currentClient',
        }),
        /**
         * When feature flag enabled and current xero clients do not have valid credentials
         */
        isUnlinkedXeroClient() {
            return this.client.provider === ClientProvider.XERO && !this.client.linked;
        },
        isQuickbooksClient() {
            return this.client.provider === ClientProvider.QUICKBOOKS;
        },
        isClientRoute() {
            return Boolean(this.$route.name?.match(/client\./));
        },
        isTeamRoute() {
            return Boolean(this.$route.name?.match('team*'));
        },
        lastSync() {
            if (this.client.lastSuccessfulImport) {
                return moment(this.client.lastSuccessfulImport).fromNow();
            }

            return 'Never';
        },
    },
});
</script>

<style lang="scss" scoped>
@import 'style/dext/includes';

.action-container {
    align-items: center;
    display: flex;
    margin-right: 8px;
}

.last-sync {
    font-size: pxtoem(13);
    margin-left: 10px;
}
</style>
