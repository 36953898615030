<template>
    <div class="notification-wrapper">
        <Component
            :is="notification.component"
            v-bind="notification.props"
            :key="notification.key"
            @completed="destroyNotification(notification.key)"
            v-for="notification in notifications"
        />
    </div>
</template>

<script>
import FlowCreation from './Notifications/FlowCreation.vue';
import FixSuggestions from './Notifications/FixSuggestions.vue';

const notificationMap = {
    flowCreationStarted: FlowCreation,
    fixSuggestionStarted: FixSuggestions,
};

export default {
    name: 'NotificationManager',
    data() {
        return {
            notifications: [],
        };
    },
    created() {
        Object.entries(notificationMap).map(([key, value]) =>
            window.Bus.$on(key, (payload) => {
                const notificationId = Symbol();

                this.notifications.push({
                    key: notificationId,
                    component: value,
                    props: {
                        notificationId,
                        ...payload,
                    },
                });
            })
        );
    },
    methods: {
        destroyNotification(id) {
            const index = this.notifications.findIndex((y) => y.key === id);

            this.notifications.splice(index, 1);
        },
    },
};
</script>

<style lang="scss" scoped>
.notification-wrapper {
    align-items: flex-end !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: fixed;
    flex-direction: column;
    bottom: 16px;
    right: 16px;
}
</style>
