<template>
    <PrecisionAppProviders>
        <PageLayout>
            <RouterView :key="$route.path" />
        </PageLayout>
    </PrecisionAppProviders>
</template>

<script setup lang="ts">
import PrecisionAppProviders from '@/App/PrecisionAppProviders.vue';

import PageLayout from '../PageLayout/PageLayout.vue';
</script>

<script lang="ts">
export default { name: 'AdminLayout' };
</script>
