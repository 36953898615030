<template>
    <ErrorLayout v-bind="$attrs">
        <template #title>Something went wrong... (code 500)</template>

        <template #image>
            <img
                alt="Laptop computer with a sad face emoji below a cloud, with a jumbled wire connecting them together."
                src="/img/500-computer.svg"
            />
        </template>

        <p>An unknown error has occurred.</p>
        <p>
            Go back to the
            <DButton link name="Error 404 link to home" text :to="{ name: 'team.home' }">homepage</DButton>.
        </p>
        <p>For more help, <DButton text @click="showChat">contact us</DButton>.</p>
    </ErrorLayout>
</template>

<script setup lang="ts">
import { DButton } from '@/components/buttons';
import { useContactSupport } from '@/composables/useContactSupport';
import { ErrorLayout } from '@/layout/ErrorLayout';

const { showChat } = useContactSupport();
</script>

<script lang="ts">
export default { name: 'Error500Internal' };
</script>
