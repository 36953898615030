<template>
    <div :class="{ selected: selected }">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'DropdownItem',
    props: {
        selected: {
            default: false,
            required: false,
            type: Boolean,
        },
    },
};
</script>

<style lang="scss" scoped>
@import 'style/dext/includes';

.selected {
    background: get-color(orange, lite);
    cursor: default;
    pointer-events: none;
}
</style>
