<template>
    <nav class="sidebar" data-qa="sidebar">
        <div class="sidebar__content">
            <div class="sidebar__prepend" v-if="hasPrependItems">
                <slot name="prepend" />
            </div>

            <SimpleBar class="sidebar__main simplebar-light fs-unmask">
                <slot />
            </SimpleBar>

            <div class="sidebar__append" v-if="hasAppendItems">
                <slot name="append" />
            </div>
        </div>
    </nav>
</template>

<script>
import { defineComponent } from 'vue';
import SimpleBar from 'simplebar-vue';

import useHasSlot from '@/hooks/useHasSlot';

export default defineComponent({
    name: 'Sidebar',

    components: {
        SimpleBar,
    },

    setup(props, context) {
        const hasAppendItems = useHasSlot('append', context);
        const hasPrependItems = useHasSlot('prepend', context);

        return {
            hasAppendItems,
            hasPrependItems,
        };
    },
});
</script>

<style lang="scss" scoped>
@import 'style/dext/includes';

.sidebar {
    background-color: get-color(xavier-custom, blue-dark);
    color: $color-white;
    height: 100%;
    overflow: hidden;
    position: relative;
    width: $sidebar-width;
}

.sidebar__content {
    display: flex;
    flex-direction: column;
    inset: 0;
    padding-bottom: 100px;
    position: absolute;
}

.sidebar__prepend {
    border-bottom: 1px solid get-color(xavier-custom, blue-gray);
}

.sidebar__append {
    border-bottom: 1px solid get-color(xavier-custom, blue-gray);
    border-top: 1px solid get-color(xavier-custom, blue-gray);
}

.sidebar__main {
    flex: 1;
    height: 100%;
    overflow-x: hidden;
}
</style>
