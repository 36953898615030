module.exports = {
    props: ['user'],

    /**
     * The component's data.
     */
    data() {
        return {
            closeAccountForm: new SparkForm({})
        };
    },

    computed: {
        /**
         * Get the URL for closing an account.
         */
        urlForClosing() {
            return `/user/${this.user.id}`;
        }
    },

    methods: {
        /**
         * Approve closing the user account.
         */
        approveClosingAccount() {
            $('#modal-close-account').modal('show');
        },

        /**
         * Close the account.
         */
        closeAccount() {
            Spark.delete(this.urlForClosing, this.closeAccountForm)
                .then(() => {
                    Bus.$emit('updateUser');
                    Bus.$emit('updateTeams');

                    $('#modal-close-account').modal('hide');
                })
                .catch((e) => {
                    $('#modal-close-account').modal('hide');

                    swal({
                        title: 'Something went wrong',
                        text:
                            e.response?.data?.message ??
                            'There was a problem closing your account. Please try again, and contact us if the problem persists.',
                        icon: 'error',
                        dangerMode: true,
                    });
                });
        },
    }
};
