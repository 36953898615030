<template>
    <Transition name="notification" mode="out-in">
        <div class="notification-group">
            <div class="notification">
                <slot name="content" />
            </div>
        </div>
    </Transition>
</template>

<script>
export default {
    name: 'BaseNotification',
};
</script>

<style lang="scss" scoped>
@import 'style/dext/includes';

.notification-group {
    align-items: flex-end !important;
    background-color: #fff;
    border: 1px solid #ebeef5;
    border-radius: 4px;
    box-shadow: 1px 11px 22px 0 #7a6e8790;
    box-sizing: border-box;
    display: flex;
    margin: 10px 8px 10px 13px;
    overflow: hidden;
    padding: 14px 26px 14px 13px;
    transition: all 0.5s ease-in-out;
    width: 330px;
}
</style>
