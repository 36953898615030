// Add images here which are used in the SPA

const preload = [
    '/img/logo.svg',
    '/img/dext-confetti.svg',
    '/img/dext-thumbs-up.svg',
    '/img/fdandhatch.svg',
    '/img/abacus.svg',
    '/img/dext-add-flow.svg',
];

for (const source of preload) {
    let image = new Image();

    image.src = source;
}
