<template>
    <div>
        <input class="form-control mb-2" placeholder="Search" v-model="filter" />

        <ul
            :class="[
                'list-unstyled bg-white p-0 mb-0 simple-item-list',
                flowCreator ? 'list-view' : longList ? 'long-list' : 'short-list',
            ]"
        >
            <li
                class="px-3 py-3 reveal d-flex align-items-center"
                :key="index"
                @click.prevent="$emit('clicked', item)"
                v-for="(item, index) in filteredItems"
            >
                <span class="flex-grow-1" v-if="useItemComponent">
                    <slot :item="item" name="itemComponent" />
                </span>
                <span class="flex-grow-1" v-else-if="tags">{{ item.name }} ({{ item.value }})</span>
                <span class="flex-grow-1" v-else v-html="titleText(item)" />
                <span
                    :class="[
                        alwaysRevealTarget ? '' : 'reveal-target',
                        'float-right pl-2',
                        actionTextColour ? 'text-' + actionTextColour : '',
                    ]"
                    v-if="actionText"
                >
                    <span v-if="actionIconName">
                        <VIcon :name="actionIconName" />
                    </span>
                    {{ actionText }}
                </span>
            </li>
            <!-- add no further options available -->
            <li class="px-3 py-1 nohover" v-if="!filteredItems.length">No results found for your search criteria.</li>
        </ul>
    </div>
</template>

<script>
import { encode } from 'html-entities';
import * as PropTypes from 'vue-types';

export default {
    name: 'SimpleList',
    props: {
        items: { type: Array, required: true },
        actionText: { type: String, default: null },
        actionIconName: { type: String, default: null },
        actionTextColour: { type: String, default: null },
        useItemComponent: { type: Boolean, default: false },
        longList: { type: Boolean, default: false },
        flowCreator: { type: Boolean, default: false },
        titleText: {
            type: Function,
            default: (item) => {
                return encode(item.name);
            },
        },
        searchFilter: {
            type: Function,
            default: (searchText) => {
                return (item) => {
                    return item.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1;
                };
            },
        },
        sortItems: { type: Function, default: (a, b) => a.name.localeCompare(b.name) },
        tags: { type: Boolean, default: false },
        alwaysRevealTarget: { type: Boolean, default: false },
    },
    data() {
        return {
            filter: '',
        };
    },
    computed: {
        filteredItems() {
            const filtered = this.filter ? this.items.filter(this.searchFilter(this.filter)) : this.items;

            return filtered.sort(this.sortItems);
        },
    },
};
</script>

<style lang="scss" scoped>
@import 'style/dext/includes';

.simple-item-list {
    border: 1px solid #b7b0bf70;
    box-shadow: inset 0 0 10px -8px rgba(0, 0, 0, 44%);
    overflow-y: scroll;

    & > li {
        color: get-color(charcoal, lite) !important;
        transition:
            color 0.15s ease-in-out,
            background-color 0.15s ease-in-out;
    }

    & > li:nth-child(even) {
        background-color: get-color(silver, lite);
    }

    & > li:hover:not(.nohover) {
        background-color: get-color(silver, medium) !important;
        color: get-color(blue) !important;
        cursor: pointer;
    }
}

.list-view {
    max-height: calc(75vh - 150px);
}

.long-list {
    height: 300px;
}

.short-list {
    max-height: 160px;
    min-height: 50px;
}

.text-danger {
    color: #dd4822 !important;
}
</style>
