<template>
    <SidebarItem :class="syncButtonClass" name="Sync Button" no-action>
        <SyncButton class="sync-button fs-unmask" :client-id="client.id" mode="all">
            <template #activator="{ attrs, isSyncing, on }">
                <VButton
                    v-bind="attrs"
                    alt
                    block
                    class="sync-button__custom"
                    color="primary"
                    name="Sync Client - New Sidebar"
                    outline
                    small
                    v-on="on"
                >
                    <template #icon-left>
                        <VIcon class="sync-icon" decorative name="sync" :spin="isSyncing" :vertical-align="false" />
                    </template>
                    {{ isSyncing ? 'Syncing...' : `Sync with ${client.providerName}` }}
                </VButton>
            </template>
        </SyncButton>
        <div class="last-sync">Last Sync: {{ lastSync }}</div>
    </SidebarItem>
</template>

<script lang="ts">
import moment from 'moment';
import { computed, defineComponent } from 'vue';
import * as PropTypes from 'vue-types';

import SyncButton from '@/components/SyncButton';
import { SidebarItem } from '@/layout/Sidebar';

import type { Client } from '@/store/modules/client/types/Client';

export default defineComponent({
    name: 'SidebarSyncButton',

    props: {
        client: PropTypes.object<Client>().isRequired,
    },

    components: {
        SidebarItem,
        SyncButton,
    },

    setup(props) {
        const lastSync = computed(() => {
            if (props.client.lastSuccessfulImport) {
                return moment(props.client.lastSuccessfulImport).fromNow();
            }

            return 'Never';
        });

        const syncButtonClass = computed(() => {
            return {
                'sidebar-item--sync-button': true,
                'long-provider': props.client.providerName.length > 12,
            };
        });

        return {
            lastSync,
            syncButtonClass,
        };
    },
});
</script>

<style lang="scss" scoped>
@import 'style/dext/includes';

.sync-button {
    width: 100%;
}

.long-provider {
    padding-left: 9px;
}

.last-sync {
    font-size: pxtoem(12);
    margin-top: 5px;
    opacity: 0.8;
    text-align: center;
    width: 100%;
}
</style>
