<template>
    <div class="editor">
        <EditorMenuBubble
            v-if="editable"
            v-slot="{ commands, isActive, getMarkAttrs, menu }"
            class="menububble"
            :editor="editor"
            @hide="hideLinkMenu"
        >
            <div
                class="menububble"
                :class="{ 'is-active': menu.isActive }"
                :style="`left: ${menu.left}px; bottom: ${menu.bottom}px;`"
            >
                <form
                    v-if="linkMenuIsActive"
                    class="menububble__form"
                    @submit.prevent="setLinkUrl(commands.link, linkUrl)"
                >
                    <input
                        ref="linkInput"
                        v-model="linkUrl"
                        class="menububble__input"
                        placeholder="https://"
                        type="text"
                        @keydown.esc="hideLinkMenu"
                    />
                    <button class="menububble__button" type="button" @click="setLinkUrl(commands.link, null)">
                        <VIcon name="delete-filled" />
                    </button>
                </form>

                <template v-else>
                    <button
                        class="menububble__button"
                        :class="{ 'is-active': isActive.link() }"
                        @click="showLinkMenu(getMarkAttrs('link'))"
                    >
                        <span>{{ isActive.link() ? 'Update Link' : 'Add Link' }}</span>
                        <VIcon class="ml-2" name="link-thick" />
                    </button>
                </template>
            </div>
        </EditorMenuBubble>

        <EditorContent :class="['editor__content', { editable: editable }]" :editor="editor" />
    </div>
</template>

<script>
import { Editor, EditorContent, EditorMenuBubble, Node, Text, Doc } from 'tiptap';
import { Link } from 'tiptap-extensions';

// Overriding default paragraph to use div instead of p
// https://github.com/scrumpy/tiptap/issues/291
class Paragraph extends Node {
    get name() {
        return 'paragraph';
    }

    get schema() {
        return {
            content: 'inline*',
            draggable: false,
            group: 'block',
            parseDOM: [
                {
                    tag: 'div',
                },
            ],
            toDOM() {
                return ['div', 0];
            },
        };
    }
}

export default {
    name: 'RichTextBox',
    components: {
        EditorContent,
        EditorMenuBubble,
    },
    props: {
        value: { type: [Object, String], default: undefined },
        editable: { type: Boolean, default: true },
    },
    data() {
        return {
            editor: null,
            linkUrl: null,
            linkMenuIsActive: false,
        };
    },
    watch: {
        value(newValue) {
            this.editor.setContent(newValue);
        },
    },
    mounted() {
        this.editor = new Editor({
            useBuiltInExtensions: false,
            extensions: [new Doc(), new Text(), new Paragraph(), new Link()],
            content: this.value,
            editable: this.editable,
            onUpdate: ({ getJSON }) => {
                this.$emit('input', getJSON());
            },
        });
    },
    beforeDestroy() {
        this.editor.destroy();
    },
    methods: {
        showLinkMenu(attrs) {
            this.linkUrl = attrs.href;
            this.linkMenuIsActive = true;
            this.$nextTick(() => {
                this.$refs.linkInput.focus();
            });
        },
        hideLinkMenu() {
            this.linkUrl = null;
            this.linkMenuIsActive = false;
        },
        setLinkUrl(command, url) {
            command({ href: url });
            this.hideLinkMenu();
        },
    },
};
</script>

<style lang="scss">
@import 'style/dext/includes';

.menububble {
    background: get-color(blue, dark);
    border-radius: 5px;
    display: flex;
    margin-bottom: 0.5rem;
    opacity: 0;
    padding: 0.3rem;
    position: absolute;
    transform: translateX(-50%);
    transition:
        opacity 0.2s,
        visibility 0.2s;
    visibility: hidden;
    z-index: 20;

    &.is-active {
        opacity: 1;
        visibility: visible;
    }

    &__button {
        background: transparent;
        border: 0;
        border-radius: 3px;
        color: white;
        cursor: pointer;
        display: inline-flex;
        margin-right: 0.2rem;
        padding: 0.2rem 0.5rem;

        &:last-child {
            margin-right: 0;
        }

        &:hover {
            background-color: rgba(white, 0.1);
        }

        &.is-active {
            background-color: rgba(white, 0.2);
        }
    }

    &__form {
        align-items: center;
        display: flex;
    }

    &__input {
        background: transparent;
        border: none;
        color: white;
        font: inherit;
    }
}

.editor {
    margin: 0 auto;
    position: relative;

    &__content {
        overflow-wrap: break-word;
        word-break: break-word;
        word-wrap: break-word;

        * {
            caret-color: currentcolor;
        }

        a {
            margin-left: 3px;
            margin-right: 3px;
        }

        div:focus {
            outline: none;
        }
    }

    &__content.editable {
        background-color: white;
        border: 1px solid #ccc;
        border-radius: 4px;
        padding: 6px 10px;
    }
}
</style>
