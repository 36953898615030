<template>
    <ValidationProvider
        ref="validationProviderRef"
        v-slot="validationSlotProps"
        :mode="validationMode"
        :name="validationName || name"
        :rules="rules"
        slim
        :vid="vid"
    >
        <DTextAreaField
            :id="id"
            v-model="currentValue"
            :autogrow="autogrow"
            :autogrow-max-lines="autogrowMaxLines"
            v-bind="attrs"
            :error-messages="validationSlotProps.errors"
            :hint="hint"
            :label="label"
            :status="status"
            v-on="$listeners"
        >
            <template v-for="(_, slotName) in slots" :slot="slotName">
                <slot :name="slotName" v-bind="validationSlotProps" />
            </template>
        </DTextAreaField>
    </ValidationProvider>
</template>

<script setup lang="ts">
import { ValidationProvider } from 'vee-validate';
import { ref, useSlots } from 'vue-demi';

import { DTextAreaField } from '@/components/DextUi/DForm';

import type { ValidationInteractionMode } from '../Form.types';

import { useInput } from '../composables/useInput';

interface Props {
    autogrow?: boolean;
    autogrowMaxLines?: string | number;
    disabled?: boolean;
    errorMessages?: string | string[];
    id?: string;
    label?: string;
    loading?: boolean;
    hint?: string;
    name: string;
    qaName: string;
    rules?: string | object;
    status?: 'error' | 'success' | 'warning';
    validationMode?: ValidationInteractionMode;
    validationName?: string;
    value?: string;
    vid?: string;
}

const props = withDefaults(defineProps<Props>(), {
    autogrowMaxLines: undefined,
    disabled: false,
    errorMessages: undefined,
    id: undefined,
    label: undefined,
    loading: false,
    hint: undefined,
    qaName: undefined,
    status: undefined,
    value: '',
    rules: '',
    validationMode: 'eager',
    validationName: undefined,
    vid: undefined,
});

const slots = useSlots();
const validationProviderRef = ref<InstanceType<typeof ValidationProvider>>();
const { attrs, currentValue } = useInput(props.name, props);

defineExpose({ validationProviderRef });
</script>
