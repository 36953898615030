<template>
    <Menu v-bind="attrs" name="Client Switcher">
        <template #activator="{ on, attrs: menuAttrs }">
            <SidebarItem v-bind="menuAttrs" name="Switch Team" v-on="on">
                <template v-if="currentTeam">
                    {{ currentTeam.name }}
                    <div class="sidebar-item-subtitle" v-if="currentTeam.name && teams.length > 1">Switch team</div>
                </template>

                <template #actions v-if="teams.length > 1">
                    <VIcon decorative name="exchange-thick" />
                </template>

                <Spinner basic :centered="false" v-if="!currentTeam.name" />
            </SidebarItem>
        </template>

        <MenuItemsFilterable
            :alignment="{ horizontal: 'left', vertical: 'top' }"
            detach
            inline
            :items="teamList"
            :options="{ threshold: 0 }"
            :search-keys="['name']"
        >
            <template #prepend>
                <MenuTitle>Switch Team</MenuTitle>
            </template>

            <template #item="{ item: team }">
                <MenuItem
                    :disabled="team.id === currentTeam.id"
                    :href="`/settings/teams/${team.id}/switch`"
                    name="Switch Team"
                >
                    {{ team.name }}
                    <DBadge class="current-team-badge" variant="processing" v-if="team.id === currentTeam.id">
                        current
                    </DBadge>
                </MenuItem>
            </template>
        </MenuItemsFilterable>
    </Menu>
</template>

<script>
import PropTypes from 'vue-types';
import { computed, defineComponent } from 'vue';

import { DBadge } from '@/components/Badge';
import { Menu, MenuItem, MenuItemsFilterable, MenuSection, MenuTitle } from '@/components/Menu';
import Spinner from '@/components/Spinner';
import { SidebarItem } from '@/layout/Sidebar';
import omit from 'lodash-es/omit';

export default defineComponent({
    components: {
        DBadge,
        Menu,
        MenuItem,
        MenuItemsFilterable,
        MenuSection,
        MenuTitle,
        SidebarItem,
        Spinner,
    },

    props: {
        ...Menu.props,
        teams: PropTypes.array.isRequired,
        currentTeam: PropTypes.object.isRequired,
    },

    setup(props, context) {
        const isDisabled = computed(() => {
            return props.disabled || props.teams.length <= 1;
        });

        const mapTeamData = (team) => ({
            id: team.id,
            name: team.name,
            slug: team.slug,
        });

        const sortTeams = (a, b) => {
            if (a.id === props.currentTeam.id) return -1;
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;

            return 0;
        };

        const attrs = computed(() => {
            return {
                ...context.attrs,
                ...omit(props, ['teams', 'currentTeam']),
                closeOnContentClick: true,
                disabled: isDisabled.value,
            };
        });

        const teamList = computed(() => {
            return props.teams.map(mapTeamData).sort(sortTeams);
        });

        return {
            attrs,
            teamList,
        };
    },
});
</script>

<style lang="scss" scoped>
.current-team-badge {
    margin-left: auto;
}

.sidebar-item-subtitle {
    font-size: 12px;
    text-align: left;
    width: 100%;
}
</style>
