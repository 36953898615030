<template>
    <div class="disconnected-cell">
        <span>-</span>
    </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
    name: 'DisconnectedCellRenderer',
});
</script>
