import { Axios } from 'axios';

/**
 * Refresh the Laravel Spark Session Token. This is done to keep the user
 * session alive and not expire. The basic logic is lifted out of the Laravel
 * Spark codebase.
 *
 * The refresh interval is started automatically upon class instantiation.
 *
 * ```
 * // Creating a new Token Refresher
 * const tokenRefresher = new TokenRefresher(myAxios);
 * // Stopping a Token Refresher
 * tokenRefresher.stop();
 * ```
 */
export class TokenRefresher {
    /**
     * Axios instance
     *
     * @var {Axios} axios
     */
    private axios: Axios;

    /**
     * Interval reference ID.
     *
     * @var {number} intervalId
     */
    private intervalId?: number;

    /**
     * Is the token currently in the process of refreshing.
     *
     * @var {boolean} refreshing
     */
    private refreshing = false;

    /**
     * Logic taken from Spark: Every 4 minutes attempt a spark token refresh.
     *
     * @var {number} tokenRefreshPeriodMilliseconds
     */
    private tokenRefreshPeriodMilliseconds: number = 4 * 60 * 1000;

    /**
     * @param axios Custom Axios instance
     */
    constructor(axios: Axios) {
        this.axios = axios;
        this.start();
    }

    /**
     * Start the application session token refresh interval. If we are currently
     * in the process of refreshing the session, we exit early.
     *
     * Will clear the existing refresher if called again.
     *
     * ```
     * tokenRefresher.start()
     * ```
     */
    start() {
        this.stop();
        this.intervalId = window.setInterval(async () => {
            if (this.refreshing) {
                return;
            }

            this.refreshing = true;
            await this.axios.put('/spark/token');
            this.refreshing = false;
        }, this.tokenRefreshPeriodMilliseconds);
    }

    /**
     * Stop refreshing the current session.
     *
     * ```
     * tokenRefresher.stop()
     * ```
     */
    stop() {
        clearInterval(this.intervalId);
        this.intervalId = undefined;
    }
}
