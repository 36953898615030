import { ref, computed } from 'vue';
import type { FormContextApi } from '../Form.types';

export function useFieldValue<Value = unknown>(inputId: string, api?: FormContextApi, initialValue?: Value) {
    if (!api) {
        const value = ref(initialValue);

        return value;
    }

    return computed({
        get() {
            return api.formState.value[inputId];
        },
        set(value) {
            api.setInputValue(inputId, value);
        },
    });
}
