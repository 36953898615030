import { defineComponent } from 'vue';
import type { RouteConfig } from 'vue-router';

import { Feature } from '@/enums/Feature';
import PageLayout from '@/layout/PageLayout';
import { TeamSidebar } from '@/layout/TeamLayout';
import PrecisionAppProviders from '@/App/PrecisionAppProviders.vue';
import useIsFeatureEnabled from '@/store/modules/feature/hooks/useIsFeatureEnabled';

import { adminRoutes } from './LEGACY/admin';
import { clientRoutes } from './LEGACY/client';
import { configureImportRoutes } from './LEGACY/configure-import';
import { teamRoutes } from './LEGACY/team';
import { userRoutes } from './LEGACY/user';

import { dextTeamRoutes } from './LEGACY/dext/team';

const hasNewClientStore = useIsFeatureEnabled(Feature.NEW_CLIENTS_STORE);

const Root = defineComponent({
    render(createElement) {
        return createElement(PrecisionAppProviders, [createElement(PageLayout)]);
    },
});

export const routes: RouteConfig[] = [
    {
        path: '',
        component: Root,
        children: [
            ...adminRoutes,
            ...configureImportRoutes,
            ...teamRoutes,
            ...clientRoutes,
            ...dextTeamRoutes,
            ...userRoutes,
            {
                path: '/',
                beforeEnter(_to, _from, next) {
                    window.Xavier.teamIsBase ? next({ name: 'team.dashboard' }) : next();
                },
                component: () => {
                    return import('@/scenes/ClientList/index.js').then((components) => {
                        return hasNewClientStore ? components.ClientListScene : components.ClientListSceneLegacy;
                    });
                },
                meta: { sidebar: TeamSidebar },
                name: 'team.home',
            },
        ],
    },
    // This is purely a development route, and will by default render
    // a 404 page.
    {
        path: '/errors/:code',
        props: true,
        component: () => import('@/scenes/Errors/DevelopmentTesting.vue'),
        name: 'error.developmentTesting',
    },
    {
        path: '/login',
        props: false,
        component: () => import('@/scenes/Login/RedirectSceneContainer.vue'),
        name: 'login',
    },
    {
        path: '*',
        component: () => import('@/scenes/Errors/404NotFound.vue'),
        name: 'error.404.notFound',
    },
];
