import moment from 'moment';

import ClientLinkRenderer from '@/components/ReportView/ClientLinkRenderer.vue';
import DisconnectedCellRenderer from '@/components/ReportView/DisconnectedCellRenderer.vue';
import DisconnectedClientRenderer from '@/components/ReportView/DisconnectedClientRenderer.vue';

import type { ICellRendererParams, ColDef } from '@ag-grid-community/core';

/*
    dateGetter: function
*/
export function datePartColumns(dateGetter) {
    const compareNumbers = (a, b) => a - b;

    const commonConfig = {
        width: 100,
        filter: 'agSetColumnFilter',
        filterParams: { comparator: compareNumbers },
    };

    return [
        {
            headerName: 'Period',
            colId: 'period',
            valueGetter: ({ data }) => (data ? moment(dateGetter(data)).format('YYYY-MM') : null),
            ...commonConfig,
        },
        {
            headerName: 'Week',
            colId: 'week',
            valueGetter: ({ data }) => (data ? Number(moment(dateGetter(data)).format('W')) : null),
            pivotComparator: compareNumbers,
            ...commonConfig,
        },
        {
            headerName: 'Month',
            colId: 'month',
            valueGetter: ({ data }) => (data ? Number(moment(dateGetter(data)).format('MM')) : null),
            pivotComparator: compareNumbers,
            ...commonConfig,
        },
        {
            headerName: 'Quarter',
            colId: 'quarter',
            valueGetter: ({ data }) => (data ? Number(moment(dateGetter(data)).quarter()) : null),
            pivotComparator: compareNumbers,
            ...commonConfig,
        },
        {
            headerName: 'Year',
            colId: 'year',
            valueGetter: ({ data }) => (data ? Number(moment(dateGetter(data)).format('YYYY')) : null),
            pivotComparator: compareNumbers,
            ...commonConfig,
        },
    ];
}

export const providerLinkRenderer = ({ value }) => {
    if (value && value !== '#') {
        let linkText = 'Link to QuickBooks';

        if (value.includes('xero')) {
            linkText = 'View in Xero';
        }

        if (value.includes('gov.uk')) {
            linkText = 'View in Companies House';
        }

        return `<a target="_blank" href="${value}">${linkText}</a>`;
    } else {
        return '';
    }
};

export const companyDetailsLinkRenderer = ({ value }) => {
    if (value && value !== '#') {
        const linkText = 'Companies House';

        return `<a target="_blank" href="${value}">${linkText}</a>`;
    }

    return '';
};

export const generalLinkRenderer = ({ value }) => {
    if (value) {
        return `<a target="_blank" href="${value}">${value}</a>`;
    } else {
        return '';
    }
};

export function disconnectedCellRendererSelector(params) {
    if (!params) return {};

    const { colDef, data } = params;
    const isSupportedProvider = ['quickbooks', 'xero'].includes(data?.provider);

    if (!data?.isDemo && !data?.linked && isSupportedProvider) {
        return {
            component: 'AgGridComponentRenderer',
            params: { component: DisconnectedCellRenderer },
        };
    }

    if (colDef.component) {
        return {
            component: 'AgGridComponentRenderer',
            params: { component: colDef.component },
        };
    }

    return { value: params.value };
}

export function disconnectedClientRendererSelector(params: ICellRendererParams) {
    if (!params) return {};

    const { column, data } = params;

    if (column?.isRowGroupDisplayed(column.getColId())) {
        return { component: 'agGroupCellRenderer' };
    }

    if (data?.isDemo) {
        return {
            component: 'AgGridComponentRenderer',
            params: { component: ClientLinkRenderer },
        };
    }

    if (!data?.linked && ['quickbooks', 'xero'].includes(data?.provider)) {
        return {
            component: 'AgGridComponentRenderer',
            params: { component: DisconnectedClientRenderer },
        };
    }

    return {
        component: 'AgGridComponentRenderer',
        params: { component: ClientLinkRenderer },
    };
}

export const fixedAssetColumns = [
    { headerName: 'Asset Number', field: 'AssetNumber', filter: 'agSetColumnFilter', width: 100 },
    { headerName: 'Asset Name', field: 'AssetName', filter: 'agSetColumnFilter' },
    { headerName: 'Asset Type', field: 'AssetType', filter: 'agSetColumnFilter' },
    { headerName: 'Purchase Date', field: 'PurchaseDate', type: 'dateColumn', sort: 'desc' },
    { headerName: 'Purchase Price', valueGetter: ({ data }) => Number(data.PurchasePrice), type: 'moneyColumn' },
    { headerName: 'Book Value', valueGetter: ({ data }) => Number(data.AccountingBookValue), type: 'moneyColumn' },
    { headerName: 'Status', field: 'AssetStatus', filter: 'agSetColumnFilter' },
    { headerName: 'Original Invoice ID', field: 'OriginalInvoiceID', filter: 'agSetColumnFilter' },
    { headerName: 'Link', field: 'DeepLink', cellRenderer: 'DeepLink' },
];

export const accountColumns = [
    { headerName: 'Code', field: 'Code', filter: 'agSetColumnFilter', width: 100 },
    { headerName: 'Name', field: 'Name', filter: 'agSetColumnFilter' },
    { headerName: 'Description', field: 'Description', filter: 'agSetColumnFilter' },
    { headerName: 'Class', field: 'Class', filter: 'agSetColumnFilter' },
    { headerName: 'Type', field: 'Type', filter: 'agSetColumnFilter' },
    { headerName: 'Balance', valueGetter: ({ data }) => Number(data.accountBalance), type: 'moneyColumn' },
    { headerName: 'Status', field: 'Status', filter: 'agSetColumnFilter' },
    { headerName: 'Link', field: 'deepLink', cellRenderer: 'DeepLink' },
];

export const invoiceColumns = [
    {
        headerName: 'Type',
        valueGetter: ({ data }) => {
            if (!data) return;

            //could be extracted into enum if needed
            const typesLookUpTable = {
                ACCPAY: 'Bill',
                ACCREC: 'Sale',
                ACCPAYCREDIT: 'Bill Credit Note',
                ACCRECCREDIT: 'Sale Credit Note',
                'SPEND-OVERPAYMENT': 'Bill Overpayment',
                'RECEIVE-OVERPAYMENT': 'Sale Overpayment',
                SPENDEXPENSE: 'Expense',
                SPENDDEPOSIT: 'Deposit',
                RECEIVEDEPOSIT: 'Deposit',
                CREDITCARDCREDIT: 'Credit Card Credit',
                CHEQUE: 'Cheque',
            };

            return typesLookUpTable[data.Type];
        },
        filter: 'agSetColumnFilter',
    },
    { headerName: 'Contact', field: 'Contact.Name', filter: 'agSetColumnFilter' },
    {
        headerName: 'Reference',
        field: 'Reference',
        filter: 'agSetColumnFilter',
        valueGetter: ({ data }) => (data?.Reference ? data?.Reference : 'N/A'),
    },
    { headerName: 'Currency', field: 'CurrencyCode', filter: 'agSetColumnFilter' },
    { headerName: 'Date', field: 'Date', type: 'dateColumn', sort: 'desc' },
    { headerName: 'Due Date', field: 'DueDate', type: 'dateOrNullColumn' },
    { headerName: 'Amount Due', valueGetter: ({ data }) => Number(data?.AmountDue), type: 'moneyColumn' },
    { headerName: 'Amount Paid', valueGetter: ({ data }) => Number(data?.AmountPaid), type: 'moneyOrNullColumn' },
    {
        headerName: 'Amount Credited',
        valueGetter: ({ data }) => Number(data?.AmountCredited),
        type: 'moneyOrNullColumn',
    },
    { headerName: 'Status', field: 'Status', filter: 'agSetColumnFilter' },
    { headerName: 'Link', field: 'deepLink', cellRenderer: 'DeepLink' },
];

export const overpaymentColumns = [
    { headerName: 'Contact', field: 'contact', filter: 'agSetColumnFilter', width: 200 },
    { headerName: 'Date', field: 'date', type: 'dateColumn', sort: 'desc', width: 120 },
    { headerName: 'Currency', field: 'currency', filter: 'agSetColumnFilter', width: 120 },
    { headerName: 'Total', valueGetter: ({ data }) => Number(data.total), type: 'moneyColumn' },
    { headerName: 'Remaining Credit', valueGetter: ({ data }) => Number(data.remainingCredit), type: 'moneyColumn' },
    { headerName: 'Description', field: 'description', filter: 'agSetColumnFilter' },
    { headerName: 'Type', field: 'type', filter: 'agSetColumnFilter' },
    // { headerName: 'Link', field: 'deepLink', cellRenderer: 'DeepLink' }
];

export const sourceColumns = [
    { headerName: 'Contact', field: 'contactName', filter: 'agSetColumnFilter', width: 200 },
    { headerName: 'Currency', field: 'currency', filter: 'agSetColumnFilter' },
    { headerName: 'Date', field: 'date', type: 'dateColumn', sort: 'desc' },
    { headerName: 'Gross Amount', valueGetter: ({ data }) => Number(data.grossAmount), type: 'moneyColumn' },
    { headerName: 'Net Amount', valueGetter: ({ data }) => Number(data.netAmount), type: 'moneyColumn' },
    { headerName: 'Tax Amount', valueGetter: ({ data }) => Number(data.taxAmount), type: 'moneyColumn' },
    { headerName: 'Reference', field: 'reference', filter: 'agSetColumnFilter' },
    { headerName: 'Type', field: 'type', filter: 'agSetColumnFilter' },
    { headerName: 'Status', field: 'status', filter: 'agSetColumnFilter' },
    { headerName: 'Locked', field: 'locked', filter: 'agSetColumnFilter', width: 100 },
    { headerName: 'Link', field: 'deepLink', cellRenderer: 'DeepLink' },
];

export const statementColumns = [
    { headerName: 'Date', field: 'date', type: 'dateColumn', sort: 'asc' },
    { headerName: 'Bank Account', field: 'bankAccount', filter: 'agSetColumnFilter' },
    { headerName: 'Description', field: 'description' },
    { headerName: 'Reference', field: 'reference' },
    { headerName: 'Reconciled', field: 'reconciled', filter: 'agSetColumnFilter' },
    { headerName: 'Amount', field: 'amount', type: 'moneyColumn' },
    { headerName: 'Balance', field: 'balance', type: 'moneyColumn' },
    { headerName: 'Link', field: 'reconcileLink', cellRenderer: 'DeepLink' },
    { headerName: 'Bank Account ID', field: 'bankAccountID', filter: 'agSetColumnFilter' },
    { headerName: 'Source', field: 'source', filter: 'agSetColumnFilter' },
    { headerName: 'Locked', field: 'locked', filter: 'agSetColumnFilter' },
    ...datePartColumns((data) => data.date),
];

export const bankTransactionColumns = [
    { headerName: 'Date', field: 'date', type: 'dateColumn', sort: 'asc' },
    { headerName: 'Description', field: 'contactName' },
    { headerName: 'Reference', field: 'reference' },
    { headerName: 'Source', field: 'source', filter: 'agSetColumnFilter' },
    { headerName: 'Currency', field: 'currency', filter: 'agSetColumnFilter' },
    { headerName: 'Currency Rate', field: 'currencyRate', type: 'numberColumn' },
    { headerName: 'Amount', valueGetter: ({ data }) => Number(data.grossAmount), type: 'moneyColumn' },
    { headerName: 'Link', field: 'deepLink', cellRenderer: 'DeepLink' },
];

export const journalColumns = [
    { headerName: '#', field: 'journalNumber', type: 'numberColumn', width: 80 },
    { headerName: 'ContactID', field: 'contactId', filter: 'agSetColumnFilter', width: 200, hide: true },
    { headerName: 'Contact', field: 'contactName', filter: 'agSetColumnFilter', width: 200 },
    { headerName: 'Description', field: 'description', width: 200 },
    {
        headerName: 'Account',
        children: [
            {
                headerName: 'Name',
                colId: 'accountName',
                valueGetter: ({ data }) =>
                    data ? (data.accountCode ? `${data.accountName} (${data.accountCode})` : data.accountName) : null,
                filter: 'agSetColumnFilter',
            },
            { headerName: 'Class', field: 'accountClass', filter: 'agSetColumnFilter' },
            { headerName: 'Report Code', field: 'accountReportingCode', filter: 'agSetColumnFilter' },
            { headerName: 'Report Category', field: 'accountReportingCodeName', filter: 'agSetColumnFilter' },
        ],
    },
    {
        headerName: 'Amounts',
        children: [
            { headerName: 'Gross', field: 'grossAmount', type: 'moneyColumn' },
            { headerName: 'Net', field: 'netAmount', type: 'moneyColumn' },
            { headerName: 'Tax Amount', field: 'taxAmount', type: 'moneyColumn' },
        ],
    },
    {
        headerName: 'Tax',
        children: [
            { headerName: 'Tax Type', field: 'taxType', filter: 'agSetColumnFilter' },
            { headerName: 'Tax Name', field: 'taxName', filter: 'agSetColumnFilter' },
            { headerName: 'Effective Tax Rate', field: 'effectiveTaxRate', type: 'numberColumn' },
        ],
    },
    {
        headerName: 'Date',
        children: [
            { headerName: 'Date', field: 'date', type: 'dateColumn', sort: 'desc' },
            ...datePartColumns((data) => data.date),
        ],
    },
    {
        headerName: 'Source',
        children: [
            { headerName: 'Identifier', field: 'sourceId', filter: 'agSetColumnFilter' },
            { headerName: 'Category', field: 'sourceCategory', filter: 'agSetColumnFilter' },
            { headerName: 'Type', field: 'sourceType', filter: 'agSetColumnFilter' },
            { headerName: 'Status', field: 'sourceStatus', filter: 'agSetColumnFilter' },
            { headerName: 'Locked', field: 'locked', filter: 'agSetColumnFilter', width: 100 },
            { headerName: 'Link', field: 'sourceLink', cellRenderer: 'DeepLink' },
        ],
    },
];

export const clientColumns: ColDef[] = [
    { headerName: 'Name', field: 'name', filter: 'agSetColumnFilter' },
    { headerName: 'Health Score', field: 'healthScore', filter: 'numberColumn' },
    { headerName: 'Tags', field: 'tags', filter: 'agSetColumnFilter' },
    { headerName: 'Assigned to', field: 'assignedTo', filter: 'agSetColumnFilter' },
    {
        headerName: 'Reviewer 1',
        filter: 'agSetColumnFilter',
        valueGetter(params) {
            return params.data.firstReviewer ?? params.data.reviewer;
        },
        field: 'firstReviewer',
    },
    { headerName: 'Reviewer 2 ', field: 'secondReviewer', filter: 'agSet<ColumnFilter', field: 'secondReviewer' },
] as const;

export const sandboxLiteColumns = [
    { headerName: 'Date', field: 'date', type: 'dateColumn', sort: 'desc' },
    { headerName: 'Contact', field: 'contactName', filter: 'agSetColumnFilter', width: 200 },
    { headerName: 'Description', field: 'description' },
    { headerName: 'Code', field: 'accountCode' },
    { headerName: 'Account', field: 'accountName' },
    {
        headerName: 'Net Amount',
        valueGetter: ({ data }) => (data ? Number(data.netAmount) : null),
        type: 'moneyColumn',
    },
    {
        headerName: 'Tax Amount',
        valueGetter: ({ data }) => (data ? Number(data.taxAmount) : null),
        type: 'moneyColumn',
    },
    { headerName: 'Tax Type', field: 'taxName' },
    { headerName: 'Category', field: 'sourceCategory', filter: 'agSetColumnFilter' },
    {
        headerName: 'Link',
        valueGetter: ({ data }) => (data ? data.sourceLink : null),
        cellRenderer: 'DeepLink',
    },
];

export const taxCentricColumns = [
    { headerName: 'Date', field: 'date', type: 'dateColumn', sort: 'desc' },
    { headerName: 'Tax Type', field: 'taxName', filter: 'agSetColumnFilter' },
    { headerName: 'Created On', field: 'createdDate', type: 'dateColumn' },
    { headerName: 'Contact', field: 'contactName', filter: 'agSetColumnFilter', width: 200 },
    { headerName: 'Description', field: 'description' },
    { headerName: 'Account', field: 'accountName', filter: 'agSetColumnFilter' },
    {
        headerName: 'Net Amount',
        valueGetter: ({ data }) => (data ? Number(data.netAmount) : null),
        type: 'moneyColumn',
    },
    {
        headerName: 'Tax Amount',
        valueGetter: ({ data }) => (data ? Number(data.taxAmount) : null),
        type: 'moneyColumn',
    },
    { headerName: 'Category', field: 'sourceCategory', filter: 'agSetColumnFilter' },
    { headerName: 'Code', field: 'accountCode', filter: 'agSetColumnFilter' },
    { headerName: 'Status', field: 'sourceStatus', filter: 'agSetColumnFilter' },
    { headerName: 'Link', field: 'sourceLink', cellRenderer: 'DeepLink' },
];

export const defaultOptions = {
    groupIncludeFooter: true,
    defaultColDef: {
        enablePivot: true,
        enableRowGroup: true,
    },
};

export const agedOptions = {
    groupIncludeFooter: true,
    defaultColDef: {
        enablePivot: true,
        enableRowGroup: true,
    },
    getRowClass: ({ data }) => {
        if (!data) {
            return 'row-alert-none';
        }

        if (data.reconciled === 'Yes') {
            return 'row-alert-none';
        } else if (moment().diff(data.date, 'days') >= 30) {
            return 'row-alert-error';
        } else if (moment().diff(data.date, 'days') >= 15) {
            return 'row-alert-warning';
        } else {
            return 'row-alert-none';
        }
    },
};
