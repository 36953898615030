<template>
    <div class="login-scene" data-qa="scene-login">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'LoginScene',

    inject: ['analyticsProvider'],

    data() {
        return {
            xeroSsoLinkEl: null,
        };
    },

    methods: {
        onXeroSsoClick() {
            const url = this.xeroSsoLinkEl.getAttribute('href');

            this.analyticsProvider.trackLinkClick('Sign in with Xero', url, true);
        },
    },

    created() {
        this.analyticsProvider.setTrackingContext({ page: 'visitor.auth.login' });
    },

    mounted() {
        this.xeroSsoLinkEl = document.querySelector('#xero-sso a');
        this.xeroSsoLinkEl.addEventListener('click', this.onXeroSsoClick);
    },

    beforeDestroy() {
        this.xeroSsoLinkEl.removeEventListener('click', this.onXeroSsoClick);
    },
};
</script>

<style lang="scss" scoped></style>
