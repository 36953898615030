<template>
    <Modal name="video-modal" size="medium" v-model="isVisible">
        <ModalHeader>Instructional video</ModalHeader>

        <ModalContent>
            <iframe
                width="560"
                height="315"
                src="//play.vidyard.com/CVsp5wXUxC3bzCVJ8WScSX.html?"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
            ></iframe>
        </ModalContent>

        <ModalFooter>
            <template #right>
                <VButton @click="isVisible = false">Close</VButton>
            </template>
        </ModalFooter>
    </Modal>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue';
import * as PropTypes from 'vue-types';

import { Modal, ModalContent, ModalFooter, ModalHeader } from '@/components/Modal';

export default defineComponent({
    components: {
        Modal,
        ModalContent,
        ModalFooter,
        ModalHeader,
    },

    props: {
        visible: PropTypes.bool().def(false),
    },

    model: {
        prop: 'visible',
        event: 'visibility-change',
    },

    setup(props, context) {
        const isVisible = ref<boolean>(props.visible);

        watch(
            () => props.visible,
            (value) => {
                isVisible.value = value;
            }
        );

        watch(isVisible, (value) => {
            context.emit('visibility-change', value);
        });

        return {
            isVisible,
        };
    },
});
</script>
