module.exports = {
    props: ['user', 'team'],

    /**
     * The component's data.
     */
    data() {
        return {
            form: new SparkForm({
                name: '',
                /* Xavier: adding in slug */
                slug: '',
            })
        };
    },


    /**
     * Prepare the component.
     */
    mounted() {
        this.form.name = this.team.name;
        /* Xavier: adding in slug */
        this.form.slug = this.team.slug;
    },

    /* Xavier: adding in slug */
    watch: {
        /**
         * Watch the team name for changes.
         */
        'form.name': function (val, oldVal) {
            if (this.form.slug === '' ||
                this.form.slug === oldVal.toLowerCase().replace(/[\s\W-]+/g, '-')
            ) {
                this.form.slug = val.toLowerCase().replace(/[\s\W-]+/g, '-');
            }
        }
    },


    methods: {
        /**
         * Update the team name.
         */
        update() {
            Spark.put(`/settings/${Spark.teamsPrefix}/${this.team.id}/name`, this.form)
                .then(() => {
                    Bus.$emit('updateTeam');
                    Bus.$emit('updateTeams');
                });
        }
    }
};
