enum UserPermissions {
    ADD_NEW_CLIENT = 'canAddNewClient',
    APPROVE_COMPLETED_FLOWS = 'canApproveCompletedFlows',
    ACCESS_CLIENT_ACTIVITY = 'accessClientActivity',
    ACCESS_CLIENT_BULK_SETTINGS = 'accessClientBulkSettings',
    ACCESS_CLIENT_SETTINGS = 'accessClientSettings',
    ACCESS_TEAM_SETTINGS = 'accessTeamSettings',
    CREATE_FLOW_TEMPLATES = 'canCreateFlowTemplates',
    EDIT_ANY_FLOW = 'canEditAnyFlow',
    INVITE_NEW_MEMBERS = 'canInviteNewMembers',
    MANAGE_SUBSCRIPTION = 'canManageSubscription',
    MANAGE_TEAM_INTEGRATIONS = 'canManageTeamIntegrations',
    MANAGE_TEAM_MEMBERS = 'canManageTeamMembers',
    RESTORE_CLIETNS = 'canRestoreClients',
    VIEW_ALL_CLIENTS = 'canViewAllClients',
}

export default UserPermissions;
