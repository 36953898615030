<template>
    <BaseNotification>
        <div slot="content">
            <strong class="notification-title" v-if="!completed">
                <span>
                    <VIcon name="sync" spin />
                </span>
                Flow Creation In Progress...
            </strong>
            <strong class="notification-title text-success" v-else>
                <span><VIcon name="checklist-thick" /></span>
                {{ completedFlows }} {{ $plural('Flow', totalFlows) }} Created Successfully!
            </strong>
            <div class="notification-content" v-if="!completed">
                <p>{{ completedFlows }} / {{ totalFlows }} created.</p>
            </div>
        </div>
    </BaseNotification>
</template>

<script>
import BaseNotification from './BaseNotification.vue';

export default {
    name: 'FlowCreation',
    components: {
        BaseNotification,
    },
    props: {
        totalFlows: { type: Number, required: true },
        notificationId: { type: Symbol, required: true },
        batchId: { type: String, required: true },
    },
    data() {
        return {
            completedFlows: 0,
            completed: false,
        };
    },
    created() {
        window.Bus.$on('flowCreationProgress', ({ batchId }) => this.setProgress(batchId));
        window.Bus.$on('flowCreationCompleted', ({ batchId }) => this.setCompleted(batchId));
        window.Bus.$on('flowCreationFailed', ({ batchId }) => this.setFailed(batchId));
    },
    methods: {
        setCompleted(batchId) {
            if (this.batchId === batchId) {
                this.completed = true;
                setTimeout(() => this.$emit('completed'), 4000);
            }
        },
        setProgress(batchId) {
            if (this.batchId === batchId) {
                this.completedFlows++;
            }
        },
        setFailed(batchId) {
            if (this.batchId === batchId) {
                this.$emit('completed');
            }
        },
    },
};
</script>

<style scoped>
.notification-title {
    font-weight: 700;
    font-size: 16px;
    /*color: #000002;*/
    margin: 0;
}

.notification-content {
    font-size: 14px;
    line-height: 21px;
    margin: 6px 0 0;
    color: #606266;
    text-align: justify;
}

.notification-content p {
    margin: 0;
}
</style>
