import { computed, getCurrentInstance, inject, onBeforeMount, unref, useAttrs, watch } from 'vue';

import { generateQaName } from '@/utils/strings';

import type { FormContextApi } from '../Form.types';
import type { SetupContext } from 'vue';

import { useFieldValue } from './useFieldValue';
import { FormContext } from './useFormContext';

type InputOptions = {
    disabled?: boolean;
    id?: string;
    /* eslint-disable-next-line */
    defaultValue?: any;
    qaName?: string;
    /* eslint-disable-next-line */
    value?: any;
};

type InputAttrs = {
    disabled: boolean;
    name: string;
    ['data-qa']: string;
} & SetupContext['attrs'];

export function useInput(name: string, options?: InputOptions) {
    const formApi = inject<FormContextApi | undefined>(FormContext, undefined);
    const vm = getCurrentInstance();
    const currentValue = useFieldValue(name, formApi, options?.value);
    const componentAttrs = useAttrs();

    const attrs = computed<InputAttrs>(() => {
        const disabled =
            options?.disabled === false ? false : Boolean(options?.disabled) || formApi?.disabled.value === true;

        return {
            ...componentAttrs,
            disabled,
            name,
            ['data-qa']: generateQaName(options?.qaName || options?.id || name),
        };
    });

    watch(
        () => unref(options?.value),
        (newValue) => {
            currentValue.value = newValue;
        }
    );

    watch(currentValue, (newValue, oldValue) => {
        if (options?.value !== undefined) {
            return newValue !== options.value && vm?.proxy.$emit('input', newValue);
        }

        return newValue !== oldValue && vm?.proxy.$emit('input', newValue);
    });

    onBeforeMount(() => {
        if (formApi?.initialFormState.value?.[name] !== undefined) {
            return;
        }

        if (options?.value !== undefined) {
            return formApi?.setInputValue(name, options.value);
        }

        if (options?.defaultValue !== undefined) {
            return formApi?.setInputValue(name, options.defaultValue);
        }

        formApi?.setInputValue(name, undefined);
    });

    return { attrs, currentValue };
}
