<template>
    <ElDatePicker
        v-bind="$attrs"
        v-model="modelBinding"
        :clearable="clearable"
        :disabled="disabled"
        end-placeholder="End date"
        :format="localeDateFormat"
        :picker-options="datePickerOptions"
        range-separator="-"
        size="small"
        start-placeholder="Start date"
        :style="pickerStyle"
        type="daterange"
        unlink-panels
        value-format="yyyy-MM-dd"
    />
</template>

<script>
import DatePicker from 'element-ui/lib/date-picker';
import moment from 'moment';

import 'element-ui/lib/theme-chalk/date-picker.css';

import { localeDateFormat, getFinancialYearForDate } from '@/utils/date';

const defaultShortcuts = {
    Today: () => [moment().startOf('day').toDate(), moment().endOf('day').toDate()],
    'This Week': () => [moment().startOf('week').toDate(), moment().endOf('week').toDate()],
    'This Month': () => [moment().startOf('month').toDate(), moment().endOf('month').toDate()],
    'Last Month': () => [
        moment().subtract(1, 'months').startOf('month').toDate(),
        moment().subtract(1, 'months').endOf('month').toDate(),
    ],
    'Last 30 Days': () => [moment().subtract(30, 'days').toDate(), moment().toDate()],
    'Last 3 Months': () => [moment().subtract(3, 'months').toDate(), moment().toDate()],
    'Last 6 Months': () => [moment().subtract(6, 'months').toDate(), moment().toDate()],
    'This Year': () => [moment().startOf('year').toDate(), moment().endOf('year').toDate()],
    'Last Year': () => [
        moment().subtract(1, 'years').startOf('year').toDate(),
        moment().subtract(1, 'years').endOf('year').toDate(),
    ],
};

export default {
    name: 'DatePicker',
    components: {
        ElDatePicker: DatePicker,
    },
    props: {
        value: { type: Array, default: () => null },
        shortcuts: {
            type: Object,
            default: () => {},
        },
        clearable: { type: Boolean, default: true },
        disabled: { type: Boolean, default: false },
        taxYearEnd: { type: Object, default: () => null },
    },
    data() {
        return {
            localeDateFormat: localeDateFormat(),
        };
    },
    computed: {
        datePickerOptions() {
            return {
                shortcuts: Object.entries(this.allShortcuts).map(([k, v]) => ({
                    text: k,
                    onClick: (picker) => picker.$emit('pick', v()),
                })),
            };
        },
        allShortcuts() {
            const financialYears = {};

            if (this.taxYearEnd && this.taxYearEnd.month && this.taxYearEnd.day) {
                const thisYearEnd = getFinancialYearForDate(this.taxYearEnd.day, this.taxYearEnd.month, moment());
                const lastYearEnd = getFinancialYearForDate(
                    this.taxYearEnd.day,
                    this.taxYearEnd.month,
                    moment().subtract(1, 'years')
                );

                financialYears[`FY ${thisYearEnd.start.year()}`] = () => [
                    thisYearEnd.start.toDate(),
                    thisYearEnd.end.toDate(),
                ];
                financialYears[`FY ${lastYearEnd.start.year()}`] = () => [
                    lastYearEnd.start.toDate(),
                    lastYearEnd.end.toDate(),
                ];
            }

            return { ...defaultShortcuts, ...this.shortcuts, ...financialYears };
        },
        modelBinding: {
            get() {
                return this.value;
            },
            set(dates) {
                this.$emit('input', dates);
            },
        },
        pickerStyle() {
            return { width: '225px !important' };
        },
    },
};
</script>

<style lang="scss">
@import 'style/dext/includes';

.el-picker-panel .el-icon-d-arrow-left {
    margin-right: 10px;
}

.el-picker-panel .el-icon-d-arrow-right {
    margin-left: 10px;
}

.el-picker-panel .el-icon-d-arrow-right::before,
.el-picker-panel .el-icon-d-arrow-left::before {
    font-weight: bold;
}

.el-picker-panel .el-date-picker__header-label {
    background-color: var(--d-color-silver-lite);
    border-radius: 5px;
    color: var(--d-color-charcoal);
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    margin: 3px;
    padding: 3px 11px;
    text-align: center;
}

.el-picker-panel .el-date-picker__header-label:hover {
    background-color: var(--d-color-silver-medium);
    color: var(--d-color-charcoal);
}

.el-picker-panel .el-picker-panel__icon-btn {
    background-color: var(--d-color-silver-lite);
    border-radius: 5px;
    display: inline-block;
    margin: 3px;
    padding: 8px 6px;
}

.el-picker-panel .el-picker-panel__icon-btn:hover {
    background-color: var(--d-color-silver-medium);
    color: var(--d-color-charcoal);
}

.el-picker-panel .el-picker-panel__icon-btn.is-disabled:hover {
    background-color: var(--d-color-silver-lite);
    color: #bbb; // From elementui
}
</style>
