import type { RouteConfig } from 'vue-router';
import { FlowSidebar } from '@/layout/ClientLayout';

import { extractClientIdParam } from '../utils';

const flowRoutes: RouteConfig[] = [
    {
        path: 'flow/:flowSlug',
        component: () => import('@/scenes/Flow/FlowScene.vue'),
        props: extractClientIdParam,
        meta: { sidebar: FlowSidebar },
        name: 'client.flow.details',
    },
];

export { flowRoutes };
