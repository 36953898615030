import moment from 'moment';
import type { GridApi, ExcelExportParams, ProcessCellForExportParams } from '@ag-grid-community/core';

import { truncate, stripSpecialCharacters, collapseSpaces } from '@/utils/strings';

type ExportToExcelOptions = {
    fileName: string;
    sheetName?: string;
};

/**
 * Format the filename for export. We need to strip any extension that is added
 * because previous versinos of AG Grid would not add the extension. Version
 * >24 will add the extension.
 *
 * @param fileName
 */
function formatFileName(fileName: string) {
    const fileNameWithoutExtension = fileName.endsWith('.xlsx') ? fileName.replace('.xlsx', '') : fileName;

    return collapseSpaces(stripSpecialCharacters(fileNameWithoutExtension));
}

/**
 * Create the sheet name for the excel spreadhseet
 *
 * @param sheetName
 */
function formatSheetName(sheetName = 'Export') {
    const truncated = truncate(sheetName, 30);

    return collapseSpaces(stripSpecialCharacters(truncated));
}

/**
 * If the column is a date column, we need to format the date.
 *
 * @param processCellCallBackParams
 */
function processCallback(params: ProcessCellForExportParams): string {
    const colDef = params.column.getColDef();

    if (colDef.type === 'dateColumn' && params.value) {
        return moment(params.value).format('YYYY-MM-DD');
    }

    /**
     * This is dumb, if there is no valid value, AG Grid replaces the value with
     * either the group header, or the column header. Why?
     */
    return params.value || ' ';
}

/**
 * Export the grid to an Excel spreadsheet.
 *
 * @param gridApi
 * @param options
 */
export function exportToExcel(gridApi: GridApi, options: ExportToExcelOptions) {
    const params: ExcelExportParams = {
        fileName: formatFileName(options.fileName),
        sheetName: formatSheetName(options.sheetName),
        processCellCallback: processCallback,
    };

    gridApi?.exportDataAsExcel(params);
}
