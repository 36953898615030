import { defineComponent, provide, ref, watch } from 'vue';

import { useRoute } from '@/composables/useRoute';
import { PreviousRouteContext, RouteContext } from '@/hooks/useNavigationContext';

/**
 * Provide route utility methods to any child component. This will expose the
 * access to the `$route` property in the setup function.
 *
 * @var {Vue} RouteProvider
 * @deprecated
 */
const RouteProvider = defineComponent({
    setup(props, context) {
        const route = useRoute();
        const currentRoute = ref(null);
        const previousRoute = ref(null);

        provide(PreviousRouteContext, previousRoute);
        provide(RouteContext, currentRoute);

        watch(
            route,
            (newValue, oldValue) => {
                currentRoute.value = newValue;
                previousRoute.value = oldValue;
            },
            { immediate: true }
        );

        return () => context.slots.default();
    },
});

export default RouteProvider;
