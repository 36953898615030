<template>
    <Menu v-bind="attrs" close-on-content-click name="Client Switcher">
        <template #activator="{ on, attrs: menuAttrs }">
            <SidebarItem v-bind="menuAttrs" name="Switch Client" v-on="on">
                <template v-if="currentClient">
                    {{ currentClient.name }}
                    <div class="sidebar-item-subtitle" v-if="clientList.length > 1 && currentClient.name">
                        Switch client
                    </div>
                </template>

                <template #actions v-if="clientList.length > 1">
                    <VIcon decorative name="exchange-thick" />
                </template>

                <Spinner basic :centered="false" v-if="!currentClient.name" />
            </SidebarItem>
        </template>

        <MenuItemsFilterable
            :alignment="{ horizontal: 'left', vertical: 'top' }"
            detach
            inline
            :items="clientList"
            :options="{ threshold: 0 }"
            :search-keys="['name', 'provider', 'slug']"
        >
            <template #prepend>
                <MenuTitle>Switch Client</MenuTitle>
            </template>

            <template #item="{ item: client }">
                <MenuItem
                    class="client-item"
                    :disabled="isClientImporting(client.id) || client.id === currentClient.id"
                    name="Switch Client"
                    :to="{
                        name: $route.name,
                        params: { clientSlug: client.slug },
                        query: $route.query,
                    }"
                >
                    <template #icon-left>
                        <VClientIcon class="provider-logo" icon-size="xs" :org-type="client.provider.toUpperCase()" />
                        <VIcon class="favourite-client-icon" decorative name="star-filled" v-if="client.isFavourite" />
                    </template>

                    {{ client.name }} <span class="sr-only" v-if="client.isFavourite">(added to favourites)</span>
                    <DBadge class="current-client-badge" variant="processing" v-if="client.id === currentClient.id">
                        current
                    </DBadge>
                </MenuItem>
            </template>
        </MenuItemsFilterable>
    </Menu>
</template>

<script>
import PropTypes from 'vue-types';
import { computed, defineComponent } from 'vue';
import orderBy from 'lodash-es/orderBy';
import { DBadge } from '@/components/Badge';
import { Menu, MenuItem, MenuItemsFilterable, MenuTitle } from '@/components/Menu';
import Spinner from '@/components/Spinner';
import { SidebarItem } from '@/layout/Sidebar';
import omit from 'lodash-es/omit';
import { createNamespacedHelpers } from 'vuex-composition-helpers';

const { useGetters: useImportGetters } = createNamespacedHelpers('imports');

export default defineComponent({
    components: {
        DBadge,
        Menu,
        MenuItem,
        MenuItemsFilterable,
        MenuTitle,
        SidebarItem,
        Spinner,
    },

    props: {
        ...Menu.props,
        clients: PropTypes.array.isRequired,
        currentClient: PropTypes.object.isRequired,
    },

    setup(props, context) {
        const isDisabled = computed(() => {
            return props.disabled || props.clients.length < 2 || !props.currentClient.slug;
        });

        const { isClientImporting } = useImportGetters(['isClientImporting']);

        const mapClientData = (client) => ({
            id: client.id,
            isFavourite: client.hasFlagged,
            name: client.name,
            provider: client.provider,
            slug: client.slug,
        });

        const attrs = computed(() => {
            return {
                ...context.attrs,
                ...omit(props, ['clients', 'currentClient']),
                closeOnContentClick: true,
                disabled: isDisabled.value,
            };
        });

        /**
         * Sort the client list. Unfortunately this is a more complex sort than
         * we would like, however to get a good experience I think it's valuable
         * to put the current client first, followed by the users favourites and
         * then sort by provider name and finally client name.
         *
         * I hope that we never need to change this as it took longer to resolve
         * than I would like to admit.
         */
        const clientList = computed(() => {
            const sortOrder = [
                (item) => (item.id === props.currentClient.id ? 0 : 1),
                (item) => (item.isFavourite ? 0 : 1),
                'provider',
                'name',
            ];

            return orderBy(props.clients.map(mapClientData), sortOrder, ['asc', 'asc', 'asc', 'asc']);
        });

        return {
            attrs,
            clientList,
            isDisabled,
            isClientImporting,
        };
    },
});
</script>

<style lang="scss" scoped>
@import 'style/dext/includes';

.client-item {
    position: relative;
}

.current-client-badge {
    margin-left: auto;
}

.favourite-client-icon {
    color: get-color(orange);
    left: 10px;
    position: absolute;
    top: 2px;
    z-index: 2;

    svg {
        stroke: $color-white;
    }
}

.sidebar-item-subtitle {
    font-size: 12px;
    text-align: left;
    width: 100%;
}

.provider-logo {
    height: 18px;
    margin-top: -2px;
    position: absolute;
    width: 18px;
}
</style>
