<template>
    <Sidebar>
        <template #prepend>
            <!-- If DEXT account switcher is enabled, display Precision Team switcher with empty team list -->
            <TeamSwitcher :current-team="currentTeam" :teams="teamList" v-if="!isDextAccountSwitcherEnabled" />
            <TeamSwitcher :current-team="currentTeam" :teams="[]" v-else />
        </template>

        <SidebarItem exact name="Team Home" :to="{ name: 'team.home' }">
            <template #icon-left="{ isActive }">
                <VIcon decorative :name="isActive ? 'home-filled' : 'home-thick'" />
            </template>
            Team Home
        </SidebarItem>

        <FocusGroup />
        <PracticeAnalysisGroup />

        <ConfigurationGroup />
        <TeamManagementGroup :current-team="currentTeam" />

        <SidebarItem name="Get Started" :to="{ name: 'team.getStarted' }" v-if="!teamIsBase && setupPercent < 100">
            <template #icon-left>
                <VIcon decorative name="rocket-thick" />
            </template>

            Get Started

            <div class="progress-bar">
                <div class="progress-bar__percent" :style="{ width: `${setupPercent}%` }" />
            </div>
        </SidebarItem>

        <template #append v-if="isNovaUser">
            <PrecisionAdminSidebarItems />
        </template>
    </Sidebar>
</template>

<script>
import { createNamespacedHelpers } from 'vuex-composition-helpers';

import useXavierGlobals from '@/hooks/useXavierGlobals';
import { Sidebar, SidebarItem } from '@/layout/Sidebar';
import { PrecisionAdminSidebarItems } from '@/layout/PrecisionAdmin';

import FocusGroup from './Groups/FocusGroup.vue';
import PracticeAnalysisGroup from './Groups/PracticeAnalysisGroup.vue';
import ConfigurationGroup from './Groups/ConfigurationGroup.vue';
import TeamManagementGroup from './Groups/TeamManagementGroup.vue';
import TeamSwitcher from './TeamSwitcher.vue';

const { useGetters: useTeamGetters } = createNamespacedHelpers('teams');

export default {
    name: 'TeamSidebar',

    components: {
        ConfigurationGroup,
        FocusGroup,
        PracticeAnalysisGroup,
        PrecisionAdminSidebarItems,
        Sidebar,
        SidebarItem,
        TeamManagementGroup,
        TeamSwitcher,
    },

    setup() {
        const { isNovaUser = false, teamIsBase = false } = useXavierGlobals();
        const { currentTeam, setupPercent, teamList, isDextAccountSwitcherEnabled } = useTeamGetters([
            'currentTeam',
            'setupPercent',
            'teamList',
            'isDextAccountSwitcherEnabled',
        ]);

        return {
            currentTeam,
            isNovaUser,
            setupPercent,
            teamIsBase,
            teamList,
            isDextAccountSwitcherEnabled,
        };
    },
};
</script>

<style lang="scss" scoped>
@import 'style/dext/includes';

.progress-bar {
    background-color: get-color(charcoal);
    border-radius: 8px;
    height: 8px;
    margin-top: 5px;
    width: 100%;
}

.progress-bar__percent {
    background-color: get-color(green);
    border-radius: 8px;
    height: 100%;
}
</style>
