import type { RouteConfig } from 'vue-router';
import { TeamSidebar } from '@/layout/TeamLayout';

const configureImportRoutes: RouteConfig[] = [
    {
        path: 'configure-import/:authEventId',
        meta: { sidebar: TeamSidebar },
        component: () => import('@/scenes/ClientImportConfiguration/ClientImportConfigurationContainer.vue'),
        name: 'configureBulkImport',
    },

    {
        path: 'configure-quickbooks-import/:authEventId',
        meta: { sidebar: TeamSidebar },
        component: () => import('@/scenes/ClientImportConfiguration/QuickbooksClientImportConfigurationContainer.vue'),
        name: 'configureQuickbookImport',
    },
];

export { configureImportRoutes };
