import { isInsightSupported } from '@/utils/accountingApps';
import useCreateItems from './useCreateItems';

export default function useBaseGroup(client, reportMode, routeOptions = {}) {
    const baseRoute = {
        params: { clientSlug: client.slug },
        query: { mode: reportMode },
        ...routeOptions,
    };

    return {
        baseRoute,
        isInsightSupported: (...insights) => {
            return insights.map((name) => isInsightSupported(client.provider, name)).some((value) => value === true);
        },
        createItems: useCreateItems(baseRoute),
    };
}
