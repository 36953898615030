/**
 * Any Pose easing functions should be added to this enum.
 *
 * @var {Object} easing
 * @link https://popmotion.io/pose/learn/vue-custom-transitions/
 */
const easing = {
    CUBIC_BEZIER: [0.25, 0.8, 0.5, 1],
};

export default Object.freeze(easing);
