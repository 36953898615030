<template>
    <div class="grid maximise-height">
        <VButton
            v-if="expandable && data.length > 0 && !isEmbedded"
            class="expand-button"
            :name="`View ${name ? name : ''} grid fullscreen`"
            @click.stop="showGridModal = true"
        >
            <template #icon-left>
                <VIcon decorative name="full-screen-thick" />
            </template>
            View fullscreen
        </VButton>

        <Modal
            v-if="data.length > 0"
            v-model="showGridModal"
            :name="name ? `${name} Grid` : 'Grid'"
            :route="name ? name : null"
            size="fullscreen"
        >
            <ModalHeader>{{ expandedTitle || '' }}</ModalHeader>

            <ModalContent>
                <AgGridVue
                    v-bind="$attrs"
                    :class="['ag-grid-container ag-theme-quartz', { 'grid--busy': busy }]"
                    :grid-options="gridOptions"
                    :row-data="data"
                    v-on="$listeners"
                    @column-moved="schemaChanged"
                    @column-pinned="schemaChanged"
                    @column-pivot-changed="schemaChanged"
                    @column-pivot-mode-changed="schemaChanged"
                    @column-resized="schemaChanged"
                    @column-row-group-changed="schemaChanged"
                    @column-value-changed="schemaChanged"
                    @displayed-columns-changed="schemaChanged"
                    @filter-changed="schemaChanged"
                    @first-data-rendered="onFirstDataRenderered"
                    @grid-ready="onGridReady"
                    @sort-changed="schemaChanged"
                />
            </ModalContent>
        </Modal>

        <div class="grid-container maximise-height" :style="gridStyle">
            <AgGridVue
                v-bind="$attrs"
                :class="['ag-grid-container ag-theme-quartz', { 'grid--busy': busy }]"
                :grid-options="gridOptions"
                :row-data="data"
                v-on="$listeners"
                @column-moved="schemaChanged"
                @column-pinned="schemaChanged"
                @column-pivot-changed="schemaChanged"
                @column-pivot-mode-changed="schemaChanged"
                @column-resized="schemaChanged"
                @column-row-group-changed="schemaChanged"
                @column-value-changed="schemaChanged"
                @displayed-columns-changed="schemaChanged"
                @filter-changed="schemaChanged"
                @first-data-rendered="onFirstDataRenderered"
                @grid-ready="onGridReady"
                @sort-changed="schemaChanged"
            />
        </div>
    </div>
</template>

<script>
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ModuleRegistry } from '@ag-grid-community/core';
import { CsvExportModule } from '@ag-grid-community/csv-export';
import { AgGridVue } from '@ag-grid-community/vue';
import { GridChartsModule } from '@ag-grid-enterprise/charts';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { LicenseManager, EnterpriseCoreModule } from '@ag-grid-enterprise/core';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { RichSelectModule } from '@ag-grid-enterprise/rich-select';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import merge from 'lodash-es/merge';
import moment from 'moment';
import { defineComponent } from 'vue';
import * as PropTypes from 'vue-types';

import { AppContext } from '@/App/composables/useAppContext';
import DeepLink from '@/components/Link/DeepLink.vue';
import Modal, { ModalContent, ModalHeader } from '@/components/Modal';
import { format, round } from '@/utils/number';

import { AgGridComponentRenderer } from './cellRenderers';
import { exportToExcel } from './utils';

LicenseManager.setLicenseKey(window.__agGridLicense ?? '');

ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    ClipboardModule,
    ColumnsToolPanelModule,
    CsvExportModule,
    EnterpriseCoreModule,
    ExcelExportModule,
    FiltersToolPanelModule,
    GridChartsModule,
    MasterDetailModule,
    MenuModule,
    RichSelectModule,
    RowGroupingModule,
    SetFilterModule,
]);

const gridSize = 6;
const rowHeight = gridSize * 6;
const headerHeight = gridSize * 7;

const excelFont = { fontName: 'Calibri', size: 11 };

const globalComponents = {
    AgGridComponentRenderer,
    DeepLink,
};

export default defineComponent({
    name: 'AgGrid',
    components: {
        ...globalComponents,
        AgGridVue,
        Modal,
        ModalHeader,
        ModalContent,
    },
    inject: {
        appContext: {
            from: AppContext,
        },
    },
    props: {
        columns: PropTypes.array(),
        data: PropTypes.array().isRequired,
        dateField: PropTypes.string().def('date'),
        dateRange: PropTypes.array(),
        expandable: PropTypes.bool().def(false),
        expandedTitle: PropTypes.string(),
        filter: PropTypes.string(),
        fitColumns: PropTypes.bool().def(false),
        gridStyle: PropTypes.string(),
        name: PropTypes.string(),
        options: PropTypes.object(),
        sidebar: PropTypes.bool().def(true),
    },
    data() {
        return {
            api: undefined,
            usingCustomSchema: false, // we avoid reloading columns if custom schema is in place
            showGridModal: false,
            isEmbedded: this.appContext.isEmbedded.value,
            gridOptions: merge(
                {
                    autoSizeStrategy: {
                        type: 'fitCellContents',
                    },
                    columnMenu: 'new',
                    enableCharts: true,
                    enableRangeSelection: true,
                    columnDefs: this.columns,
                    rowHeight,
                    headerHeight,
                    floatingFiltersHeight: headerHeight,
                    defaultColDef: {
                        filter: 'agTextColumnFilter',
                        width: 150,
                        cellClass: 'default',
                        sortable: true,
                        resizable: true,
                        filterParams: { newRowsAction: 'keep' },
                        enablePivot: true,
                        enableRowGroup: true,
                    },
                    suppressAggFuncInHeader: true,
                    rowGroupPanelShow: 'always',
                    pivotColumnGroupTotals: 'after',
                    pivotRowTotals: 'after',
                    columnTypes: {
                        booleanColumn: {
                            filter: 'agSetColumnFilter',
                            valueFormatter: ({ value }) => (value === true ? 'Yes' : value === false ? 'No' : null),
                        },
                        dateColumn: {
                            filter: this.dateRange ? false : 'agDateColumnFilter',
                            valueFormatter: ({ value }) => (value ? moment(new Date(value)).format('L') : null),
                            filterParams: {
                                comparator(base_value, comparator_value) {
                                    const a = new Date(base_value);
                                    const momentA = a.getFullYear() + '-' + (a.getMonth() + 1) + '-' + a.getDate();
                                    const b = new Date(comparator_value);
                                    const momentB = b.getFullYear() + '-' + (b.getMonth() + 1) + '-' + b.getDate();

                                    const formattedMomentA = new Date(momentA);
                                    const formattedMomentB = new Date(momentB);

                                    if (formattedMomentA < formattedMomentB) {
                                        return 1;
                                    }

                                    if (formattedMomentA > formattedMomentB) {
                                        return -1;
                                    }

                                    return 0;
                                },
                            },
                        },
                        dateOrNullColumn: {
                            filter: this.dateRange ? false : 'agDateColumnFilter',
                            valueFormatter: ({ value }) => (value ? moment(new Date(value)).format('L') : 'N/A'),
                            filterParams: {
                                comparator(base_value, comparator_value) {
                                    const a = new Date(base_value);
                                    const momentA = a.getFullYear() + '-' + (a.getMonth() + 1) + '-' + a.getDate();
                                    const b = new Date(comparator_value);
                                    const momentB = b.getFullYear() + '-' + (b.getMonth() + 1) + '-' + b.getDate();

                                    const formattedMomentA = new Date(momentA);
                                    const formattedMomentB = new Date(momentB);

                                    if (formattedMomentA < formattedMomentB) {
                                        return 1;
                                    }

                                    if (formattedMomentA > formattedMomentB) {
                                        return -1;
                                    }

                                    return 0;
                                },
                            },
                        },
                        qBoLastTransactionColumn: {
                            filter: this.dateRange ? false : 'agDateColumnFilter',
                            valueFormatter: ({ value }) => (value ? moment(value).format('L') : 'N/A'),
                            filterParams: {
                                comparator(base_value, comparator_value) {
                                    const a = new Date(base_value);
                                    const momentA = a.getFullYear() + '-' + (a.getMonth() + 1) + '-' + a.getDate();
                                    const b = new Date(comparator_value);
                                    const momentB = b.getFullYear() + '-' + (b.getMonth() + 1) + '-' + b.getDate();

                                    const formattedMomentA = new Date(momentA);
                                    const formattedMomentB = new Date(momentB);

                                    if (formattedMomentA < formattedMomentB) {
                                        return 1;
                                    }

                                    if (formattedMomentA > formattedMomentB) {
                                        return -1;
                                    }

                                    return 0;
                                },
                            },
                        },
                        dateTimeColumn: {
                            valueFormatter: ({ value }) => (value ? moment(value).format('L HH:mm') : null),
                        },
                        numberColumn: {
                            enableValue: true,
                            filter: 'agNumberColumnFilter',
                            headerClass: 'ag-numeric-header',
                            cellClass: ['default', 'ag-numeric-cell'],
                            aggFunc: 'sum',
                            allowedAggFuncs: ['sum', 'min', 'max', 'avg'],
                            valueFormatter: ({ value }) => format(value, 2, 0),
                        },
                        moneyColumn: {
                            enableValue: true,
                            headerClass: 'ag-numeric-header',
                            cellClass: ['default', 'ag-numeric-cell'],
                            filter: 'agNumberColumnFilter',
                            aggFunc: 'sum',
                            allowedAggFuncs: ['sum', 'min', 'max', 'avg', 'count'],
                            valueParser: ({ newValue }) => round(newValue, 2),
                            valueFormatter: ({ value }) => format(value),
                        },
                        moneyOrNullColumn: {
                            enableValue: true,
                            headerClass: 'ag-numeric-header',
                            cellClass: ['default', 'ag-numeric-cell'],
                            aggFunc: 'sum',
                            filter: 'agNumberColumnFilter',
                            valueParser: ({ newValue }) => {
                                return newValue ? round(newValue, 2) : 'N/A';
                            },
                            valueFormatter: ({ value }) => {
                                return value ? format(value) : 'N/A';
                            },
                        },
                        metaColumn: {
                            menuTabs: [],
                            suppressToolPanel: true,
                        },
                    },
                    isExternalFilterPresent: () => !!this.dateRange && this.dateRange.some((d) => d !== null),
                    doesExternalFilterPass: this.dateInRange,
                    sideBar: this.sidebar ? { toolPanels: ['columns', 'filters'] } : false,
                    getContextMenuItems(params) {
                        return [
                            'autoSizeAll',
                            'copy',
                            'copyWithHeaders',
                            ...(params.api.getRowGroupColumns().length
                                ? ['separator', 'expandAll', 'contractAll']
                                : []),
                            'separator',
                            'csvExport',
                            'chartRange',
                        ];
                    },
                    excelStyles: [
                        {
                            id: 'default',
                            font: excelFont,
                        },
                        {
                            id: 'header',
                            font: excelFont,
                        },
                    ],
                },
                this.$props.options ?? {}
            ),
            busy: false,
        };
    },

    watch: {
        filter(value) {
            this.api.setGridOption('quickFilterText', value);
        },
        dateRange() {
            this.api.onFilterChanged();
        },
        columns() {
            if (this.usingCustomSchema) {
                return;
            }

            this.api.setGridOption('columnDefs', this.columns);
        },
    },
    methods: {
        onGridReady(params) {
            this.api = params.api;

            this.$emit('ready', params);
        },

        onFirstDataRenderered(params) {
            this.fitColumns && params.api.autoSizeAllColumns();
        },

        getSchema() {
            const autoColumn = this.api.getColumn('ag-Grid-AutoColumn');
            let autoColumnDef = null;

            if (autoColumn) {
                autoColumnDef = {
                    colId: autoColumn.colId,
                    width: autoColumn.actualWidth,
                    pinned: autoColumn.pinned,
                };
            }

            return {
                groups: this.api.getColumnGroupState(),
                columns: this.api.getColumnState(),

                filter: this.api.getFilterModel(),
                pivotMode: this.api.isPivotMode(),
                autoColumn: autoColumnDef,
            };
        },
        setSchema(schema) {
            if (!this.api) return;

            this.usingCustomSchema = true;
            this.busy = true;
            this.api.showLoadingOverlay();

            return new Promise((resolve) => {
                // Timeout to allow busy status to update before doing a potentially slow schema set
                setTimeout(() => {
                    this.api.applyColumnState({ state: schema.columns, applyOrder: true });
                    this.api.setColumnGroupState(schema.groups);
                    this.api.setGridOption('pivotMode', schema.pivotMode);

                    /**
                     * This is to map the old sort method to the new one. AG Grid removed the way of sorting in v24.0.0,
                     * and we need to do this to make sure user saved tables are still functional. Should be removed
                     * if we ever run a map on the database to update the saved tables.
                     *
                     * @TODO Remove this when we run a map on the database to update the saved tables.
                     */
                    if (schema.sort) {
                        const hasNewSortMethodSaved = schema.columns.some((col) => {
                            return 'sort' in col && 'sortIndex' in col;
                        });

                        if (!hasNewSortMethodSaved) {
                            const sortClone = [...schema.sort];

                            delete schema.sort;
                            sortClone.forEach((sort, index) => {
                                this.api.applyColumnState({
                                    state: [{ colId: sort.colId, sort: sort.sort, sortIndex: index }],
                                });
                            });
                        }
                    }

                    if (schema.autoColumn) {
                        const { colId, width, pinned } = schema.autoColumn;

                        this.api.setColumnWidths([colId], width);
                        this.api.setColumnsPinned([colId], pinned);
                    }

                    this.api.setFilterModel(schema.filter);

                    // Give the grid a change to redraw before considering this action completed
                    setTimeout(() => {
                        this.busy = false;
                        this.api.hideOverlay();

                        resolve(null);
                    }, 400);
                });
            });
        },
        setColumnFilter(columnName, filterModel) {
            const filter = this.api.getColumnFilterInstance(columnName);

            filter.setModel(filterModel);

            this.api.onFilterChanged();
        },
        sizeColumnsToFit() {
            this.api.autoSizeAllColumns();
        },

        exportToExcel(fileName = null, sheetName = 'Export') {
            exportToExcel(this.api, { fileName, sheetName });
        },

        dateInRange({ data }) {
            const [from, to] = this.dateRange;
            const cellDate = moment.utc(data[this.dateField]);

            return cellDate >= moment.utc(from) && cellDate <= moment.utc(to);
        },
        schemaChanged(e) {
            this.$emit('schema-changed', e);
        },
    },
});
</script>

<style lang="scss">
.ag-grid-container {
    flex: 1;
    height: 100%;
    width: 100%;
}

.maximise-height {
    flex: 1; // In case we are in a flexbox container, take up available space
    height: 100%;
    width: 100%;
}

.grid--busy {
    opacity: 0.5;
}

.center-header {
    display: flex !important;
    justify-content: center;
}
</style>

<style lang="scss" scoped>
.grid .expand-button {
    margin-bottom: 5px;
}
</style>
