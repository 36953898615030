<template functional>
    <a :href="props.client.providerLink" class="btn btn-sm btn-primary" :client="props.client" target="_blank">{{
        providerLinkText
    }}</a>
</template>

<script>
import linkText from '@/utils/linkText';

export default {
    props: {
        client: { type: Object, required: true },
    },
    computed: {
        providerLinkText() {
            return linkText(this.client);
        },
    },
};
</script>
