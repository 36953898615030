import BaseApiService from './BaseApiService';

class KeepAliveService extends BaseApiService {
    /**
     * This endpoint always returns a success given the users authentication status is authorised.
     * @returns Promise
     */
    ping() {
        return this.get('keep-alive.ping');
    }
}

export default Object.freeze(new KeepAliveService());
