<template>
    <div :class="className" :data-qa="`system-bar-${qaName}`" v-if="isVisible">
        <div class="system-bar__content">
            <slot />
        </div>

        <div class="system-bar__actions" v-if="Boolean($slots.actions)">
            <slot name="actions" />
        </div>

        <div class="system-bar__dismiss-container">
            <VButton
                class="system-bar__dismiss-button"
                icon
                :name="`dismiss-system-bar-${qaName}`"
                text
                title="Dismiss banner"
                @click.prevent="isVisible = false"
                v-if="dismissable"
            >
                <VIcon decorative name="close-thick" size="16px" :vertical-align="false" />
            </VButton>
        </div>
    </div>
</template>

<script>
const validTypes = ['info', 'error', 'success', 'warning'];

export default {
    name: 'SystemBar',

    model: {
        prop: 'visible',
        event: 'visibility-change',
    },

    props: {
        bottom: {
            default: false,
            required: false,
            type: Boolean,
        },
        dismissable: {
            default: false,
            required: false,
            type: Boolean,
        },
        iconName: {
            default: null,
            required: false,
            type: String,
        },
        small: {
            default: false,
            required: false,
            type: Boolean,
        },
        qaName: {
            default: null,
            required: false,
            type: String,
        },
        type: {
            default: '',
            required: false,
            type: String,
            validator: (value) => {
                if (!value) return true;

                const isValid = validTypes.includes(value);

                if (!isValid) {
                    // eslint-disable-next-line no-console
                    console.error(`[Vue warn]: Invalid prop: invalid type of "${value}" provided for prop "type".`);
                }

                return isValid;
            },
        },
        visible: {
            default: true,
            required: false,
            type: Boolean,
        },
    },

    data() {
        return {
            isVisible: !!this.visible,
        };
    },

    computed: {
        className() {
            return {
                'system-bar': true,
                'system-bar--bottom': this.bottom,
                'system-bar--dismissable': this.dismissable,
                'system-bar--has-actions': Boolean(this.$slots.actions),
                [`system-bar--${this.type}`]: Boolean(this.type),
            };
        },
    },

    watch: {
        visible(value) {
            this.isVisible = !!value;
        },

        isVisible(value) {
            !!value !== this.visible && this.$emit('visibility-change', value);
        },
    },
};
</script>

<style lang="scss" scoped>
@import 'style/dext/includes';

$variants: (
    error: red,
    info: blue,
    success: green,
    warning: amber,
);

.system-bar {
    align-items: center;
    background-color: $color-white;
    border-width: 0;
    border-bottom-width: 1px;
    border-color: get-color(silver);
    border-style: solid;
    color: get-color(charcoal, lite);
    display: flex;
    font-size: pxtoem(13);
    padding: 8px 13px;
    position: relative;
    width: 100%;
}

.system-bar--bottom {
    border-bottom-width: 0;
    border-top-width: 1px;
}

.system-bar__dismiss-container {
    height: 26px;
    margin-left: auto;
    margin-top: -13px;
    position: absolute;
    right: 9px;
    top: 50%;
    width: 26px;
}

.system-bar__dismiss-button {
    color: inherit;
    height: 100%;
    padding: 0;
    width: 100%;
}

@each $variant-name, $color in $variants {
    .system-bar--#{$variant-name} {
        background-color: get-color($color, lite);
        border-color: get-color($color, medium);

        .system-bar__dismiss-button {
            color: get-color($color);
        }
    }
}

.system-bar__actions {
    margin-left: auto;
}

.system-bar__dismiss-button:hover {
    color: get-color(blue, dark);
}

.system-bar--dismissable:not(.system-bar--has-actions) {
    .system-bar__content {
        /*
         * Magic number accounts for the width of the button minus it's
         * `position: right` value, and some visual alignment perfection.
         */
        padding-right: 37px;
    }
}

.system-bar--dismissable.system-bar--has-actions {
    .system-bar__actions {
        /*
         * Magic number accounts for the width of the button minus it's
         * `position: right` value, and some visual alignment perfection.
         */
        padding-right: 37px;
    }
}
</style>
