import type { RouteConfig } from 'vue-router';

import { extractClientIdParam } from '../utils';

const settingsRoutes: RouteConfig[] = [
    {
        path: 'settings/general',
        component: () => import('@/scenes/ClientSettings/ClientSettingsScene.vue'),
        props: extractClientIdParam,
        name: 'client.settings.general',
    },
    {
        path: 'settings/visibility',
        component: () => import('@/scenes/ClientVisibility/ClientVisibilityScene.vue'),
        props: extractClientIdParam,
        name: 'client.settings.visibility',
    },
    {
        path: 'settings/overview-configuration',
        component: () => import('@/scenes/ClientOverviewConfig/ClientOverviewConfigScene.vue'),
        props: extractClientIdParam,
        name: 'client.settings.overviewConfiguration',
    },
    {
        path: 'settings/account-configuration',
        component: () => import('@/scenes/ClientAccountSettings/ClientAccountSettingsScene.vue'),
        props: extractClientIdParam,
        name: 'client.settings.accountConfiguration',
    },
    {
        path: 'settings/reconciliation-configuration',
        component: () => import('@/scenes/ReconciliationConfig/ReconciliationConfigScene.vue'),
        props: extractClientIdParam,
        name: 'client.settings.reconciliationConfiguration',
    },
    {
        path: 'settings/unintegrated-configuration',
        component: () => import('@/scenes/UnintegratedTaxSettings/UnintegratedTaxSettingsScene.vue'),
        props: extractClientIdParam,
        name: 'client.settings.unintegratedTaxConfiguration',
    },
];

export { settingsRoutes };
