<template>
    <Fragment>
        <div
            :class="[
                'stat-section',
                'text-center',
                { clickable: active && $xavier.hasPermission('canManageTeamMembers') },
            ]"
            @click.prevent="takeAction"
        >
            <div class="d-flex">
                <VAvatar :avatar="avatar" v-if="avatar && active" />
            </div>
            <div class="stat-name">
                <p>{{ name }}</p>
                <p>{{ email }}</p>
            </div>
            <div class="d-flex nowrap ml-auto">
                <div class="key-no text-center">
                    <VButton
                        icon
                        :loading="removingUser"
                        name="Remove Team Member"
                        @click.prevent.stop="removeUser"
                        v-if="active && canDelete && !alwaysHasAccess"
                    >
                        <span class="sr-only">Remove team member</span>
                        <VIcon decorative :name="removingIcon" />
                    </VButton>
                    <VButton
                        icon
                        name="Click On Team Owner"
                        @click.prevent.stop=""
                        v-if="alwaysHasAccess"
                        v-tooltip="alwaysHasAccessTooltip"
                    >
                        <span class="sr-only">Team Owner</span>
                        <VIcon decorative name="crown-thick" />
                    </VButton>
                    <template v-if="!active && canInvite">
                        <VButton
                            :loading="sendInviteInProgress"
                            name="Invite Team Member To Client"
                            secondary
                            @click.prevent="inviteModalVisible = true"
                            v-if="invitePending && !sentInvite"
                        >
                            <span class="sr-only">Invite Team Member</span>
                            <template #icon-left>
                                <VIcon decorative :name="inviteIcon" />
                            </template>
                            Invite
                        </VButton>
                        <VButton class="ml-2" disabled name="Invite Pending" primary v-else> Invite Pending </VButton>
                    </template>
                    <template v-if="!active && canAdd">
                        <VButton :loading="addingUser" name="Add Team Member" primary @click.prevent="addUser">
                            <template #icon-left>
                                <VIcon decorative :name="addingIcon" />
                            </template>
                            Add
                        </VButton>
                    </template>
                </div>
            </div>
        </div>

        <ConfirmationModal
            :loading="sendInviteInProgress"
            name="Send User Invite"
            @confirm="onInviteConfirm"
            v-model="inviteModalVisible"
        >
            <template #title>Invite {{ name }} to your Dext Precision team?</template>

            An invite will be sent via email. After they join the team, they will be able to view all clients with
            "Whole Team" visibility, plus those they have access to in their accounting software.
        </ConfirmationModal>
    </Fragment>
</template>

<script>
import { defineComponent, reactive, toRefs } from 'vue';
import axios from 'axios';
import { Fragment } from 'vue-frag';

import ConfirmationModal from '@/components/ConfirmationModal';

export default defineComponent({
    name: 'UserListItem',
    inject: ['analyticsProvider'],

    components: {
        Fragment,
        ConfirmationModal,
    },
    props: {
        userId: { type: Number, default: null },
        name: { type: String, required: true },
        email: { type: String, required: true },
        avatar: { type: String, default: null },
        alwaysHasAccess: { type: Boolean, default: false },
        active: { type: Boolean, default: true },
        canInvite: { type: Boolean, default: false },
        canAdd: { type: Boolean, default: false },
        canDelete: { type: Boolean, default: true },
        invitePending: { type: Boolean, default: false },
        segmentName: { type: String, required: false, default: '' },
    },

    setup() {
        const state = reactive({
            inviteModalVisible: false,
            sendInviteInProgress: false,
        });

        return { ...toRefs(state) };
    },

    data() {
        return {
            sentInvite: false,
            addingUser: false,
            removingUser: false,
        };
    },
    computed: {
        alwaysHasAccessTooltip() {
            if (this.$xavier.isEnterprise) {
                return 'Team Owners have access to all clients';
            }

            return 'Team Owners and Admins have access to all clients';
        },
        inviteIcon() {
            return this.sendInviteInProgress ? 'sync' : 'send-thick';
        },
        addingIcon() {
            return this.addingUser ? 'sync' : 'zoom-add-plus-thick';
        },
        removingIcon() {
            return this.removingUser ? 'sync' : 'delete-filled';
        },
    },
    methods: {
        takeAction() {
            if (this.active && this.$xavier.hasPermission('canManageTeamMembers')) {
                this.trackClick();
                this.$router.push({ name: 'team.members' });
            }
        },
        onInviteConfirm() {
            this.sendInviteInProgress = true;

            axios
                .post(`/settings/teams/${this.$xavier.teamId}/invitations`, { email: this.email })
                .then(() => {
                    this.sentInvite = true;
                    this.sendInviteInProgress = false;
                })
                .catch(() => {
                    this.$toaster.error(`Could not invite ${this.name} to the team, please try again.`, {
                        timeout: 10000,
                    });
                    this.sendInviteInProgress = false;
                });
        },
        async addUser() {
            this.addingUser = true;
            this.$emit('addUser');
        },
        async removeUser() {
            this.removingUser = true;
            this.$emit('removeUser');
        },
        trackClick() {
            Boolean(this.segmentName) && this.analyticsProvider.trackElementClick(this.segmentName, 'Card');
        },
    },
});
</script>

<style lang="scss" scoped>
@import 'style/dext/includes';

.stat-section {
    align-items: center;
    color: get-color(charcoal, lite) !important;
    display: flex;
    flex-wrap: wrap;
    padding: 5px 0;

    &:hover {
        color: get-color(charcoal, lite) !important;
    }

    &:first-child {
        padding-top: 5px;
    }

    &:last-child {
        padding-bottom: 5px;
    }

    &:not(:last-child) {
        border-bottom: 1px solid #efefef;
    }

    .key-no {
        margin: 0 5px 0 10px;
        transition: all 0.2s ease-in-out;
    }

    .stat-avatar {
        flex: 1;
        margin: 0 10px;
        text-align: left;
        transition: all 0.2s ease-in-out;
    }

    .stat-name {
        flex: 1;
        margin: 0 10px;
        text-align: left;
        transition: all 0.2s ease-in-out;

        p {
            margin: 0;

            &:nth-child(2) {
                font-size: 80%;
            }
        }
    }

    img {
        height: 38px;
        padding: 5px 10px 5px 5px;
        transition: all 0.2s ease-in-out;
    }
}

.clickable:hover {
    cursor: pointer;
}
</style>
