export default {
    bind(element, binding) {
        /**
         * We have to explicitly check for false, as most values can be truthy,
         * which can cause unexpected behaviour. We need to make sure that if
         * false is passed, then it does not display.
         */
        const isVisible = Boolean(binding.value) === false ? false : true;

        if (isVisible) {
            element.setAttribute('target', '_blank');
            element.setAttribute('rel', 'noopener noreferrer');
        }
    },

    update(element, binding) {
        const isVisible = Boolean(binding.value) === false ? false : true;
        const target = isVisible ? '_blank' : false;
        const rel = isVisible ? 'noopener noreferrer' : false;

        target && element.setAttribute('target', target);
        rel && element.setAttribute('rel', rel);
    },
};
