import type { RouteConfig } from 'vue-router';

import { extractClientIdParam } from '../utils';

const focusRoutes: RouteConfig[] = [
    {
        path: 'focus',
        component: () => import('@/scenes/ClientFocus/ClientFocusScene.vue'),
        props: extractClientIdParam,
        name: 'client.focus',
    },
    {
        path: 'focus/flow-creator',
        component: () => import('@/scenes/ClientFlowCreator/ClientFlowCreatorScene.vue'),
        props: extractClientIdParam,
        name: 'client.focus.flowCreator',
    },
];

export { focusRoutes };
