<template>
    <div class="disconnected-client text-left">
        <PButton v-if="subscriptionValid" qa-name="Go to Client" text @click="goToClientOverview">
            {{ params.data.name }}
        </PButton>

        <span v-else class="text-primary">
            {{ params.data ? params.data.name : '' }}
        </span>

        <DBadge variant="error">Disconnected</DBadge>
    </div>
</template>

<script>
import Vue from 'vue';

import { dispatchIframeEvent } from '@/App/composables/useIframeEventHandlers';
import { useTeamContext } from '@/App/composables/useTeamContext';
import { DBadge } from '@/components/Badge';
import { PButton } from '@/components/PButton';
import { IS_EMBEDDED } from '@/config/env';
import { ClientNavigationPathNames } from '@/enums/prepare/IFrameNavigationPathNames';
import useXavierGlobals from '@/hooks/useXavierGlobals';

export default Vue.extend({
    name: 'DisconnectedClientRenderer',

    components: { DBadge, PButton },

    props: {
        params: {
            required: true,
            type: Object,
        },
    },

    setup() {
        const { currentTeam } = useTeamContext('disconnectedClientRenderer');
        const { subscriptionValid } = useXavierGlobals();

        return { currentTeam, subscriptionValid };
    },

    methods: {
        goToClientOverview() {
            if (IS_EMBEDDED) {
                return dispatchIframeEvent({
                    action: 'NAVIGATE',
                    payload: {
                        clientCrn: this.params.data.slug,
                        pathName: ClientNavigationPathNames.CLIENT_OVERVIEW,
                        practiceCrn: this.currentTeam.rbExternalId.toString(),
                    },
                });
            }

            this.$router.push({
                name: 'client.overview',
                params: { clientSlug: this.params.data.slug },
            });
        },
    },
});
</script>

<style lang="scss">
.disconnected-client {
    align-items: center;
    display: flex;
    height: 100%;
    width: 100%;
}

.d-badge {
    margin-left: auto;
}
</style>
