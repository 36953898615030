<template>
    <div class="d-inline-flex align-items-center">
        <span class="nav-link-group secondary-nav-link mr-1" v-if="icon && showIcon">
            <VIcon :name="icon" size="1.3em" v-tooltip="tooltip" />
        </span>
        <span class="nav-link-group secondary-nav-link" v-if="title">{{ title }}</span>
        <template v-for="(option, index) in optionCollection">
            <a
                :class="['py-0 nav-link-group secondary-nav-link', { active: selectedOption === option.value }]"
                :key="`link-${index}`"
                @click.prevent.stop="selectOption(option)"
            >
                {{ option.text }}
            </a>
            <span class="py-0 secondary-nav-link clearfix-sm" :key="`seperator-${index}`" v-if="!isLastOption(index)"
                >|</span
            >
        </template>
    </div>
</template>

<script>
export default {
    props: {
        options: { type: Object, required: true },
        value: { type: [String, Boolean, Number], default: null },
        title: { type: String, default: null },
        icon: { type: String, default: 'filter-thick' },
        tooltip: { type: String, default: null },
        showIcon: { type: Boolean, default: true },
    },
    data() {
        return {
            selectedOption: this.value,
        };
    },
    computed: {
        optionCollection() {
            return Object.entries(this.options).map(([key, value]) => ({ text: key, value }));
        },
    },
    methods: {
        selectOption(option) {
            this.selectedOption = option.value;

            this.$emit('input', option.value);
        },
        isLastOption(index) {
            return index === this.optionCollection.length - 1;
        },
    },
};
</script>
