<template>
    <nav class="top-nav fs-unmask">
        <div class="left">
            <AppSwitcher @user-access-updated="isAppSwitcherRendered = $event" />
            <VDextLogo class="dext-logo" variant="precision-with-xavier" />

            <VButton
                class="button-try-prepare-ipd"
                color="primary"
                :href="tryPrepareUrl"
                link
                name="Try Prepare IPD"
                new-tab
                v-if="showPrepareIpdButton"
            >
                Try Prepare
            </VButton>

            <slot name="left" />
            <PortalTarget class="left-portal" name="top-nav-left" />
            <div
                :class="{ 'practices-switcher-left-margin': !isAppSwitcherRendered && !showPrepareIpdButton }"
                v-if="isDextAccountSwitcherEnabled"
            >
                <DextAccountSwitcher />
            </div>
        </div>

        <div class="right">
            <PortalTarget name="top-nav-right">
                <TopNavActions />
            </PortalTarget>

            <VButton
                class="button-resources-link top-nav-link ml-1"
                href="https://help.dext.com/en/category/precision"
                link
                name="resources"
                new-tab
                v-if="!userVoiceEnabled"
            >
                <template #icon-left>
                    <VIcon class="top-nav-link-icon" name="resources-thick" size="16px" :vertical-align="false" />
                </template>
                <span>Resources</span>
            </VButton>

            <ResourcesMenu v-if="userVoiceEnabled" />

            <ProfileDropdown />
        </div>
    </nav>
</template>

<script>
import { defineComponent, ref } from 'vue';

import { Feature } from '@/enums/Feature';
import useIsFeatureEnabled from '@/store/modules/feature/hooks/useIsFeatureEnabled';

import AppSwitcher from './AppSwitcher/AppSwitcher.vue';
import ResourcesMenu from './ResourcesMenu/ResourcesMenu.vue';
import TopNavActions from './TopNavActions.vue';
import DextAccountSwitcher from '@/layout/TopNav/AccountSwitcher/DextAccountSwitcher.vue';
import ProfileDropdown from '@/layout/TopNav/Profile/ProfileDropdown.vue';

export default defineComponent({
    name: 'TopNav',

    components: {
        ProfileDropdown,
        DextAccountSwitcher,
        AppSwitcher,
        ResourcesMenu,
        TopNavActions,
    },

    setup(props) {
        const isAppSwitcherRendered = ref(false);
        const { dextPrepareTrialUrl: tryPrepareUrl, teamIsDext, teamProducts, userIsDext, isTeamOwner } = window.Xavier;

        const hasPrepare = Boolean(teamProducts.prepare);
        const isPrecisionTrial = teamProducts.precision === 'trial';
        const isValidDext = teamIsDext && userIsDext;

        const showPrepareIpdButton = isValidDext && !hasPrepare && !isPrecisionTrial && isTeamOwner;
        const userVoiceEnabled = useIsFeatureEnabled(Feature.USER_VOICE_MENU);

        return {
            isAppSwitcherRendered,
            showPrepareIpdButton,
            tryPrepareUrl,
            userVoiceEnabled,
        };
    },

    computed: {
        isDextAccountSwitcherEnabled() {
            /**
             * TopNav component is loaded before the store is loaded
             */
            return this.$store && this.$store.getters['teams/isDextAccountSwitcherEnabled'];
        },
    },
});
</script>

<style lang="scss" scoped>
@import 'style/dext/includes';

.top-nav {
    align-items: center;
    background-color: $color-white;
    border-bottom: 1px solid get-color(silver);
    display: flex;
    height: 55px;
    padding-bottom: 7px;
    padding-right: 20px;
    padding-top: 7px;
    position: relative;
    width: 100%;
}

.dext-logo {
    display: block;
    margin-left: 16px;
    max-width: 160px;
}

.top-nav-link {
    & + & {
        margin-left: 8px;
    }
}

.left {
    align-items: center;
    display: flex;
}

.left-portal {
    margin-left: 10px;
}

.right {
    align-items: center;
    display: flex;
    margin-left: auto;
}

.right-portal {
    margin-right: 10px;
}

.button-try-prepare-ipd {
    margin-left: 20px !important;
}

.practices-switcher-left-margin {
    margin-left: pxtoem(40);
}
</style>
