<template>
    <AnalyticsProvider>
        <div class="error-layout" data-qa="page-layout-error">
            <TopNav v-if="topNavEnabled" />

            <ContentContainer>
                <DCard>
                    <template #title>
                        <div v-if="Boolean(slots.image)" class="heading-image-container">
                            <slot name="image" />
                        </div>
                    </template>

                    <VDextLogo v-if="dextLogoEnabled" class="dext-logo" />

                    <h3 v-if="Boolean(slots.title)" class="error-title">
                        <slot name="title" />
                    </h3>

                    <div class="card-body text-center">
                        <slot />
                    </div>
                </DCard>
            </ContentContainer>
        </div>
    </AnalyticsProvider>
</template>

<script setup lang="ts">
import { useSlots } from 'vue';

import { DCard } from '@/components/DextUi';
import { TopNav } from '@/layout/TopNav';
import { AnalyticsProvider } from '@/providers/AnalyticsProvider';

import { ContentContainer } from '../Content';

const slots = useSlots();

type Props = {
    topNavEnabled?: boolean;
    dextLogoEnabled?: boolean;
};

withDefaults(defineProps<Props>(), {
    topNavEnabled: true,
    dextLogoEnabled: true,
});
</script>

<style scoped>
.error-layout {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.page-content {
    align-items: center;
    display: flex;
    flex: 1;
    font-size: var(--d-font-size-large);
    justify-content: center;
}

.d-card {
    max-width: 730px;
    width: 100%;
}

.heading-image-container {
    margin: var(--d-spacing-3) auto;
    max-width: 205px;

    :deep(img) {
        display: block;
        width: 100%;
    }
}

.dext-logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 80px;
}

.error-title {
    font-size: var(--d-font-size-h1);
    font-weight: var(--d-font-weight-bold);
    margin-bottom: 0;
    margin-top: 30px;
    text-align: center;
}

:deep(.content-container__inner) {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
</style>
