<template>
    <CountdownTimer
        ref="countdownTimer"
        :seconds="timeoutThresholdSeconds"
        use-worker
        @ended="countdownEnded = true"
        v-model="countdownPaused"
    >
        <template #default="{ minutes, seconds }">
            <Modal container-class="session-timeout-modal" name="Session Timeout" persistent v-model="isVisible">
                <ModalHeader>{{ countdownEnded ? 'Session timeout' : 'Need more time?' }}</ModalHeader>

                <ModalContent>
                    <template v-if="!countdownEnded">
                        <p>Your session is about to end due to inactivity. Stay logged in to continue working.</p>
                        <div>You will be logged out in:</div>
                        <div class="countdown-modal-content__timer">{{ minutes }}m {{ seconds }}s</div>
                    </template>

                    <template v-if="countdownEnded">
                        You have been logged out due to inactivity. Please log in again to continue using Dext
                        Precision.
                    </template>
                </ModalContent>

                <ModalFooter>
                    <template #right>
                        <VButton
                            href="/logout"
                            link
                            name="session-timeout-go-to-login"
                            :primary="countdownEnded"
                            @click="resetQboClientsClicked"
                        >
                            {{ countdownEnded ? 'Go to login' : 'Log out' }}
                        </VButton>

                        <VButton
                            name="session-timeout-stay-logged-in"
                            primary
                            @click="isVisible = false"
                            v-if="!countdownEnded"
                        >
                            Stay logged in
                        </VButton>
                    </template>
                </ModalFooter>
            </Modal>
        </template>
    </CountdownTimer>
</template>

<script>
import { mapActions } from 'vuex';

import { Modal, ModalContent, ModalFooter, ModalHeader } from '@/components/Modal';
import CountdownTimer from '@/frames/CountdownTimer';

export default {
    model: {
        prop: 'visible',
        event: 'visibility-change',
    },

    components: {
        CountdownTimer,
        Modal,
        ModalContent,
        ModalFooter,
        ModalHeader,
    },

    props: {
        timeoutThresholdSeconds: {
            default: 0,
            required: false,
            type: [Number, String],
        },
        visible: {
            default: false,
            required: false,
            type: Boolean,
        },
    },

    data() {
        return {
            countdownEnded: false,
            countdownPaused: true,
            isVisible: Boolean(this.visible),
        };
    },

    watch: {
        visible(value) {
            this.isVisible = Boolean(value);
        },

        isVisible: {
            immediate: true,
            handler(value) {
                this.$nextTick(() => {
                    this.$emit('visibility-change', value);

                    if (value) {
                        this.isVisible = true;
                        this.$refs.countdownTimer?.startTimer();
                    } else {
                        this.isVisible = false;
                        this.$refs.countdownTimer?.pauseTimer();
                        this.$refs.countdownTimer?.resetTimer();
                    }
                });
            },
        },
    },

    methods: {
        ...mapActions({
            resetQboClientsClicked: 'session/resetQboClientsClicked',
        }),
    },
};
</script>

<style lang="scss">
.session-timeout-modal {
    z-index: 20 !important;
}
</style>

<style lang="scss" scoped>
@import 'style/dext/includes';

.countdown-modal-content {
    font-size: pxtoem(16);
}

.countdown-modal-content__timer {
    font-size: pxtorem(24);
}
</style>
