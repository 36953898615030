import Vue from 'vue';

/**
 * Format the given date.
 */
Vue.filter('date', (value) => {
    return moment.utc(value).local().format('MMMM Do, YYYY');
});

/**
 * Format the given date without timezone adjustment.
 */
Vue.filter('date_global', (value, format = 'DD/MM/YYYY') => {
    return moment(value).local().format(format);
});

/**
 * Format the given date as a timestamp.
 */
Vue.filter('datetime', (value) => {
    return moment.utc(value).local().format('MMMM Do, YYYY h:mm A');
});

/**
 * Format the given date into a relative time.
 */
Vue.filter('relative', (value) => {
    return moment.utc(value).local().locale('en-short').fromNow();
});

/**
 * Convert the first character to upper case.
 *
 * Source: https://github.com/vuejs/vue/blob/1.0/src/filters/index.js#L37
 */
Vue.filter('capitalize', (value) => {
    if (!value && value !== 0) {
        return '';
    }

    return value.toString().charAt(0).toUpperCase() + value.slice(1);
});

/**
 * Format the given money value.
 */
Vue.filter('currency', (value) => {
    // Xavier: check for nulls because they were causing our subscribe page to fail
    if ([undefined, NaN, Infinity, null].includes(value)) {
        return '';
    }

    const Dinero = require('dinero.js').default;

    return Dinero({
        amount: Math.round(value * 100),
        currency: window.Spark.currency,
    })
        .setLocale(window.Spark.currencyLocale)
        .toFormat('$0,0.00');
});
