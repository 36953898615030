import type { RouteConfig } from 'vue-router';
import AdminLayout, { AdminSidebar } from '@/layout/AdminLayout';

const adminRoutes: RouteConfig[] = [
    {
        path: '/admin',
        beforeEnter(_to, _from, next) {
            window.Xavier.isAdmin ? next() : next({ name: 'team.home' });
        },
        meta: { sidebar: AdminSidebar },
        component: AdminLayout,
        children: [
            {
                path: 'advanced-features',
                component: () => import('@/scenes/AdvancedFeatures/AdvancedFeaturesScene.vue'),
                name: 'admin.advancedFeatures',
            },
            {
                path: 'status',
                component: () => import('@/scenes/AiStatus/AiStatusScene.vue'),
                name: 'admin.status',
            },
            {
                path: 'team-stats',
                component: () => import('@/scenes/TeamStats/TeamStatsScene.vue'),
                name: 'admin.teamStats',
            },
            {
                path: 'reports',
                component: () => import('@/scenes/AdminReportList/AdminReportListScene.vue'),
                name: 'admin.reports',
            },
            {
                path: 'stats-report/:reportId',
                component: () => import('@/scenes/TeamStats/TeamStatsScene.vue'),
                props: (route) => route.params,
                name: 'admin.report.details',
            },
            {
                path: 'advanced-features-report/:reportId',
                component: () => import('@/scenes/AdvancedFeatures/AdvancedFeaturesScene.vue'),
                props: (route) => route.params,
                name: 'admin.advancedFeaturesReport.details',
            },
            {
                path: 'advisors',
                component: () => import('@/scenes/Advisors/AdvisorsScene.vue'),
                name: 'admin.advisors',
            },
            {
                path: 'imports',
                component: () => import('@/scenes/ImportStatus.vue'),
                name: 'admin.imports',
            },
            {
                path: 'pdf-preview',
                component: () => import('@/scenes/PdfPreview.vue'),
                name: 'admin.pdfPreview',
            },
        ],
    },
];

export { adminRoutes };
