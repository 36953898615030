/* eslint-disable sort-keys */

import { defineComponent } from 'vue';

import { Feature } from '@/enums/Feature';
import { xavier } from '@/plugins/xavierClientGlobals';

import type { BreadcrumbConfig } from '@/layout/PageLayout/Breadcrumbs/Breadcrumbs.types';
import type { RouteConfig } from 'vue-router';

const childIds = {
    AVERAGE_HEALTH_SCORE: 'averageHealthScore',
    DIRECTORS_LOAN_ACCOUNTS: 'directorsLoanAccounts',
    FLOWS_DUE: 'flowsDue',
    LOCK_DATES: 'lockDates',
    RECONCILIATION_ACTIVITY: 'reconciliationActivity',
    TOTAL_TRANSACTIONS: 'totalTransactions',
} as const;

function getChildBreadcrumbs(currentId: (typeof childIds)[keyof typeof childIds]): BreadcrumbConfig[] {
    return [
        {
            id: childIds.AVERAGE_HEALTH_SCORE,
            label: 'Healthscore',
            to: { name: 'team.insightDashboard.averageHealthScore' },
        },
        {
            id: childIds.DIRECTORS_LOAN_ACCOUNTS,
            label: 'Directors loan accounts',
            to: { name: 'team.insightDashboard.directorsLoanAccounts' },
        },
        {
            id: childIds.FLOWS_DUE,
            label: 'Workflows due within 7 days',
            to: { name: 'team.insightDashboard.flowsDue' },
        },
        {
            id: childIds.LOCK_DATES,
            label: 'Lock dates',
            to: { name: 'team.insightDashboard.lockDateActivity' },
        },
        {
            id: childIds.RECONCILIATION_ACTIVITY,
            label: 'Reconciliation activity',
            to: { name: 'team.insightDashboard.reconciliationActivity' },
        },
        {
            id: childIds.TOTAL_TRANSACTIONS,
            label: 'Total transactions',
            to: { name: 'team.insightDashboard.totalTransactions' },
        },
    ].map((item) => {
        return { ...item, disabled: item.id === currentId };
    });
}

const insightsDashboardRoutes: RouteConfig[] = [
    {
        path: 'insights-dashboard',
        component: defineComponent({ render: (h) => h('router-view') }),
        meta: {
            breadcrumbs: [
                {
                    label: 'Insights dashboard',
                    iconName: 'dext-back-thick',
                    to: { name: 'team.practiceInsightsDashboard' },
                },
            ],
        },
        children: [
            {
                path: '/',
                component: () => import('@/scenes/InsightsDashboard/InsightsDashboardSceneContainer.vue'),
                name: 'team.practiceInsightsDashboard',
                meta: { breadcrumbs: false },
            },
            {
                path: 'average-health-score',
                component: () =>
                    import(
                        '@/scenes/InsightsDashboard/AverageHealthScore/AverageHeathScoreDetailedView/AverageHeathScoreDetailedViewScene.vue'
                    ),
                name: 'team.insightDashboard.averageHealthScore',
                meta: {
                    breadcrumbs: [
                        {
                            label: 'Healthscore',
                            children: getChildBreadcrumbs('averageHealthScore'),
                        },
                    ],
                },
            },
            {
                path: 'directors-loan-accounts',
                component: () =>
                    import(
                        '@/scenes/InsightsDashboard/DirectorsLoanAccounts/DirectorsLoanAccountsDetailedView/DirectorsLoanAccountsDetailedViewScene.vue'
                    ),
                name: 'team.insightDashboard.directorsLoanAccounts',
                meta: {
                    breadcrumbs: [
                        {
                            label: 'Directors loan accounts',
                            children: getChildBreadcrumbs('directorsLoanAccounts'),
                        },
                    ],
                },
            },
            {
                path: 'lock-date-activity',
                component: () =>
                    import(
                        '@/scenes/InsightsDashboard/LockDateActivity/LockDateActivityDetailedView/LockDateActivityDetailedViewScene.vue'
                    ),
                name: 'team.insightDashboard.lockDateActivity',
                meta: {
                    breadcrumbs: [
                        {
                            label: 'Lock dates',
                            children: getChildBreadcrumbs('lockDates'),
                        },
                    ],
                },
            },
            {
                path: 'flows-due',
                component: () =>
                    import('@/scenes/InsightsDashboard/FlowsDue/FlowsDueDetailedView/FlowsDueDetailedViewScene.vue'),
                name: 'team.insightDashboard.flowsDue',
                meta: {
                    breadcrumbs: [
                        {
                            label: 'Workflows due within 7 days',
                            children: getChildBreadcrumbs('flowsDue'),
                        },
                    ],
                },
            },
            xavier.featureEnabled(Feature.INSIGHTS_DASHBOARD_RECONCILIATION_ACTIVITY_ENABLED) && {
                path: 'reconciliation-activity',
                component: () =>
                    import(
                        '@/scenes/InsightsDashboard/ReconciliationActivity/ReconciliationActivityDetailedView/ReconciliationActivityDetailedViewScene.vue'
                    ),
                name: 'team.insightDashboard.reconciliationActivity',
                meta: {
                    breadcrumbs: [
                        {
                            label: 'Reconciliation activity',
                            children: getChildBreadcrumbs('reconciliationActivity'),
                        },
                    ],
                },
            },
            {
                path: 'total-transactions',
                component: () =>
                    import(
                        '@/scenes/InsightsDashboard/TotalTransactions/TotalTransactionsDetailedView/TotalTransactionsDetailedViewScene.vue'
                    ),
                name: 'team.insightDashboard.totalTransactions',
                meta: {
                    breadcrumbs: [
                        {
                            label: 'Total transactions',
                            children: getChildBreadcrumbs('totalTransactions'),
                        },
                    ],
                },
            },
        ].filter(Boolean),
    },
];

export { insightsDashboardRoutes };
