import axios from 'axios';
import moment from 'moment';
import $ from 'jquery';

window.Popper = require('popper.js').default;
window.__ = (key, replace) => {
    let translation = window.Spark.translations[key] ? window.Spark.translations[key] : key;

    _.forEach(replace, (value, key) => {
        translation = translation.replace(':' + key, value);
    });

    return translation;
};

/*
 * Define Moment locales
 */
moment.defineLocale('en-short', {
    parentLocale: 'en',
    relativeTime: {
        future: 'in %s',
        past: '%s',
        s: '1s',
        m: '1m',
        mm: '%dm',
        h: '1h',
        hh: '%dh',
        d: '1d',
        dd: '%dd',
        M: '1 month ago',
        MM: '%d months ago',
        y: '1y',
        yy: '%dy',
    },
});
moment.locale('en');

/*
 * Load jQuery and Bootstrap jQuery, used for front-end interaction.
 */
// if (window.$ === undefined || window.jQuery === undefined) {
//     window.$ = window.jQuery = require('jquery');
// }

require('bootstrap');

/**
 * Load Vue if this application is using Vue as its framework.
 */
if ($('#spark-app').length > 0) {
    require('vue-bootstrap');
}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */
axios.defaults.headers.common = {
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-TOKEN': window.Spark.csrfToken,
};

/**
 * Intercept the incoming responses.
 *
 * Handle any unexpected HTTP errors and pop up modals, etc.
 */
axios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error.response === undefined) {
            return Promise.reject(error);
        }

        switch (error.response.status) {
            case 401:
                window.axios.get('/logout');
                // $('#modal-session-expired').modal('show'); // XAVIER: We handle this in app
                break;

            case 402:
                window.location = '/settings#/subscription';
                break;
        }

        return Promise.reject(error);
    }
);

window.Vue.component('login-scene', require('@/scenes/Login').default);
window.Vue.component('analytics-provider', require('@/providers/AnalyticsProvider').AnalyticsProvider);
window.Vue.component('tracked-page', require('@/frames/TrackedPage').default);
