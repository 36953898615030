function toggleVisibility(element, isVisible) {
    element.style.visibility = isVisible ? 'visible' : 'hidden';
}

export default {
    bind(el, binding) {
        toggleVisibility(el, binding.value);
    },
    update(el, binding) {
        toggleVisibility(el, binding.value);
    },
};
