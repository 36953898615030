<template>
    <VInfoBanner>
        This feature is in BETA. If you have any issues or questions please
        <VButton name="Beta Banner Contact Support" text @click="showChat">give us a shout</VButton>! Thanks
    </VInfoBanner>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { useContactSupport } from '@/composables/useContactSupport';

export default defineComponent({
    setup() {
        const { showChat } = useContactSupport();

        return { showChat };
    },
});
</script>
