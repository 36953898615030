<template>
    <Interactive v-bind="attrs" :class="className" :data-qa="name" v-on="listeners" @click.native="onClick">
        <div class="sidebar-item__prepend-icon" v-if="hasSlot('icon-left')">
            <slot v-bind="slotProps" name="icon-left" />
        </div>

        <div class="sidebar-item__label">
            <slot v-bind="slotProps" />
        </div>

        <div class="sidebar-item__actions" v-if="hasSlot('actions')">
            <slot v-bind="slotProps" name="actions" />
        </div>
    </Interactive>
</template>

<script>
import { defineComponent } from 'vue';
import PropTypes from 'vue-types';
import kebabCase from 'lodash-es/kebabCase';
import noop from 'lodash-es/noop';
import omit from 'lodash-es/omit';

import PropValidationError from '@/errors/PropValidationError';
import SlotValidationError from '@/errors/SlotValidationError';
import hasSlotMixin from '@/mixins/hasSlot';
import uuidMixin from '@/mixins/uuid';

import Interactive from '@/components/Interactive';

function normaliseRoutePath(routeString) {
    return routeString
        .split('/')
        .filter((segment) => segment)
        .join('/');
}

export default defineComponent({
    name: 'SidebarItem',

    inject: {
        analyticsProvider: {},
        sidebarGroup: {
            default: {
                register: noop,
                unregister: noop,
            },
        },
    },

    model: {
        prop: 'visible',
        event: 'visibility-change',
    },

    props: {
        ...Interactive.props,
        active: PropTypes.bool.def(false),
        name: PropTypes.string.isRequired,
        noAction: PropTypes.bool.def(false),
        subItem: PropTypes.bool.def(false),
        visible: PropTypes.bool.def(false),
    },

    components: { Interactive },
    mixins: [hasSlotMixin, uuidMixin],

    data() {
        return {
            isActive: Boolean(this.active),
        };
    },

    computed: {
        attrs() {
            const attrs = {
                ...this.$attrs,
                ...omit(this.$props, ['active', 'name', 'noAction', 'subItem', 'visible']),
            };

            if (this.isRouterElement) {
                attrs['active-class'] = 'sidebar-item--active';
                attrs['exact-path-active-class'] = 'sidebar-item--active-exact';
            }

            return attrs;
        },

        className() {
            return {
                'sidebar-item': true,
                'sidebar-item--active': this.isActive,
                'sidebar-item--button': this.isButton,
                'sidebar-item--disabled': this.disabled,
                'sidebar-item--has-icon': Boolean(this.iconName),
                'sidebar-item--link': this.isAnchorElement || this.isRouterElement,
                'sidebar-item--no-action': this.noAction,
                'sidebar-item--sub-item': this.subItem,
            };
        },

        isAnchorElement() {
            return Boolean(this.href) ?? false;
        },

        isButtonElement() {
            return Boolean(this.$listeners.click) ?? false;
        },

        isRouterElement() {
            return Boolean(this.to) ?? false;
        },

        /**
         * Calculate if the sidebar item should be active based on the current
         * route. We call the Vue Router resolved method to attempt to find our
         * route, if it can't be found we get a 404 back, where the route name,
         * path or fullPath would not match.
         *
         * We only want to perform this check if it's a Vue Router element since
         * regular anchor tags, or buttons, can't have internal routes asigned
         * to them.
         *
         * @returns {Boolean}
         * @link https://router.vuejs.org/api/#router-resolve
         */
        isCurrentRoute() {
            if (!this.isRouterElement) {
                return false;
            }

            const resolved = this.$router.resolve(this.to);
            const isName = this.$route.name === resolved.route.name;
            const isMatchingPath = normaliseRoutePath(this.$route.path) === normaliseRoutePath(resolved.route.path);
            const isMatchingFullPath =
                normaliseRoutePath(this.$route.fullPath) === normaliseRoutePath(resolved.route.fullPath);

            return isName || isMatchingPath || isMatchingFullPath;
        },

        itemName() {
            return `menu-item-${kebabCase(this.name)}`;
        },

        listeners() {
            return omit(this.$listeners, ['click']);
        },

        slotProps() {
            return {
                isActive: this.isActive,
                isCurrentRoute: this.isCurrentRoute,
            };
        },
    },

    methods: {
        onClick(event) {
            if (!this.disabled) {
                switch (true) {
                    case this.isAnchorElement:
                        this.analyticsProvider.trackLinkClick(this.itemName, this.href, true);
                        break;

                    case this.isRouterElement:
                        this.analyticsProvider.trackLinkClick(this.itemName, this.to, false);
                        break;

                    case this.isButtonElement:
                        this.analyticsProvider.trackButtonClick(this.itemName);
                        break;
                }

                this.$emit('click', event);
            }
        },
    },

    watch: {
        active(value) {
            this.isActive = Boolean(value);
        },

        $route() {
            if (this.isRouterElement) {
                this.isActive = this.isCurrentRoute;
            }
        },
    },

    created() {
        const shouldValidateLinkLocation =
            !this.noAction && !this.isAnchorElement && !this.isButtonElement && !this.isRouterElement;

        if (shouldValidateLinkLocation) {
            throw new PropValidationError(
                'One of "href" or "to" props are required when the "SidebarItem" is not a "button".',
                false
            );
        }
    },

    mounted() {
        if (!this.hasSlot('default')) {
            throw new SlotValidationError(this.$options.name, 'default');
        }

        if (this.subItem) {
            this.sidebarGroup.register(this.uuid, this);
        }

        this.isActive = this.isCurrentRoute;
    },

    destroyed() {
        this.sidebarGroup.unregister(this.uuid);
    },
});
</script>

<style lang="scss" scoped>
@import 'style/dext/includes';

.sidebar-item {
    align-items: center;
    background-color: transparent;
    border: 0;
    color: get-color(silver, medium);
    cursor: default;
    display: flex;
    font-size: pxtoem(14);
    font-weight: $font-weight-regular;
    justify-content: flex-start;
    padding: 16px;
    text-decoration: none;
    transition: background-color 0.2s ease-in-out;
    width: 216px;
}

.sidebar-item:not(.sidebar-item--disabled, .sidebar-item--no-action) {
    @include focus-outline($color: get-color(blue), $opacity: 0.9, $inset: true);

    cursor: pointer;

    &:hover {
        background-color: get-color(gray);
        transition: background-color 0.15s $transition-primary;
    }
}

.sidebar-item--disabled {
    color: get-color(gray, lite);
    cursor: default;
    outline: 0 !important;
}

.sidebar-item--active {
    font-weight: $font-weight-bold;
}

.sidebar-item--sub-item {
    font-size: pxtoem(12);
    padding-left: 54px;
}

.sidebar-item__prepend-icon {
    color: get-color(gray, lite);
    display: inline-flex;
    font-size: 21px;
    margin-right: 16px;

    .sidebar-item--active & {
        color: get-color(green);
    }
}

.sidebar-item__actions {
    display: inline-flex;
    margin-left: auto;

    .dext-icon-container {
        font-size: 21px;
    }
}

.sidebar-item__label {
    align-items: center;
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    padding-right: 10px;
    text-align: left;
    width: 100%;
}

.sidebar-item--is-collapsed {
    .sidebar-item__label {
        opacity: 0;
        visibility: hidden;
    }
}
</style>
