<template>
    <Fragment>
        <Menu class="resources-menu" close-on-content-click name="Resources Menu">
            <template #activator="{ attrs, on }">
                <VButton v-bind="attrs" name="Resources" v-on="on">
                    <template #icon-left>
                        <VIcon class="top-nav-link-icon" decorative name="resources-thick" />
                    </template>
                    Resources
                </VButton>
            </template>

            <MenuItems :alignment="{ horizontal: 'right' }" size="medium">
                <MenuTitle>Learn the Basics</MenuTitle>

                <MenuSection horizontal>
                    <MenuItemCard
                        class="resources-menu__item"
                        href="https://help.dext.com/en/s/article/precision-training-support"
                        name="Getting Started"
                        new-tab
                    >
                        <template #image>
                            <img
                                alt="Calendar with mouse pointer over a highlighted day"
                                src="/img/resources/book-a-demo.svg"
                            />
                        </template>

                        Getting started webinar
                    </MenuItemCard>

                    <MenuItemCard
                        class="resources-menu__item"
                        href="https://www.youtube.com/playlist?list=PLsYCwMYVbHGJuBvUxF1O19TyNWSqf3aYz"
                        name="Training Videos"
                        new-tab
                    >
                        <template #image>
                            <img
                                alt="Rectangle with right-facing arrow indicating a video"
                                src="/img/resources/video.svg"
                            />
                        </template>

                        Training videos
                    </MenuItemCard>

                    <MenuItemCard
                        class="resources-menu__item"
                        href="https://help.dext.com/en/category/precision?_ga=2.179012836.1783372592.1629807680-51982230.1616404945"
                        name="How To"
                        new-tab
                    >
                        <template #image>
                            <img alt="Circle with question mark inside" src="/img/resources/help.svg" />
                        </template>

                        How-to articles
                    </MenuItemCard>
                </MenuSection>

                <MenuSection horizontal>
                    <MenuTitle>Connect with us</MenuTitle>

                    <MenuItemCard class="resources-menu__item" name="Contact Support" @click="showChat">
                        <template #image>
                            <img
                                alt="Headphones with boom microhone and chat bubble"
                                src="/img/resources/chat-with-support.svg"
                            />
                        </template>

                        Contact support
                    </MenuItemCard>

                    <MenuItemCard class="resources-menu__item" name="Suggest Feature" @click="showUserVoice">
                        <template #image>
                            <img
                                alt="Open box with an orange star coming out of opening"
                                src="/img/resources/suggest-feature.svg"
                            />
                        </template>

                        Suggest a feature
                    </MenuItemCard>

                    <MenuItemCard
                        class="resources-menu__item"
                        href="https://dext.com/eu/resources/product-updates?tag=Dext%20Precision"
                        name="Latest Releases"
                        new-tab
                    >
                        <template #image>
                            <img alt="A bullhorn megaphone" src="/img/resources/latest-release.svg" />
                        </template>

                        Latest Releases
                    </MenuItemCard>
                </MenuSection>
            </MenuItems>
        </Menu>

        <Modal v-model="userVoiceWidgetModalVisible" name="User Voice Widget">
            <ModalHeader>Suggest a Precision Feature</ModalHeader>

            <ModalContent>
                <div id="user-voice-target" />
            </ModalContent>

            <ModalFooter>
                <template #right>
                    <VButton color="secondary" name="Cancel User Voice" @click="userVoiceWidgetModalVisible = false">
                        Cancel
                    </VButton>
                </template>
            </ModalFooter>
        </Modal>
    </Fragment>
</template>

<script>
import { defineComponent } from 'vue';
import { Fragment } from 'vue-frag';

import { Menu, MenuItems, MenuSection, MenuItemCard, MenuTitle } from '@/components/Menu';
import Modal, { ModalHeader, ModalContent, ModalFooter } from '@/components/Modal';
import { useContactSupport } from '@/composables/useContactSupport';
import UserVoiceService from '@/services/UserVoice';

const userVoice = new UserVoiceService();

export default defineComponent({
    name: 'ResourcesMenu',

    components: {
        Fragment,
        Menu,
        MenuItemCard,
        MenuItems,
        MenuSection,
        MenuTitle,
        Modal,
        ModalHeader,
        ModalContent,
        ModalFooter,
    },

    setup() {
        const { showChat } = useContactSupport();

        return { showChat };
    },

    data() {
        return {
            userVoiceWidgetModalVisible: false,
            userVoiceVisible: false,
        };
    },

    methods: {
        /**
         * Display the UserVoice modal and embed the widget in the content.
         * We have to have a little bit of a timeout hack here as UV tries to
         * embed itself before the modal content has rendered otherwise.
         */
        showUserVoice() {
            this.userVoiceWidgetModalVisible = true;
            setTimeout(userVoice.embed);
        },
    },
});
</script>

<style lang="scss" scoped>
.resources-menu .resources-menu__item {
    max-width: 104px;
}
</style>
