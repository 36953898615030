<template>
<div class="col-lg ml-3 p-4 card dashboard-card bg-white">
    <div class="p-5">
    <img class="mb-5" src="/img/Training-video.svg" />
        <h5 class="mb-4">Learn about Dext Precision</h5>
        <p class="flow-desc mt-4 text-left">Watch a short video to find out how to get started with Precision.</p>
        <p class="flow-desc text-left">
            <strong>You'll discover how to:</strong>
            <ul>
                <li>Add your Xero and Quickbooks clients</li>
                <li>Use the Health Score to improve client data</li>
                <li>Use the Data Cleanup tools</li>
                <li>Explore the data insights tools</li>
                <li>Find more resources</li>
            </ul>
        </p>
    </div>
    <div>
        <VButton style="color: black" secondary class="video-link" @click="onClick">Watch the video</VButton>
    </div>
</div>
</template>

<script>

export default {
    methods: {
        onClick() {
            this.$root.$emit('show-video');
        },
    },
};
</script>

<style lang="scss" scoped>
.video-link {
    text-color: 'black';
}
</style>
