<template>
    <BaseNotification>
        <div slot="content">
            <strong class="notification-title">
                <span v-if="!completed">
                    <VIcon name="sync" spin />
                </span>
                {{ notificationHeader }}
            </strong>
            <div class="notification-content">
                <p>{{ completedSuggestions }} / {{ count }}</p>
            </div>
        </div>
    </BaseNotification>
</template>

<script>
import BaseNotification from './BaseNotification.vue';

export default {
    name: 'FlowCreation',
    components: {
        BaseNotification,
    },
    props: {
        count: { type: Number, required: true },
        notificationId: { type: Symbol, required: true },
        batchId: { type: String, required: true },
        type: { type: String, required: true },
    },
    data() {
        return {
            completedSuggestions: 0,
            completed: false,
        };
    },
    created() {
        window.Bus.$on('fixSuggestionProgress', ({ count, batchId }) => this.setProgress(count, batchId));
        window.Bus.$on('fixSuggestionCompleted', ({ batchId }) => this.setCompleted(batchId));
        window.Bus.$on('fixSuggestionFailed', ({ batchId, lastJob }) => this.setFailed(batchId, lastJob));
    },
    computed: {
        notificationHeader() {
            switch (this.type) {
                case 'contact-defaults':
                    return 'Setting your contact defaults...';
                case 'dormant-accounts':
                    return 'Archiving your dormant accounts...';
                default:
                    return 'Currently working on your tasks...';
            }
        },
    },
    methods: {
        setCompleted(batchId) {
            if (this.batchId === batchId) {
                this.completed = true;
                setTimeout(() => this.$emit('completed'), 4000);
            }
        },
        setProgress(count, batchId) {
            if (this.batchId === batchId) {
                this.completedSuggestions++;
            }
        },
        setFailed(batchId, lastJob) {
            if (this.batchId === batchId && lastJob) {
                this.$emit('completed');
            }
        },
    },
};
</script>

<style scoped>
.notification-title {
    font-weight: 700;
    font-size: 16px;
    /*color: #000002;*/
    margin: 0;
}

.notification-content {
    font-size: 14px;
    line-height: 21px;
    margin: 6px 0 0;
    color: #606266;
    text-align: justify;
}

.notification-content p {
    margin: 0;
}
</style>
