<template>
    <PrecisionAppProviders>
        <DAreaLoader v-if="displayLoading" />

        <Error404NotFoundScene v-else-if="display404" data-qa="precision-app-not-found" />
        <Error500InternalScene v-else-if="display500" data-qa="precision-app-failure" />

        <PageLayout v-else-if="displayApp">
            <RouterView />
        </PageLayout>
    </PrecisionAppProviders>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { RouterView } from 'vue-router';

import { DAreaLoader } from '@/components/DextUi';
import PageLayout from '@/layout/PageLayout';
import { Error404NotFoundScene, Error500InternalScene } from '@/scenes/Errors';

import PrecisionAppProviders from './PrecisionAppProviders.vue';

type Props = {
    hasErrors: boolean;
    validTeam: boolean;
    loading: boolean;
};

const props = defineProps<Props>();

const display404 = computed(() => !props.loading && !props.hasErrors && !props.validTeam);
const display500 = computed(() => !props.loading && props.hasErrors);
const displayApp = computed(() => !props.loading && !props.hasErrors);
const displayLoading = computed(() => props.loading && !props.hasErrors && !props.validTeam);
</script>

<script lang="ts">
export default { name: 'PrecisionApp' };
</script>
