import { Feature } from '@/enums/Feature';
import TeamLayout, { TeamSidebar } from '@/layout/TeamLayout';
import useIsFeatureEnabled from '@/store/modules/feature/hooks/useIsFeatureEnabled';

import type { RouteConfig } from 'vue-router';

import { clientsRoutes } from './clients';
import { customReportRoutes } from './customReport';
import { focusRoutes } from './focus';
import { insightsDashboardRoutes } from './insightsDashboard';

const hasProductTourEnabled = useIsFeatureEnabled(Feature.PRODUCT_TOUR);

/* eslint-disable sort-keys */
const teamRoutes: RouteConfig[] = [
    {
        path: '/team',
        meta: { sidebar: TeamSidebar },
        component: TeamLayout,
        children: [
            ...clientsRoutes,
            ...customReportRoutes,
            ...focusRoutes,
            ...insightsDashboardRoutes,
            {
                path: 'activity-stats',
                component: () => import('@/scenes/PracticeActivityStats/PracticeActivityStatsSceneContainer.vue'),
                name: 'team.activityStats',
            },
            {
                path: 'dashboard',
                component: () => import('@/scenes/PracticeDashboard/PracticeDashboardSceneContainer.vue'),
                name: 'team.dashboard',
            },
            {
                path: 'deadlines',
                component: () => import('@/scenes/DeadlinesDashboard/DeadlinesDashboardSceneContainer.vue'),
                name: 'team.deadlines',
            },
            {
                path: 'get-started',
                component: () =>
                    hasProductTourEnabled
                        ? import('@/scenes/ProductTour/ProductTourSceneContainer.vue')
                        : import('@/scenes/GetStarted/GetStartedScene.vue'),
                name: 'team.getStarted',
            },
            {
                path: 'integrations',
                component: () => import('@/scenes/Integrations/IntegrationsScene.vue'),
                name: 'team.integrations',
            },
            {
                path: 'focus-daily-digest',
                component: () => import('@/scenes/FocusDailyDigest/FocusDailyDigestScene.vue'),
                name: 'team.focusDailyDigest',
            },
            {
                path: 'api-settings',
                component: () => import('@/scenes/ApiSettings/ApiSettingsScene.vue'),
                name: 'team.apiSettings',
            },
            {
                path: 'lock-dates',
                component: () => import('@/scenes/LockDates/LockDatesScene.vue'),
                name: 'team.lockDates',
            },
            {
                path: 'members',
                component: () => import('@/scenes/TeamMembers/TeamMembersScene.vue'),
                name: 'team.members',
            },
            {
                path: 'mtd-dashboard',
                component: () => import('@/scenes/MtdDashboard/MtdDashboardSceneContainer.vue'),
                name: 'team.mtdDashboard',
            },

            {
                path: 'profile',
                component: () => import('@/scenes/TeamProfile/TeamProfileScene.vue'),
                name: 'team.profile',
            },
            {
                path: 'reports',
                component: () => import('@/scenes/ReportList/ReportListScene.vue'),
                props: true,
                name: 'team.reports',
            },
            {
                path: 'select-codes',
                component: () => import('@/scenes/SelectCodes/SelectCodesScene.vue'),
                name: 'team.selectCodes',
            },
            {
                path: 'subscription',
                component: () => import('@/scenes/TeamSubscription/TeamSubscriptionScene.vue'),
                name: 'team.subscription',
            },
            {
                path: 'tags',
                component: () => import('@/scenes/Tags/TagsScene.vue'),
                name: 'team.tags',
            },
            {
                path: 'unintegrated-import',
                component: () => import('@/scenes/UnintegratedImport/UnintegratedImportScene.vue'),
                name: 'team.unintegrated-import',
            },
            {
                path: 'unintegrated-import/staging',
                component: () => import('@/scenes/UnintegratedImport/ReviewAndImport.vue'),
                name: 'team.unintegrated-import.staging',
            },
            {
                path: 'custom-health-scores',
                component: () => import('@/scenes/CustomHealthScores/CustomHealthScoreSceneContainer.vue'),
                children: [
                    {
                        path: '',
                        component: () => import('@/scenes/CustomHealthScores/CustomHealthScoreScene.vue'),
                        name: 'team.customHealthScores.view',
                    },
                    {
                        path: 'edit',
                        component: () => import('@/scenes/CustomHealthScores/CustomHealthScoreEditScene.vue'),
                        name: 'team.customHealthScores.edit',
                    },
                    {
                        path: 'review',
                        component: () => import('@/scenes/CustomHealthScores/CustomHealthScoreReviewScene.vue'),
                        name: 'team.customHealthScores.review',
                    },
                ],
            },
        ],
    },
];

export { teamRoutes };
