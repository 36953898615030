class SlotValidationError extends Error {
    constructor(componentName, slotName = 'default', customMessage = false) {
        const baseMessage = '[Vue warn]: Invalid slot: ';
        const message = customMessage
            ? `${baseMessage}${componentName}`
            : `${baseMessage}"${slotName}" slot must be provided to "${componentName}"`;

        super(message);
    }
}

export default SlotValidationError;
