<template>
    <div @click.prevent.stop="toggle" :class="['checkbox', { 'checkbox--disabled': disabled }]" v-tooltip="text">
        <span v-if="text" class="pr-2">{{ text }}</span>
        <VIcon :name="icon" :spin="saving" :size="size" decorative />
    </div>
</template>

<script>
export default {
    props: {
        value: { type: Boolean, default: false },
        size: { type: String, default: '20px' },
        text: { type: String, default: null },
        disabled: { type: Boolean, default: false },
        saving: { type: Boolean, default: false },
    },
    computed: {
        icon() {
            return this.saving ? 'sync' : this.value ? 'success-checklist-filled' : 'circle';
        },
    },
    methods: {
        toggle() {
            if (this.disabled) {
                return;
            }

            this.$emit('input', !this.value);
        },
    },
};
</script>

<style lang="scss" scoped>
@import 'style/dext/includes';

.checkbox {
    border-radius: 100%;
    cursor: pointer;
    display: inline;
    vertical-align: middle;

    &--lg svg {
        color: get-color(blue, medium) !important;
        font-size: 26px;
    }

    &--disabled {
        cursor: default;
        opacity: 0.65;

        svg {
            background-color: #eee;
            border-radius: 100%;
        }
    }
}
</style>
