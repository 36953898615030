<template>
    <Sidebar>
        <template #prepend>
            <SidebarItem exact name="Back to Precision" :to="{ name: 'team.home' }">
                <template #icon-left>
                    <VIcon decorative name="chevron-left-thick" :vertical-align="false" />
                </template>
                Back to Precision
            </SidebarItem>
        </template>

        <SidebarItem :key="item.name" :name="item.name" :to="item.to" v-for="item in items">
            <template #icon-left v-if="item.icon">
                <VIcon decorative :name="item.icon" />
            </template>
            {{ item.label }}
        </SidebarItem>

        <template #append v-if="isNovaUser">
            <PrecisionAdminSidebarItems />
        </template>
    </Sidebar>
</template>

<script>
import useXavierGlobals from '@/hooks/useXavierGlobals';
import { PrecisionAdminSidebarItems } from '@/layout/PrecisionAdmin';
import { Sidebar, SidebarItem } from '@/layout/Sidebar';

export default {
    name: 'AdminSidebar',

    components: {
        PrecisionAdminSidebarItems,
        Sidebar,
        SidebarItem,
    },

    setup() {
        const { isNovaUser = false } = useXavierGlobals();

        return {
            isNovaUser,
            items: [
                {
                    name: 'Team Stats',
                    label: 'Team Stats',
                    icon: 'flash-thick',
                    to: { name: 'admin.teamStats' },
                },
                {
                    name: 'Advanced Features',
                    label: 'Advanced Features',
                    icon: 'flash-thick',
                    to: { name: 'admin.advancedFeatures' },
                },
                {
                    name: 'Admin Reports',
                    label: 'Admin Reports',
                    icon: 'practice-analysis-thick',
                    to: { name: 'admin.reports' },
                },
                {
                    name: 'Admin Advisors',
                    label: 'Advisors',
                    icon: 'building-sme-thick',
                    to: { name: 'admin.advisors' },
                },
                {
                    name: 'Admin AI Status',
                    label: 'AI Status',
                    icon: 'overview-globe-thick',
                    to: { name: 'admin.status' },
                },
                {
                    name: 'Admin PDF Preview',
                    label: 'PDF Preview',
                    icon: 'file-pdf-thick',
                    to: { name: 'admin.pdfPreview' },
                },
                {
                    name: 'Admin Imports',
                    label: 'PDF Imports',
                    icon: 'stack-of-documents-thick',
                    to: { name: 'admin.imports' },
                },
            ],
        };
    },
};
</script>

<style lang="scss" scoped></style>
