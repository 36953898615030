import type { RouteConfig } from 'vue-router';

import ClientLayout, { ClientSidebar } from '@/layout/ClientLayout';
import TeamLayout, { TeamSidebar } from '@/layout/TeamLayout';
import store from '@/store';
import clientStore, { clientInitialState } from '@/store/modules/client/client.store';
import type { ClientState } from '@/store/modules/client/types/Store';

import { extractClientIdFromDextRoute, extractClientSlugFromDextRoute } from '../../utils';

const dextTeamRoutes: Readonly<RouteConfig[]> = [
    {
        path: '/teams/:teamRbExternalId/clients/:clientRbExternalId',
        props: extractClientIdFromDextRoute,
        component: ClientLayout,
        meta: {
            sidebar: ClientSidebar,
            props: extractClientIdFromDextRoute,
            sidebarStacked: true,
        },
        beforeEnter(to, _from, next) {
            const clientSlug = extractClientSlugFromDextRoute(to);
            const moduleName = `client_${clientSlug}`;
            const moduleExists = Boolean(store.state.clients[`client_${clientSlug}`]);
            const { clientId } = extractClientIdFromDextRoute(to);

            if (!moduleExists) {
                store.registerModule(['clients', moduleName], {
                    ...clientStore,
                    state(): ClientState {
                        return {
                            ...clientInitialState,
                            data: { slug: clientSlug, id: clientId },
                        };
                    },
                });
            }

            next();
        },

        children: [
            {
                path: '',
                component: () => import('@/scenes/ClientOverview_LEGACY/ClientOverviewScene.vue'),
                props: extractClientIdFromDextRoute,
                name: 'dext.client.overview',
            },
            {
                path: 'settings/general',
                component: () => import('@/scenes/ClientSettings/ClientSettingsScene.vue'),
                props: extractClientIdFromDextRoute,
                name: 'dext.client.settings.general',
            },
            {
                path: 'settings/visibility',
                component: () => import('@/scenes/ClientVisibility/ClientVisibilityScene.vue'),
                props: extractClientIdFromDextRoute,
                name: 'dext.client.settings.visibility',
            },
        ],
    },

    {
        path: '/teams/:teamRbExternalId',
        meta: { sidebar: TeamSidebar },
        component: TeamLayout,
        children: [
            {
                path: 'client-setup',
                component: () => import('@/scenes/PlatformClientSetup/PlatformClientSetupScene.vue'),
                name: 'dext.platform.client.setup',
            },
        ],
    },
];

export { dextTeamRoutes };
