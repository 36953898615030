<template>
    <SidebarGroup v-if="!teamIsBase" name="Configuration">
        <template #activator>Configuration</template>

        <template #icon-left="{ isActive }">
            <VIcon decorative :name="isActive ? 'settings-filled' : 'settings-thick'" />
        </template>

        <SidebarItem name="Lock Dates" sub-item :to="{ name: 'team.lockDates' }"> Lock Dates</SidebarItem>

        <SidebarItem name="Tags" sub-item :to="{ name: 'team.tags' }"> Tags</SidebarItem>

        <SidebarItem v-if="canManageTeamIntegrations" name="Integrations" sub-item :to="{ name: 'team.integrations' }">
            Integrations
        </SidebarItem>

        <SidebarItem
            v-if="newNotificationsEnabled && teamUrlEnabled"
            name="Notification settings"
            sub-item
            :to="{ name: 'team.notificationSettings' }"
        >
            Notification Settings
        </SidebarItem>

        <SidebarItem
            v-if="!newNotificationsEnabled"
            name="Daily Digest"
            sub-item
            :to="{ name: 'team.focusDailyDigest' }"
        >
            Workflows Daily Digest
        </SidebarItem>

        <SidebarItem name="Api" sub-item :to="{ name: 'team.apiSettings' }"> API</SidebarItem>

        <SidebarItem
            v-if="canAccessBulkSettings && bulkClientConfigureEnabled"
            name="Client Configuration"
            sub-item
            :to="{ name: 'team.clients.bulkSettings' }"
        >
            Client Configuration
        </SidebarItem>

        <SidebarItem
            v-if="isCustomHealthScoresEnabled && isPermittedToViewCustomHealthScores"
            name="Health Score Settings"
            sub-item
            :to="{ name: 'team.customHealthScores.view' }"
        >
            Health Score Settings
        </SidebarItem>
    </SidebarGroup>
</template>

<script>
import { Feature } from '@/enums/Feature';
import UserPermissions from '@/enums/UserPermissions';
import useXavierGlobals from '@/hooks/useXavierGlobals';
import { SidebarGroup, SidebarItem } from '@/layout/Sidebar';
import useIsFeatureEnabled from '@/store/modules/feature/hooks/useIsFeatureEnabled';
import { hasPermission } from '@/utils/permissions';

export default {
    name: 'TeamSidebarGroupFocus',

    components: { SidebarGroup, SidebarItem },

    setup() {
        const bulkClientConfigureEnabled = useIsFeatureEnabled(Feature.BULK_CLIENT_CONFIGURE);
        const canAccessBulkSettings = hasPermission(UserPermissions.ACCESS_CLIENT_BULK_SETTINGS);
        const canManageTeamIntegrations = hasPermission(UserPermissions.MANAGE_TEAM_INTEGRATIONS);
        const isCustomHealthScoresEnabled = useIsFeatureEnabled(Feature.CUSTOM_HEALTH_SCORES);
        const newNotificationsEnabled = useIsFeatureEnabled(Feature.NEW_NOTIFICATIONS);
        const teamUrlEnabled = useIsFeatureEnabled(Feature.TEAM_URL);

        const { teamIsBase, currentUser } = useXavierGlobals();
        const isPermittedToViewCustomHealthScores = currentUser.teamOwner || currentUser.role === 'admin';

        return {
            bulkClientConfigureEnabled,
            canAccessBulkSettings,
            canManageTeamIntegrations,
            teamIsBase,
            isCustomHealthScoresEnabled,
            isPermittedToViewCustomHealthScores,
            newNotificationsEnabled,
            teamUrlEnabled,
        };
    },
};
</script>
