<template>
    <div class="import-client-card col-lg mr-3 p-4 card dashboard-card bg-white">
        <div class="p-5">
            <img class="mb-5" src="/img/Add-client.svg" />
            <h5 class="mb-4">Add client data</h5>
            <p class="flow-desc mt-4 text-left">
                Start using Dext Precision by importing your client's accounting data from Xero or Quickbooks.
            </p>
            <p class="flow-desc text-left">This can take up to 30 minutes, depending on how much data a client has.</p>
            <p class="flow-desc text-left"><strong>How to add client data</strong>
                <ul>
                    <li>Connect to your clients accounting software</li>
                    <li>Select the clients you'd like to add</li>
                    <li>Follow the steps on your screen</li>
                </ul>
            </p>
            <div>
                <CreateClientButton class="create-client-button pt-2" secondary>Import your first client</CreateClientButton>
            </div>
            <p class="flow-desc text-left pt-5"><small>We only access information you give us permission to. Plus, you can disconnect Dext Precision at any time. More about our <a href="https://dext.com/uk/security">data security policies</a></small></p>
        </div>
    </div>
</template>

<script>
import CreateClientButton from '@/components/ClientList/CreateClientButton.vue';

export default {
    components: {
        CreateClientButton,
    },
};
</script>

<style lang="scss" scoped>
.create-client-button {
    justify-content: center;
}

.import-client-card {
    max-width: 600px;
}
</style>
