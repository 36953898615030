import type { Client } from '@/store/modules/client/types/Client';

type Params = {
    feature?: string;
    subFeature?: string | null;
    url?: string;
};

/**
 * Grab all URL segments after the base URL.
 *
 * @returns Array of URL segments.
 */
function getUrlSegments() {
    const url = window.location.pathname;
    const urlSegments = url.split('/');

    return urlSegments.filter(Boolean);
}

/**
 * Get the main feature (secton of the app) from the URL.
 *
 * @param params
 * @param urlSegments
 * @returns
 */
function getFeature(params: Params = {}, urlSegments: string[] = []) {
    if (params.feature) return params.feature;
    if (urlSegments.length < 2) return 'Unknown';

    return urlSegments[2];
}

/**
 * Get the sub feature (page of the app) from the URL.
 * @param params
 * @param urlSegments
 * @returns
 */
function getSubFeature(params: Params = {}, urlSegments: string[] = []) {
    if (params.subFeature) return params.subFeature;
    if (urlSegments.length < 3) return '';
    if (urlSegments[2] === 'flow') return '';

    return urlSegments[3];
}

/**
 * Generate the analytics URL for the provider link.
 *
 * @param client
 * @param teamCrn
 * @param params
 * @returns
 */
export function useProviderUrl(client: Client | null, teamCrn: string, params?: Params): string {
    const basePath = `teams/${teamCrn}/outgoing-link/general-ledger`;

    const redirectUrl = params?.url || client?.providerLink || '';
    const url = new URL(basePath, window.Xavier.baseUrl);

    if (!client) return '';

    const routeSegments = getUrlSegments();
    const feature = getFeature(params, routeSegments);
    const subFeature = getSubFeature(params, routeSegments);

    url.searchParams.set('feature', feature);
    url.searchParams.set('sub_feature', subFeature);
    url.searchParams.set('url', redirectUrl);

    return url.toString();
}
