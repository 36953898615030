<template>
    <Fragment>
        <VButton
            alt
            block
            class="flow-action"
            color="primary"
            :disabled="currentFlow.secondReviewerId !== userId"
            name="Complete Second Review"
            @click="$emit('finish')"
        >
            {{ currentFlow.secondReviewerId === userId ? 'Complete review 2' : 'Under review' }}
        </VButton>

        <VButton
            block
            class="flow-action"
            color="secondary"
            name="Second Review Hand Back"
            @click="$emit('hand-back')"
            v-if="currentFlow.secondReviewerId === userId"
        >
            Hand back
        </VButton>
    </Fragment>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Fragment } from 'vue-frag';
import * as PropTypes from 'vue-types';

export default defineComponent({
    name: 'FlowSidebarActionsSecondReview',
    components: { Fragment },
    emits: ['finish', 'hand-back'],

    props: {
        currentFlow: PropTypes.object().isRequired,
        userId: PropTypes.number().isRequired,
    },
});
</script>

<style lang="scss" scoped src="./flow-actions.scss"></style>
