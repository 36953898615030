<template>
    <VSpinner v-if="loading" />

    <RouterView :key="$route.path" v-else />
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'TeamLayout',
    data() {
        return {
            loading: true,
        };
    },
    computed: {
        ...mapGetters({
            clientList: 'legacyClients/clientList',
            clientListLoading: 'legacyClients/loading',
            clientListLoaded: 'legacyClients/clientListLoaded',
        }),
    },
    async created() {
        if (!this.clientListLoading && !this.clientListLoaded) {
            await this.loadClientList();
        }

        this.loading = false;
    },
    methods: {
        ...mapActions({
            loadClientList: 'legacyClients/loadClientList',
        }),
    },
};
</script>
