module.exports = {
    props: ['team', 'invitations'],

    /**
     * Xavier: variable to show resend state
     *
     * The component's data.
     */
    data() {
        return {
            sentInvitations: [],
        };
    },


    methods: {
        /**
         * Cancel the sent invitation.
         */
        cancel(invitation) {
            axios.delete(`/settings/invitations/${invitation.team_id}/${invitation.id}`)
                .then(() => {
                    this.$parent.$emit('updateInvitations');
                });
        },

        /**
         * Xavier: new "resend" function
         *
         * Send a team invitation.
         */
        resend(invitation) {
            let inv = this.invitations.find(inv => inv.id === invitation.id)
            Vue.set(inv, 'resending', true);

            axios.delete(`/settings/invitations/${invitation.team_id}/${invitation.id}`)
                .then(() => {
                    axios.post(`/settings/${Spark.teamsPrefix}/${this.team.id}/invitations`, {
                        email: invitation.email,
                        role: invitation.role,
                    }).then(() => {
                        this.$parent.$emit('updateInvitations');
                        this.sentInvitations.push(invitation.email);
                        Vue.set(inv, 'resending', false);
                    });
                });


        },
    }
};
