<template>
    <div v-if="client && !loading">
        <h4 v-if="client.payrollSupported">Accounting Visibility</h4>

        <p>
            Decide who on your team can see {{ client.name }} in Dext Precision.
            <span v-if="client.provider === 'xero'">
                The default when a client is added to Dext Precision is to use "Xero Users Only", which matches user
                email addresses in Dext Precision with those in Xero.
            </span>
        </p>

        <form>
            <div class="form-group">
                <label v-if="!client.payrollSupported">Visibility</label>

                <VRadio v-model="client.visibility" border :options="visibilityOptions" />

                <div v-if="client.visibility !== 'public'" class="mt-2">
                    <VInfoBanner v-if="client.visibility === 'private'">
                        Dext Precision only considers Xero users with <b>Adviser</b> or <b>Standard</b> roles.
                    </VInfoBanner>

                    <p class="mt-3 mb-0">The following team members have access to {{ client.name }}:</p>

                    <div class="card-row w-70">
                        <div class="card-column">
                            <div v-for="user in activeUsers" :key="user.id" class="card card-hover dashboard-card">
                                <UserListItem
                                    :always-has-access="doesAlwaysHaveAccess(user)"
                                    :avatar="user.avatar"
                                    :can-delete="user.id !== $xavier.userId && client.visibility !== 'private'"
                                    :email="user.email"
                                    :name="user.name"
                                    segment-name="Client Visbility Team Member Card"
                                    @removeUser="removeUser(user.id)"
                                />
                            </div>
                        </div>
                    </div>

                    <p v-if="inactiveTeamMembers.length > 0">
                        {{ inactiveTeamMembers.length }} Dext Precision team
                        {{ $plural('member', inactiveTeamMembers.length) }}
                        {{ inactiveTeamMembers.length !== 1 ? 'do' : 'does' }} not have access.
                        <a href="" @click.prevent="toggleInactiveTeamMembers">{{
                            showInactiveTeamMembers ? 'Hide' : 'Show'
                        }}</a>
                    </p>

                    <div v-if="inactiveTeamMembers.length > 0 && showInactiveTeamMembers">
                        <p class="mb-0">
                            <strong>Team Members without access:</strong>
                        </p>

                        <div class="card-row w-70">
                            <div class="card-column">
                                <div class="card dashboard-card">
                                    <UserListItem
                                        v-for="user in inactiveTeamMembers"
                                        :key="user.id"
                                        :active="false"
                                        :avatar="user.avatar"
                                        :can-add="client.visibility === 'custom'"
                                        :email="user.email"
                                        :name="user.name"
                                        :user-id="user.id"
                                        @addUser="addUser(user.id)"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <p v-if="inactiveXeroUsers.length > 0">
                        {{ inactiveXeroUsers.length }} Xero Users are not part of your Dext Precision Team.
                        <a href="" @click.prevent="toggleInactiveXeroUsers">{{
                            showInactiveXeroUsers ? 'Hide' : 'Show'
                        }}</a>
                    </p>

                    <div v-if="inactiveXeroUsers.length > 0 && showInactiveXeroUsers">
                        <p class="mb-0">
                            <strong>Inactive Xero Users:</strong>
                        </p>

                        <div class="card-row w-70">
                            <div class="card-column">
                                <div class="card dashboard-card">
                                    <UserListItem
                                        v-for="user in inactiveXeroUsers"
                                        :key="user.providerId"
                                        :active="false"
                                        :can-invite="$xavier.hasPermission('canInviteNewMembers')"
                                        :email="user.email"
                                        :invite-pending="user.pending"
                                        :name="`${user.firstName} ${user.lastName}`"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-else class="mt-2">
                    <p>Everyone in your Dext Precision Team has access to {{ client.name }}.</p>
                </div>
            </div>

            <div>
                <VButton
                    class="mr-1"
                    color="primary"
                    :disabled="saving"
                    name="Save Client Visibility"
                    @click.prevent="save"
                >
                    Save
                </VButton>
            </div>
        </form>
    </div>

    <div v-else class="pt-5">
        <VSpinner />
    </div>
</template>

<script>
import axios from 'axios';
import * as PropTypes from 'vue-types';
import { mapGetters, mapActions, mapMutations } from 'vuex';

import * as types from '@/store/modules/clients_LEGACY/types';

import UserListItem from './UserListItem.vue';

export default {
    name: 'ClientVisibilityForm',

    inject: ['analyticsProvider'],

    components: { UserListItem },

    props: {
        clientId: PropTypes.string().isRequired,
    },

    data() {
        return {
            client: null,
            loading: true,
            saving: false,
            selectedUser: null,
            showInactiveXeroUsers: false,
            showInactiveTeamMembers: false,
        };
    },

    async created() {
        if (this.$xavier.hasPermission('canInviteNewMembers')) {
            await Promise.all([
                this.getClient(),
                this.loadClientUserIds(this.clientId),
                this.loadClientProviderUsers(this.clientId),
                this.loadUserList(),
                this.getInvitations(this.currentTeam.id),
            ]);
        } else {
            await Promise.all([
                this.getClient(),
                this.loadClientUserIds(this.clientId),
                this.loadClientProviderUsers(this.clientId),
                this.loadUserList(),
            ]);
        }

        this.loading = false;

        this.$emit('loaded');
    },

    computed: {
        ...mapGetters({
            clientUserIds: 'legacyClients/currentClientUserIds',
            clientProviderUsers: 'legacyClients/currentClientProviderUsers',
            currentTeam: 'teams/currentTeam',
            pendingInvites: 'invitations/invitations',
            users: 'users/userList',
        }),

        activeUsers() {
            if (!this.client || this.client.visibility === 'public') {
                return this.users;
            }

            if (this.client.visibility === 'private') {
                return this.possibleActiveXeroUsers;
            } else {
                if (!this.clientUserIds) return this.users;

                // custom
                return this.users.filter((user) => {
                    return this.clientUserIds.find((clientUserId) => clientUserId === user.id);
                });
            }
        },

        possibleActiveXeroUsers() {
            const isXeroUser = (user) => {
                return this.clientProviderUsers?.some(
                    (providerUser) => providerUser.email.toLowerCase() === user.email.toLowerCase()
                );
            };

            return this.users.filter((u) => {
                // If the Precision User exists as a Xero user, then they are
                // able to view this client.
                if (isXeroUser(u)) {
                    return true;
                }

                // Team owners are always allowed to view their team's clients.
                const permittedRoles = ['owner'];

                // Team admins can only view all clients if it's not an enterprise team.
                if (!this.$xavier.isEnterprise) {
                    permittedRoles.push('admin');
                }

                return permittedRoles.includes(u.role);
            });
        },

        inactiveTeamMembers() {
            if (this.client.visibility === 'private') {
                return this.users.filter((user) => !this.activeUsers.find((activeUser) => activeUser.id === user.id));
            } else {
                // custom
                return this.users.filter(
                    (user) => !this.clientUserIds.find((clientUserId) => clientUserId === user.id)
                );
            }
        },

        inactiveXeroUsers() {
            return this.clientProviderUsers
                .filter((providerUser) => {
                    return !this.users.some((user) => user.email.toLowerCase() === providerUser.email.toLowerCase());
                })
                .map((providerUser) => {
                    providerUser.pending = Boolean(
                        this.pendingInvites.find(
                            (pendingInvite) => pendingInvite.email.toLowerCase() === providerUser.email.toLowerCase()
                        )
                    );

                    return providerUser;
                });
        },

        visibilityOptions() {
            const options = [
                {
                    value: 'public',
                    icon: 'team-filled',
                    text: 'Whole Team',
                },
                {
                    value: 'custom',
                    icon: 'private-filled',
                    text: 'Specific Users',
                },
            ];

            if (this.client.provider === 'xero') {
                options.push({
                    value: 'private',
                    icon: 'private-filled',
                    text: 'Xero Users Only',
                    disabled: this.possibleActiveXeroUsers.length < 1,
                    disabledTooltip:
                        'No team member emails match the Xero Users for this client. Check the client has synced recently.',
                });
            }

            return options;
        },
    },

    watch: {
        async activeUsers() {
            if (this.client && this.activeUsers.length) {
                if (!this.activeUsers.find((user) => user.id === this.client.userId)) {
                    this.client.userId = this.activeUsers[0] ? this.activeUsers[0].id : null;
                }

                if (!this.activeUsers.find((user) => user.id === this.client.secondUserId)) {
                    this.client.secondUserId = null;
                }
            }

            if (!this.activeUsers.length && this.client.visibility === 'custom') {
                await this.addUser(this.$xavier.userId);
            }
        },
    },

    methods: {
        ...mapActions('legacyClients', ['loadClientUserIds', 'loadClientProviderUsers']),
        ...mapActions('invitations', ['getInvitations']),
        ...mapActions('users', ['loadUserList']),
        ...mapMutations({ setClientUserIds: 'legacyClients/' + types.CLIENT_USER_LIST_LOADED }),

        async getClient() {
            const practiceCrn = this.currentTeam.rbExternalId;
            const { data } = await axios.get(`/api/teams/${practiceCrn}/client/${this.clientId}`);

            this.client = data.data;
        },

        async save() {
            this.saving = true;

            const client = {
                name: this.client.name,
                userId: this.client.userId,
                secondUserId: this.client.secondUserId,
                visibility: this.client.visibility,
                clientUserIds: this.clientUserIds,
            };
            const practiceCrn = this.currentTeam.rbExternalId;

            await axios
                .post(`/api/teams/${practiceCrn}/client/${this.clientId}`, client)
                .finally(() => (this.saving = false));

            this.$toaster.success('Client visibility updated');
        },

        async addUser(id) {
            const userIds = this.clientUserIds;

            userIds.push(id);
            this.setClientUserIds({ clientId: this.clientId, userIds });
        },

        async removeUser(id) {
            const userIds = this.clientUserIds.filter((_) => _ !== id);

            this.setClientUserIds({ clientId: this.clientId, userIds });
        },

        doesAlwaysHaveAccess(user) {
            if (this.$xavier.isEnterprise) {
                return user.teamOwner;
            }

            return user.teamOwner || user.role === 'admin';
        },

        toggleInactiveXeroUsers() {
            const segmentName = this.showInactiveXeroUsers ? 'Hide' : 'Show';

            this.showInactiveXeroUsers = !this.showInactiveXeroUsers;
            this.analyticsProvider.trackElementClick(`${segmentName} Inactive Xero Members`, 'Link');
        },

        toggleInactiveTeamMembers() {
            const segmentName = this.showInactiveTeamMembers ? 'Hide' : 'Show';

            this.showInactiveTeamMembers = !this.showInactiveTeamMembers;
            this.analyticsProvider.trackElementClick(`${segmentName} Inactive Team Members`, 'Link');
        },
    },
};
</script>

<style lang="scss" scoped>
label {
    font-weight: bold;
}

.card-row {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    width: 100%;

    .card-column {
        display: flex;
        flex-direction: column;
        width: 100%;

        .card {
            background-color: white;
            margin: 15px 0;
        }
    }
}

.dashboard-card {
    padding: 0.3rem 0.5rem;
}
</style>
