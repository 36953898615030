class UserVoice {
    constructor() {
        if (UserVoice._instance) {
            return UserVoice._instance;
        }

        this.forumIds = {};
        this.jwt = null;

        UserVoice._instance = this;
    }

    static getInstance() {
        return this._instance;
    }

    /**
     * Set the forum IDs that the user has access to. Note that if none
     * provided, then the widget isn't going to work.
     *
     * @param {Object} [forumIds={}] Forum IDs that the user has access to
     * @param {String} [forumIds.precision]
     */
    setForumIds(forumIds = {}) {
        this.forumIds = forumIds;
    }

    /**
     * Set the JWT generated from the server.
     *
     * @param {String} token
     */
    setJwt(token) {
        this.jwt = token;
    }

    /**
     * Embed a static instance of the UserVoice widget at the target element.
     */
    embed() {
        const uv = window.UserVoice || [];

        uv.push(['embed', '#user-voice-target', { mode: 'smartvote' }]);
    }

    /**
     * Setup the UserVoice widget
     *
     * @throws Error If no JSON Web Token created
     */
    setup() {
        if (!this.jwt) {
            throw new Error('No JSON Web Token set, did you call `UserVoice.setJwt`?');
        }

        if (!this.forumIds.precision) {
            throw new Error(
                'No Precision forum ID set, did you call `UserVoice.setForumIds` with a `precision` value?'
            );
        }

        const uv = window.UserVoice || [];

        uv.push(['set', { sso: this.jwt }]);
        uv.push([
            'set',
            {
                smartvote_enabled: true,
                post_suggestion_enabled: true,
                accent_color: '#196acc',
                target: false,
                position: 'top-right',
                height: '340px',
                width: '450px',
                locale: 'en',
                forum_id: this.forumIds.precision,
                strings: {
                    post_suggestion_title: '',
                    post_suggestion_body:
                        'How would you improve our product? See all ideas already submitted %{link:here}',
                },
            },
        ]);
    }

    /**
     * Identify the user when they log in
     * @param {Object} data
     * @param {String} data.email
     * @param {String} data.name
     * @param {String} data.created_at
     * @param {String} data.id
     * @param {Object} data.account
     * @param {String} data.account.id
     * @param {String} data.account.name
     * @param {String?} [data.account.plan]
     */
    setUserDetails(data) {
        const uv = window.UserVoice || [];

        uv.push(['identify', data]);
    }
}

export default UserVoice;
