<template>
    <div class="flow-meta">
        <div class="flow-meta__item flow-meta__item--flow-name">
            {{ currentFlow.name }}
        </div>

        <div class="flow-meta__item flow-meta__item--description">
            {{ currentFlow.description }}
        </div>

        <div class="flow-meta__item flow-meta__item--client">
            <div class="flow-meta__item-title">Client</div>
            <div class="flow-meta__item-value fs-mask">
                {{ currentFlow.clientName }}
            </div>
        </div>

        <div class="flow-meta__item flow-meta__item--assignee">
            <div class="flow-meta__item-title">Assigned to</div>
            <div class="flow-meta__item-value fs-mask">
                {{ currentFlow.userName }}
                <StatusIcon class="status-icon" :completed="currentFlow.completed" v-if="currentFlow.started" />
            </div>
        </div>

        <div class="flow-meta__item flow-meta__item--reviewer" v-if="currentFlow.reviewerId">
            <div class="flow-meta__item-title">
                {{ currentFlow.secondReviewerId ? 'Reviewer 1: ' : 'Reviewer: ' }}
            </div>
            <div class="flow-meta__item-value fs-mask">
                {{ currentFlow.reviewerName }}
                <StatusIcon class="status-icon" :completed="currentFlow.reviewed" v-if="currentFlow.completed" />
            </div>
        </div>

        <div class="flow-meta__item flow-meta__item--reviewer" v-if="currentFlow.secondReviewerId">
            <div class="flow-meta__item-title">Reviewer 2</div>
            <div class="flow-meta__item-value fs-mask">
                {{ currentFlow.secondReviewerName }}
                <StatusIcon class="status-icon" :completed="currentFlow.secondReview" v-if="currentFlow.reviewed" />
            </div>
        </div>

        <div class="flow-meta__item flow-meta__item--start-date">
            <div class="flow-meta__item-title">Date started</div>
            <div class="flow-meta__item-value">{{ dateStarted }}</div>
        </div>

        <div class="flow-meta__item flow-meta__item--due-date">
            <div class="flow-meta__item-title">Date due</div>
            <div class="flow-meta__item-value">{{ dateDue }}</div>
        </div>

        <div class="flow-meta__item flow-meta__item--completed-date" v-if="dateCompleted">
            <div class="flow-meta__item-title">Date completed</div>
            <div class="flow-meta__item-value">{{ dateCompleted }}</div>
        </div>
    </div>
</template>

<script>
import { defineComponent, computed } from 'vue';
import PropTypes from 'vue-types';

import { format as formatDate } from '@/utils/date.js';
import StatusIcon from './StatusIcon.vue';

export default defineComponent({
    name: 'FocusSidebarFlowMeta',

    props: {
        currentFlow: PropTypes.object.isRequired,
    },

    components: {
        StatusIcon,
    },

    setup(props) {
        return {
            dateStarted: computed(() => {
                return props.currentFlow?.started ? formatDate(props.currentFlow.started, 'll') : '-';
            }),
            dateCompleted: computed(() => {
                return props.currentFlow?.completed ? formatDate(props.currentFlow.completed, 'll') : '';
            }),
            dateDue: computed(() => {
                return props.currentFlow?.due ? formatDate(props.currentFlow.due, 'll') : '-';
            }),
        };
    },
});
</script>

<style lang="scss" scoped>
@import 'style/dext/includes';

.flow-meta {
    background-color: rgba(0, 0, 2, 31.4%);
    color: get-color(gray, medium);
    padding: 16px;
}

.flow-meta__item {
    & + & {
        margin-top: 8px;
    }
}

.flow-meta__item--flow-name {
    color: $color-white;
    font-size: pxtoem(16);
}

.flow-meta__item--description {
    color: get-color(gray, lite);
    font-size: pxtoem(13);
    margin-bottom: 16px;
}

.flow-meta__item-title {
    font-size: pxtoem(12);
    letter-spacing: 0.02em;
    text-transform: uppercase;
}

.flow-meta__item-value {
    align-items: center;
    color: get-color(gray, lite);
    display: flex;
    min-height: 21px;
    width: 100%;
}

.status-icon {
    margin-left: auto;
}
</style>
