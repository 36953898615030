import { defineComponent } from 'vue';
import moment from 'moment';
import padStart from 'lodash-es/padStart';
import PropTypes from 'vue-types';

import * as env from '@/config/env';
import uuidMixin from '@/mixins/uuid';
import { DEV_loadWorker } from '@/utils/loadWorker';
import IntervalWorker from '@/workers/interval.worker?worker';

const intervalWorker = env.IS_DEV ? DEV_loadWorker('interval.worker.ts') : new IntervalWorker();

export default defineComponent({
    name: 'CountdownTimer',

    model: {
        prop: 'paused',
        event: 'paused-change',
    },

    mixins: [uuidMixin],

    props: {
        /**
         * Used for v-model binding, or for manual pause control.
         *
         * @prop {Boolean} [paused=false]
         */
        paused: PropTypes.bool.def(false),

        /**
         * How many seconds should the countdown run for.
         *
         * @prop {Number|String} [seconds=30]
         */
        seconds: PropTypes.oneOfType([Number, String]).def(30),

        /**
         * Offload the timer to a Web Worker. This will give more consistent
         * countdown results if the timer is due to run in the background at
         * any point.
         *
         * @prop {Boolean} [useWorker=false]
         */
        useWorker: PropTypes.bool.def(false),
    },

    data() {
        return {
            count: parseInt(this.seconds, 10),
            end: null,
            intervalId: null,
            intervalMs: 1000,
            isPaused: Boolean(this.paused),
            start: null,
        };
    },

    watch: {
        paused(value) {
            this.isPaused = Boolean(value);
        },

        isPaused(value) {
            Boolean(value) !== this.paused && this.$emit('paused-change', value);
        },

        seconds() {
            this.resetTimer();
            this.startTimer();
        },
    },

    methods: {
        /**
         * Convert the seconds of countdown into a time duration.
         */
        createDuration() {
            const now = moment();

            this.start = now.unix();
            this.end = now.clone().add(this.count, 'seconds').unix();
            this.duration = moment.duration((this.end - this.start) * 1000, 'milliseconds');
        },

        intervalCallback() {
            if (this.count > 0) {
                this.duration = moment.duration(this.duration - this.intervalMs, 'milliseconds');
            } else {
                this.$emit('ended');
                this.pauseTimer();
            }

            this.count -= 1;
        },

        /**
         * Pause the current timer.
         *
         * NOTE: This doesn't actually pause the timer, that's not something
         * an interval can do. It clears the interval and when `startTimer` is
         * called again it resumes from that count.
         */
        pauseTimer() {
            if (this.useWorker) {
                intervalWorker.postMessage({ command: 'stop' });
            } else {
                clearInterval(this.intervalId);
            }

            this.intervalId = null;
            this.isPaused = true;
        },

        /**
         * Start the countdown timer.
         */
        startTimer() {
            if (this.intervalId) {
                return;
            }

            this.isPaused = false;

            if (this.useWorker) {
                intervalWorker.postMessage({ command: 'start', payload: { timeout: this.intervalMs } });
                this.intervalId = this.uuid;
            } else {
                this.intervalId = setInterval(this.intervalCallback, this.intervalMs);
            }
        },

        /**
         * Reset the timer to initial.
         */
        resetTimer() {
            this.count = parseInt(this.seconds, 10);
            this.createDuration();
        },
    },

    created() {
        this.createDuration();

        if (this.useWorker) {
            intervalWorker.addEventListener('message', this.intervalCallback);
        }
    },

    destroyed() {
        if (this.useWorker) {
            intervalWorker.terminate();
        } else {
            clearInterval(this.intervalId);
        }
    },

    mounted() {
        this.resetTimer();
        this.startTimer();
    },

    render() {
        return this.$scopedSlots.default({
            pause: this.pauseTimer,
            paused: this.isPaused,
            reset: this.resetTimer,
            secondsCount: this.count,
            start: this.startTimer,
            hours: padStart(this.duration?.hours(), 2, '0'),
            minutes: padStart(this.duration?.minutes(), 2, '0'),
            seconds: padStart(this.duration?.seconds(), 2, '0'),
        });
    },
});
