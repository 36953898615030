import { inject } from 'vue';
import type { Route } from 'vue-router';

export const PreviousRouteContext = Symbol('PreviousRouteContext');
export const RouteContext = Symbol('RouteContext');

export function usePreviousRoute() {
    const previousRouteContext = inject(PreviousRouteContext, null);

    return previousRouteContext;
}

export function useRoute() {
    const routeContext = inject<Route | null>(RouteContext, null);

    return routeContext;
}
