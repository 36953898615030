module.exports = {
    props: ['user', 'team'],

    /**
     * The component's data.
     */
    data() {
        return {
            plans: []
        };
    },


    /**
     * Prepare the component.
     */
    mounted() {

    },


    methods: {

    }
};
