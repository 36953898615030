<template>
    <VIcon
        :class="className"
        decorative
        :name="completed ? 'checklist-thick' : 'time-watch-thick'"
        v-tooltip="tooltip ? tooltip : null"
    />
</template>

<script>
import { computed } from 'vue';
import PropTypes from 'vue-types';

export default {
    name: 'StatusIcon',
    props: {
        completed: PropTypes.string,
        tooltipText: PropTypes.string,
    },

    setup(props) {
        return {
            className: computed(() => {
                return {
                    'status-icon': true,
                    'status-icon--completed': props.completed,
                };
            }),
            tooltip: computed(() => {
                if (props.tooltipText) return props.tooltipText;

                return props.completed ? 'Pending' : 'Completed';
            }),
        };
    },
};
</script>

<style lang="scss" scoped>
@import 'style/dext/includes';

.status-icon {
    color: get-color(orange);
    font-size: 16px;
}

.status-icon--completed {
    color: get-color(green);
}
</style>
