import { inject } from 'vue';

import logger from '@/utils/logger';

import type { FormContextApi } from '../Form.types';

export const FormContext = Symbol('FormContext');

export function useFormContext<TFormState>(componentName: string, options = { suppressWarnings: false }) {
    const context = inject<FormContextApi<TFormState>>(FormContext);

    if (!context && !options.suppressWarnings) {
        logger.warn(`<${componentName} /> is missing a parent <FormLayout /> component.`);
    }

    return context;
}
