import type { RouteConfig } from 'vue-router';

const clientsRoutes: RouteConfig[] = [
    {
        path: 'clients/bulk-edit',
        component: () => import('@/scenes/BulkClientSettings'),
        name: 'team.clients.bulkSettings',
    },
];

export { clientsRoutes };
