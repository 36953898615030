<template>
    <a v-if="!disabled" class="secondary-nav-link btn btn-outline-secondary" title="Export to PDF" @click="exportPDF()">
        <VIcon :name="exporting ? 'sync' : 'file-pdf-thick'" :spin="exporting" />Export
    </a>
    <a
        v-else
        v-tooltip="disabledMessage"
        class="secondary-nav-link disabled text-muted btn btn-outline-secondary"
        :disabled="disabled"
        @click.stop.prevent=""
    >
        <VIcon name="file-pdf-thick" /> Export
    </a>
</template>

<script>
import axios from 'axios';

import useXavierGlobals from '@/hooks/useXavierGlobals';
import { triggerDownload } from '@/utils/browser';

export default {
    name: 'ExportToPDF',
    props: {
        clientId: { type: String, required: true },
        insight: { type: String, required: true },
        mode: { type: String, default: 'dashboard' },
        disabled: { type: Boolean, default: false },
        disabledMessage: { type: String, default: 'Export to PDF not possible' },
        exportPath: { type: String, default: null },
    },
    data() {
        return {
            exporting: false,
        };
    },
    computed: {
        exportUrl() {
            const practiceCrn = useXavierGlobals().currentTeam.rbExternalId;
            const base = `/api/teams/${practiceCrn}/client/${this.clientId}/`;
            const mode = `?mode=${this.mode}`;

            return this.exportPath ? base + this.exportPath + mode : base + `insight/${this.insight}/pdf` + mode;
        },
    },
    methods: {
        async exportPDF() {
            if (!this.exporting) {
                this.exporting = true;

                try {
                    const request = {
                        method: 'get',
                        responseType: 'blob',
                        url: this.exportUrl,
                    };

                    const response = await axios(request);

                    if (response.headers['content-type'] === 'application/pdf') {
                        const reportName = this.insight === 'go-proposal' ? 'activity-stats' : this.insight;

                        triggerDownload(`${reportName}_${moment().format('YYYY-MM-DD_HH-mm')}.pdf`, response.data);
                    } else if (response.headers['content-type'] === 'application/json') {
                        const content = JSON.parse(await response.data.text());

                        if (content.message) {
                            this.$toaster.success(content.message, { timeout: 10000 });
                        }
                    }
                } catch (ex) {
                    this.$toaster.error(
                        'Something went wrong while generating your report. Please try again or contact our support team.'
                    );

                    throw ex;
                } finally {
                    this.exporting = false;
                }
            }
        },
    },
};
</script>
