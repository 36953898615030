import type { RouteConfig } from 'vue-router';

import { Feature } from '@/enums/Feature';
import ClientLayout, { ClientSidebar } from '@/layout/ClientLayout';

import store from '@/store';
import clientStore, { clientInitialState } from '@/store/modules/client/client.store';
import type { ClientState } from '@/store/modules/client/types/Store';
import useIsFeatureEnabled from '@/store/modules/feature/hooks/useIsFeatureEnabled';

import { checkRoutes } from './check';
import { cleanupRoutes } from './cleanup';
import { customReportRoutes } from './custom-report';
import { flowRoutes } from './flow';
import { focusRoutes } from './focus';
import { insightRoutes } from './insight';
import { resilienceRoutes } from './resilience';
import { settingsRoutes } from './settings';

import { extractClientIdParam } from '../utils';

const hasCustomHealthScoreEnabled = useIsFeatureEnabled(Feature.CUSTOM_HEALTH_SCORES);

const clientRoutes: RouteConfig[] = [
    {
        path: '/client/:clientSlug',
        props: extractClientIdParam,
        component: ClientLayout,
        beforeEnter(to, _from, next) {
            const { clientSlug } = to.params;
            const moduleName = `client_${clientSlug}`;
            const moduleExists = Boolean(store.state.clients[`client_${clientSlug}`]);
            const { clientId } = extractClientIdParam(to);

            if (!moduleExists) {
                store.registerModule(['clients', moduleName], {
                    ...clientStore,
                    state(): ClientState {
                        return {
                            ...clientInitialState,
                            data: { slug: clientSlug, id: clientId },
                        };
                    },
                });
            }

            next();
        },
        meta: {
            sidebar: ClientSidebar,
            props: extractClientIdParam,
            sidebarStacked: true,
        },
        children: [
            ...checkRoutes,
            ...cleanupRoutes,
            ...customReportRoutes,
            ...flowRoutes,
            ...focusRoutes,
            ...insightRoutes,
            ...resilienceRoutes,
            ...settingsRoutes,
            {
                path: '',
                component: () =>
                    hasCustomHealthScoreEnabled
                        ? import('@/scenes/ClientOverview/ClientOverviewSceneContainer.vue')
                        : import('@/scenes/ClientOverview_LEGACY/ClientOverviewScene.vue'),
                props: extractClientIdParam,
                name: 'client.overview',
            },
            {
                path: 'legacy-overview',
                component: () => import('@/scenes/ClientOverview_LEGACY/ClientOverviewScene.vue'),
                props: extractClientIdParam,
                name: 'client.overview_legacy',
            },
            {
                path: 'health-score',
                component: () => import('@/scenes/ClientHealthScore/ClientHealthScoreSceneContainer.vue'),
                props: extractClientIdParam,
                name: 'client.overview.health-score',
            },
            {
                path: 'benchmarking',
                component: () => import('@/scenes/ClientBenchmarking/ClientBenchmarkingScene.vue'),
                name: 'client.benchmarking',
            },
            {
                path: 'reports',
                component: () => import('@/scenes/ReportList/ReportListScene.vue'),
                props: extractClientIdParam,
                name: 'client.reports',
            },
            {
                path: 'sandbox',
                component: () => import('@/scenes/Sandbox/SandboxScene.vue'),
                props: extractClientIdParam,
                name: 'client.sandbox',
            },
        ],
    },
];

export { clientRoutes };
