<template>
    <ErrorLayout>
        <template #title>Something doesn't add up... (code 404)</template>

        <template #image>
            <img alt="Simple calculator with 404 on the display" src="/img/404-calculator.svg" />
        </template>

        <p>This page might have been moved or deleted.</p>
        <p>
            Go back to the
            <DButton link name="Error 404 link to home" text :to="{ name: 'app.home' }">homepage</DButton>.
        </p>
    </ErrorLayout>
</template>

<script setup lang="ts">
import { DButton } from '@/components/buttons';
import { ErrorLayout } from '@/layout/ErrorLayout';
</script>

<script lang="ts">
export default { name: 'Error404NotFound' };
</script>
