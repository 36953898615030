<template>
    <a
        v-if="!disabled"
        class="secondary-nav-link btn btn-outline-secondary"
        title="Export to XLSX"
        @click="exportExcel()"
    >
        <VIcon :name="exporting ? 'sync' : 'file-excel-thick'" :spin="exporting" />
        Export
    </a>
    <a
        v-else
        v-tooltip="disabledMessage"
        class="secondary-nav-link disabled text-muted btn btn-outline-secondary"
        :disabled="disabled"
        @click.stop.prevent=""
    >
        <VIcon name="file-excel-thick" /> Export
    </a>
</template>

<script>
import axios from 'axios';

import useXavierGlobals from '@/hooks/useXavierGlobals';
import { triggerDownload } from '@/utils/browser';

export default {
    name: 'ExportToExcel',
    props: {
        clientId: { type: String, required: true },
        insight: { type: String, required: true },
        exportPath: { type: String, default: null },
        mode: { type: String, default: 'dashboard' },
        disabled: { type: Boolean, default: false },
        disabledMessage: { type: String, default: 'Export not possible' },
    },
    data() {
        return {
            exporting: false,
        };
    },
    computed: {
        exportUrl() {
            const practiceCrn = useXavierGlobals().currentTeam.rbExternalId;
            const base = `/api/teams/${practiceCrn}/client/${this.clientId}/`;
            const mode = `?mode=${this.mode}`;

            return this.exportPath ? base + this.exportPath + mode : base + `insight/${this.insight}/excel` + mode;
        },
    },
    methods: {
        async exportExcel() {
            if (!this.exporting) {
                this.exporting = true;

                try {
                    const request = {
                        method: 'get',
                        responseType: 'blob',
                        url: this.exportUrl,
                    };
                    const results = await axios(request);

                    // TODO - rename go-proposal insight
                    const reportName = this.insight === 'go-proposal' ? 'activity-stats' : this.insight;

                    triggerDownload(`${reportName}_${moment().format('YYYY-MM-DD_HH-mm')}.xlsx`, results.data);
                } catch (ex) {
                    this.$toaster.error(
                        'Something went wrong while generating your report. Please try again or contact our support team.'
                    );

                    throw ex;
                } finally {
                    this.exporting = false;
                }
            }
        },
    },
};
</script>
