// @ts-check
import Vue from 'vue';

const component = Vue.extend({
    methods: {
        /**
         * Check if the current component has the slot name provided.
         *
         * @param {String} name
         * @returns Boolean
         */
        hasSlot(name = 'default') {
            return Boolean(this.$slots[name] || this.$scopedSlots?.[name]) ?? false;
        },
    },
});

export default component;
