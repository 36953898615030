import Vue from 'vue';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

export default function createEventListeners(store) {
    const { userId, teamId } = window.Xavier;

    if (window.Xavier.config.pusherLogToConsole) {
        Pusher.logToConsole = true;
    }

    window.Pusher = Pusher; // Required by Laravel Echo

    let echoParams;

    if (window.Xavier.config.env === 'local') {
        echoParams = {
            authEndpoint: '/broadcasting/auth',
            broadcaster: 'pusher',
            key: window.Xavier.config.pusherAppKey,
            wsHost: window.Xavier.config.pusherHost,
            wsPort: window.Xavier.config.pusherPort,
            wssPort: window.Xavier.config.pusherPort,
            forceTLS: window.Xavier.config.pusherScheme === 'https',
            enabledTransports: ['ws', 'wss'],
        };
    } else {
        echoParams = {
            authEndpoint: '/broadcasting/auth',
            broadcaster: 'pusher',
            key: window.Xavier.config.pusherAppKey,
            cluster: window.Xavier.config.pusherAppCluster,
            encrypted: true,
        };
    }

    const echo = new Echo(echoParams);

    echo.channel('global').listen('NewVersionAvailable', () => {
        Vue.prototype.$toaster.info('A new version of Dext Precision is available. Please refresh your browser.', {
            timeout: 60000,
        });
    });

    echo.private(`App.User.${userId}`)
        .listen('FlowCreationStarted', ({ totalFlows, batchId }) =>
            window.Bus.$emit('flowCreationStarted', { totalFlows, batchId })
        )
        .listen('FlowCreationProgress', ({ batchId }) => window.Bus.$emit('flowCreationProgress', { batchId }))
        .listen('FlowCreationComplete', ({ batchId }) => {
            window.Bus.$emit('flowCreationCompleted', { batchId });
            store.dispatch('flows/loadFlowList');
        })
        .listen('FlowCreationFailed', ({ batchId }) => {
            window.Bus.$emit('flowCreationFailed', { batchId });
            Vue.prototype.$toaster.error(
                'Something went wrong while creating your Flows. Please try again or contact our support team.'
            );
        })
        .listen('FixSuggestionStarted', ({ count, batchId, type }) =>
            window.Bus.$emit('fixSuggestionStarted', { count, batchId, type })
        )
        .listen('FixSuggestionFailed', ({ fix, type, batchId, lastJob }) =>
            window.Bus.$emit('fixSuggestionFailed', { fix, type, batchId, lastJob })
        )
        .listen('FixSuggestionProgress', ({ count, batchId }) =>
            window.Bus.$emit('fixSuggestionProgress', { count, batchId })
        )
        .listen('FixSuggestionCompleted', ({ count, batchId, type }) =>
            window.Bus.$emit('fixSuggestionCompleted', { count, batchId, type })
        )
        .notification((event) => {
            if (event.toast) {
                Vue.prototype.$toaster.success(event.toast);
            }

            if (event.type === 'App\\Notifications\\TeamSwitched') {
                store.dispatch('teams/setNewTeam', event.teamId);
            }

            if (event.type === 'App\\Notifications\\TeamSwitchedDueToSuspension') {
                // we need a bit of a pause here for any async API calls
                // to finish
                //
                // otherwise, the orange toaster message that gets set in
                // TeamController@refreshTeam does not reliably appear
                setTimeout(() => {
                    window.location = '/refresh-team?teamSuspended=1';
                }, 5000);
            }

            window.Bus.$emit('updateUserData');
        });

    echo.private(`team.${teamId}`)
        .listen('ImportStarted', (importData) => store.commit('imports/UPSERT_IMPORT', importData))
        .listen('ImportCompleted', (importData) => store.commit('imports/UPSERT_IMPORT', importData))
        .listen('ClientUpdated', ({ clientId }) => store.dispatch('legacyClients/loadClient', clientId))
        .listen('ReportCreated', () => store.dispatch('reports/loadReports'))
        .listen('ReportDeleted', () => store.dispatch('reports/loadReports'))
        .listen('TeamProductsUpdated', ({ teamProducts }) => window.Bus.$emit('teamProductUpdate', teamProducts));

    return echo;
}
