import { defineStore, acceptHMRUpdate } from 'pinia';

import { useUUID } from '@/mixins/uuid';

import type { Toast, ToastsState } from './AppStore.types';
import type { Flatten } from '@/types/utils';

const useToastsStore = defineStore('toasts.store', {
    state: (): ToastsState => ({
        toasts: [],
    }),
    actions: {
        addToast(toast: Flatten<Omit<Toast, 'id'>>) {
            const uuid = useUUID();

            this.toasts.push({ ...toast, id: uuid });

            return uuid;
        },
        removeToast(id?: string) {
            const toastId = id ?? this.toasts[0]?.id ?? -1;

            this.toasts = this.toasts.filter((toast) => toast.id !== toastId);
        },
    },
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useToastsStore, import.meta.hot));
}

export { useToastsStore };
