import { inject } from 'vue';

import type { ClientContextApi } from '../App.types';

export const ClientContext = Symbol('ClientContext');

export function useClientContext(componentName: string) {
    const context = inject<ClientContextApi>(ClientContext);

    if (!context) {
        throw new Error(`<${componentName} /> is missing a parent <PrecisionAppContainer /> component.`);
    }

    return context;
}
