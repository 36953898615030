<template>
    <Sidebar class="flow-sidebar" data-qa="flow-sidebar">
        <template #prepend>
            <SidebarItem :disabled="isLoading" exact name="Back to focus" :to="previousPage.route">
                <template #icon-left>
                    <VIcon decorative name="chevron-left-thick" :vertical-align="false" />
                </template>
                {{ previousPage.copy }}
            </SidebarItem>

            <SidebarItem name="Focus Mode Completions" no-action>
                <template #icon-left>
                    <VIcon decorative name="list-thick" />
                </template>

                Workflow Mode

                <template #actions v-if="!isLoading">
                    <DBadgeCount class="flow-completion-count">
                        {{ completedChecks }}
                    </DBadgeCount>
                </template>
            </SidebarItem>
        </template>

        <template v-if="!isLoading">
            <FlowMeta :current-flow="currentFlow" />
            <FlowActions
                class="flow-sidebar__actions"
                :current-client="currentClient"
                :current-flow="currentFlow"
                :is-calculating="isCurrentClientCalculating"
                @begin="onFlowBegin"
                @complete="onFlowComplete"
                @finish-review="onReviewFinish"
                @postpone="onFlowPostpone"
            />
        </template>

        <Spinner basic :centered="false" class="flow-sidebar__loader" v-if="isLoading" />
    </Sidebar>
</template>

<script>
import { defineComponent, ref, computed, onBeforeMount, watchEffect } from 'vue';
import { createNamespacedHelpers } from 'vuex-composition-helpers';

import { DBadgeCount } from '@/components/Badge';
import Spinner from '@/components/Spinner';
import { usePreviousRoute } from '@/hooks/useNavigationContext';
import { Sidebar, SidebarItem } from '@/layout/Sidebar';

import FlowActions from './FlowActions';
import FlowMeta from './FlowMeta.vue';
import { useRouter } from '@/composables/useRouter';

const { useGetters: useClientGetters } = createNamespacedHelpers('legacyClients');
const { useActions: useFlowActions, useGetters: useFlowGetters } = createNamespacedHelpers('flows');
const { useGetters: useImportsGetters } = createNamespacedHelpers('imports');

export default defineComponent({
    name: 'FlowSidebar',

    components: {
        DBadgeCount,
        FlowActions,
        FlowMeta,
        Sidebar,
        SidebarItem,
        Spinner,
    },

    setup() {
        const previousRoute = usePreviousRoute();
        const router = useRouter();
        const { currentClient } = useClientGetters(['currentClient']);
        const { currentFlow } = useFlowGetters(['currentFlow']);
        const { isClientImporting, findImportByClientIdAndFlowId } = useImportsGetters([
            'isClientImporting',
            'findImportByClientIdAndFlowId',
        ]);
        const { completeFlow, completeFlowReview, selectFlow, startFlow } = useFlowActions([
            'completeFlow',
            'completeFlowReview',
            'selectFlow',
            'startFlow',
        ]);

        const isCurrentClientCalculating = computed(() => {
            if (currentClient && isClientImporting.value(currentClient.id)) {
                const importData = findImportByClientIdAndFlowId.value(currentClient.id, currentFlow.id);

                return Boolean(importData);
            }

            return false;
        });

        const isLoading = ref(false);

        const previousPage = ref({
            copy: 'Back to Team',
            route: { name: 'team.home' },
        });

        const completedChecks = computed(() => {
            if (!currentFlow.value) {
                return '0/0';
            }

            return `${currentFlow.value.completedChecks}/${currentFlow.value.totalChecks}`;
        });

        const onFlowBegin = async (flowId) => {
            await startFlow(flowId);
            selectFlow(flowId);
        };

        const onFlowPostpone = () => {
            router?.push(previousPage.value.route);
        };

        const onFlowComplete = (flowId) => {
            completeFlow(flowId);
            router?.push(previousPage.value.route);
        };

        const onReviewFinish = (flowId) => {
            completeFlowReview(flowId);
            router?.push(previousPage.value.route);
        };

        /**
         * Watch for changes to the `previousRoute`. This is how we decide
         * whether the back button displays for teams or for the client. By
         * default we choose the client focus screen as we are inside a client,
         * but the user may have come from the `team.focus` screen.
         */
        watchEffect(() => {
            const cameFromTeamFocus = previousRoute.value?.name.includes('team.focus') ?? false;

            isLoading.value = !(currentClient.value && currentFlow.value);

            if (cameFromTeamFocus) {
                previousPage.value = {
                    copy: 'Back to Team Workflows',
                    route: { name: previousRoute.value.name },
                };

                return;
            }

            if (currentClient.value) {
                previousPage.value = {
                    copy: 'Back to Client Workflows',
                    route: {
                        name: 'client.focus',
                        params: { clientSlug: currentClient.value.slug },
                    },
                };
            }
        });

        onBeforeMount(() => {
            isLoading.value = true;
        });

        return {
            completedChecks,
            currentClient,
            currentFlow,
            isCurrentClientCalculating,
            isLoading,
            onFlowBegin,
            onFlowComplete,
            onFlowPostpone,
            onReviewFinish,
            previousPage,
        };
    },
});
</script>

<style lang="scss" scoped>
@import 'style/dext/includes';

.flow-sidebar__loader,
.flow-sidebar__actions {
    margin-top: 16px;
}
</style>
