<template>
    <div ref="modal" v-bind="modalAttributes" class="modal" role="dialog" tabindex="-1">
        <div :class="['modal-dialog modal-dialog-centered', size ? `modal-dialog-${size}` : null]" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-if="title">
                        {{ title }}
                    </h5>
                    <button
                        aria-label="Close"
                        class="btn btn-outline-secondary close"
                        type="button"
                        @click.prevent="requestClose()"
                        v-if="dismissable"
                    >
                        <VIcon decorative name="close-thick" size="12px" />
                    </button>
                </div>
                <slot name="content" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: { type: String, default: null },
        size: { type: String, default: null },
        bubbleClose: { type: Boolean, default: false },
        dismissable: { type: Boolean, default: true },
        emitCloseOnOutsideClick: { type: Boolean, default: false },
    },
    data() {
        return {
            modalAttributes: null,
        };
    },
    created() {
        if (this.emitCloseOnOutsideClick) {
            window.addEventListener('click', this.onOutsideClick);
        }
    },
    beforeDestroy() {
        this.close();

        if (this.emitCloseOnOutsideClick) {
            window.removeEventListener('click', this.onOutsideClick);
        }
    },
    destroyed() {
        if (this.emitCloseOnOutsideClick) {
            window.removeEventListener('click', this.onOutsideClick);
        }
    },
    mounted() {
        if (!this.dismissable) {
            this.modalAttributes = {
                'data-backdrop': 'static',
                'data-keyboard': 'false',
            };
        }
    },
    methods: {
        onOutsideClick(event) {
            const isOutsideClick = this.$refs.modal && this.$refs.modal === event.target;
            const canClose = isOutsideClick && this.emitCloseOnOutsideClick && this.dismissable;

            if (canClose) {
                this.$emit('close');
            }
        },
        show() {
            $(this.$refs.modal).modal('show');
        },
        close() {
            $(this.$refs.modal).modal('hide');
        },
        requestClose() {
            if (this.bubbleClose) {
                this.$emit('close');
            } else {
                this.close();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import 'style/dext/includes';

.modal-dialog-lg {
    max-width: 600px;
}

.modal-dialog-xl {
    max-width: 900px;
}

.modal-content {
    background-color: $color-white;
    border: 0;
    border-radius: 4px;
    box-shadow: 0 8px 16px rgba($color-black, 0.1);
    padding: 24px;
}

.modal-header {
    padding: 0;

    .close {
        height: 30px;
        margin: 0;
        padding: 0;
        position: absolute;
        right: 15px;
        top: 15px;
        width: 30px;
    }
}

.modal-title {
    color: get-color(orange);
    font-size: pxtoem(16);
    font-weight: $font-weight-bold;
    margin-top: -7px;
}
</style>
