import type { RouteConfig } from 'vue-router';

import { extractClientIdParam } from '../utils';

const customReportRoutes: RouteConfig[] = [
    {
        path: 'custom-report/:reportId',
        component: () => import('@/scenes/Sandbox/SandboxScene.vue'),
        props: extractClientIdParam,
        name: 'client.customReport.details',
    },
];

export { customReportRoutes };
