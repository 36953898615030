import Hashids from 'hashids';

const hashids = new Hashids(window.Xavier.config.hashIdKey, 5);

export function encode(id) {
    return hashids.encode(id);
}

export function decode(slug) {
    return String(hashids.decode(slug)[0]);
}
