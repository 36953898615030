<template>
    <div class="sidebar-group" :data-qa="groupName">
        <SidebarItem
            v-if="hasChildren"
            v-bind="{ ...$props, ...$attrs }"
            :active="isChildActive"
            :name="groupName"
            @click="onItemClick"
        >
            <slot name="activator" />

            <template #actions>
                <VIcon class="sidebar-group__icon" :name="iconName" size="21px" />
            </template>

            <template v-if="hasSlot('icon-left')" #icon-left="data">
                <slot v-bind="data" name="icon-left" />
            </template>
        </SidebarItem>

        <Items class="sidebar-group__items" :pose="isVisible ? 'enter' : 'exit'">
            <slot />
        </Items>
    </div>
</template>

<script>
import omit from 'lodash-es/omit';
import { defineComponent } from 'vue';
import posed from 'vue-pose';
import { ReactiveProvideMixin } from 'vue-reactive-provide';
import PropTypes from 'vue-types';

import easing from '@/enums/easing';
import SlotValidationError from '@/errors/SlotValidationError';
import hasSlotMixin from '@/mixins/hasSlot';

import SidebarItem from './SidebarItem.vue';

export default defineComponent({
    name: 'SidebarGroup',

    model: {
        prop: 'visible',
        event: 'visibility-change',
    },

    props: {
        ...SidebarItem.props,
        visible: PropTypes.bool.def(false),
    },

    mixins: [
        hasSlotMixin,
        ReactiveProvideMixin({
            name: 'sidebarGroup',
            include: ['isChildActive', 'isDisabled', 'isVisible', 'name', 'register', 'unregister'],
        }),
    ],

    data() {
        return {
            isChildActive: false,
            isDisabled: Boolean(this.disabled),
            isVisible: Boolean(this.visible),
            items: {},
        };
    },

    components: {
        SidebarItem,
        Items: posed.div({
            enter: {
                height: 'auto',
                transition: {
                    duration: 250,
                    ease: easing.CUBIC_BEZIER,
                },
            },
            exit: {
                height: 0,
                transition: {
                    duration: 200,
                    ease: easing.CUBIC_BEZIER,
                },
            },
        }),
    },

    computed: {
        /**
         * Generate props to pass to the activating element scoped
         * slot (if provided).
         *
         * @returns Object
         */
        activatorSlotProps() {
            return {
                active: this.isChildActive,
                disabled: this.isDisabled,
            };
        },

        /**
         * Generate name used for analytics and QA
         *
         * @returns String
         */
        groupName() {
            return `Sidebar Group ${this.name}`;
        },

        /**
         * Does this group have any children.
         *
         * @returns Boolean
         */
        hasChildren() {
            return Object.values(this.items).length > 0;
        },

        /**
         * Generate chevron icon used for indicating a group open / closed.
         *
         * @returns String
         */
        iconName() {
            return this.isVisible ? 'chevron-up-thick' : 'chevron-down-thick';
        },
    },

    watch: {
        isVisible(value) {
            Boolean(value) !== this.visible && this.$emit('visibility-change', value);
        },

        visible(value) {
            if (this.isDisabled) return;
            this.isVisible = Boolean(value);
        },

        $route() {
            this.setContentVisibility();
        },
    },

    methods: {
        /**
         * Displays/opens the group
         */
        open() {
            if (this.disabled) return;

            this.isVisible = true;
        },
        /**
         * Handle item click. Toggles the visibility of the group.
         */
        onItemClick() {
            if (this.disabled) return;

            this.isVisible = !this.isVisible;
        },
        /**
         * Register any component to the Group Instance.
         *
         * @param {String|Number} id
         * @param {VNode} item
         */
        register(id, instance) {
            this.$set(this.items, id, instance);
        },

        async setContentVisibility() {
            await this.$nextTick();
            await this.$nextTick();

            const hasActiveDescendent = Object.values(this.items).some((item) => {
                return item?.isCurrentRoute;
            });

            this.isVisible = hasActiveDescendent;
            this.isChildActive = hasActiveDescendent;
        },

        /**
         * Unregister a registered component from the Group Instance.
         *
         * @param {String|Number} id
         */
        unregister(id) {
            this.items = omit(this.items, [id]);
        },
    },

    mounted() {
        if (!this.hasSlot('activator')) {
            throw new SlotValidationError(this.$options.name, 'activator');
        }

        this.setContentVisibility();
    },
});
</script>

<style lang="scss" scoped>
@use 'sass:color';
@import 'style/dext/includes';

.sidebar-group__items {
    background-color: color.adjust(get-color(xavier-custom, blue-dark), $lightness: 5%, $saturation: -10%);
    height: 0;
    overflow: hidden;
    will-change: transform;
}

.sidebar-group__icon {
    margin-left: auto;
}
</style>
