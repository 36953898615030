module.exports = {
    props: ['user'],

    /**
     * The component's data.
     */
    data() {
        return {
            successful: false,
            dailyDigest: true,
        };
    },

    /**
     * Bootstrap the component.
     */
    created() {
        this.dailyDigest = this.user.daily_digest ? true : false;
    },

    methods: {
        update() {
            const data = {
                id: this.user.id,
                dailyDigest: this.dailyDigest
            };

            axios.post('/settings/daily-digest', data)
                .then(response => {
                    //
                }).catch(errors => {
                    console.log(errors);
                }).finally(() => {
                    this.successful = true;
                });
        },
    }
};
