<template>
    <!--mobile version-->
    <div v-if="smallScreen" class="pl-3 pb-3 flex-1 script-card-container__sm flex-grow-1">
        <div class="script-card card mt-3 d-flex align-content-stretch flex-row justify-content-between">
            <p class="mb-0">
                {{ script }}
            </p>
            <h3 class="m-0 ml-2">
                <VIcon @click="toggleScript" name="message-thick" class="pt-1 pointable" />
            </h3>
        </div>
    </div>
    <div v-else-if="scriptExpanded" class="pl-3 largescript flex-1 script-card-container min-width-50 flex-grow-1">
        <div class="script-card mt-3 card d-flex align-content-stretch flex-row justify-content-between">
            <p class="mb-0">
                {{ script }}
            </p>
            <h3 class="m-0 ml-2">
                <VIcon @click="toggleScript" name="message-thick" class="pt-1 pointable" />
            </h3>
        </div>
    </div>
    <div v-else class="script-toggle flex-grow-0 pl-3 flex-1">
        <div class="script-toggle-card mt-3 card pointer card-hover" @click="toggleScript">
            <h3 class="m-0">
                <VIcon name="message-thick" class="pt-1" />
            </h3>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CheckScript',
    props: {
        script: { type: String, default: undefined },
    },
    data() {
        return {
            scriptExpanded: false,
        };
    },
    computed: {
        smallScreen() {
            return window.innerWidth <= 1100;
        },
    },
    methods: {
        toggleScript() {
            this.scriptExpanded = !this.scriptExpanded;
        },
    },
};
</script>

<style lang="scss" scoped>
@import 'style/dext/includes';

.script-card,
.script-toggle-card {
    margin-bottom: 0;
}

.min-width-50 {
    flex: 1 50%;
}

.script-card-container__sm {
    display: none;
}

.script-card {
    background-color: #e3dbe8;
    border-radius: 50px;
    padding: 1em 1.5em 1em 2.5em;
    width: 100%;

    h3:hover {
        color: get-color(blue);
    }
}

.script-toggle-card {
    align-items: center;
    background-color: #e3dbe8;
    border-radius: 50px;
    display: flex;
    height: 59px;
    justify-content: center;
    width: 59px;
}

@media (width <= 1100px) {
    .min-width-50 {
        min-width: 100%;
    }

    .script-card-container__sm {
        display: block;
        padding-left: 0 !important;

        .script-card > h3 {
            pointer-events: none;
        }
    }

    .script-card.card {
        border-radius: 3.5px;
        margin-bottom: 2em;
        padding: 1em;
    }

    .script-toggle,
    .largescript {
        display: none;
    }
}
</style>
