import * as slug from '@/utils/slug';

import type { Route } from 'vue-router';

function extractClientIdParam(route: Route) {
    const clientInfo = window.Xavier.rbExternalIdToClientIdMap[route.params!.clientSlug] || null;
    const routeSlug = clientInfo ? clientInfo.clientSlug : route.params?.clientSlug;

    return { clientId: slug.decode(routeSlug), ...route.params };
}

/**
 * Extracted method from Vue Router. This is how
 * they compare two Route objects
 *
 * @param a
 * @param b
 * @returns {this is string[]|boolean}
 */
function isObjectEqual(a, b) {
    if (a === void 0) a = {};
    if (b === void 0) b = {};

    if (!a || !b) {
        return a === b;
    }

    const aKeys = Object.keys(a);
    const bKeys = Object.keys(b);

    if (aKeys.length !== bKeys.length) {
        return false;
    }

    return aKeys.every(function (key) {
        const aVal = a[key];
        const bVal = b[key];

        // check nested equality
        if (typeof aVal === 'object' && typeof bVal === 'object') {
            return isObjectEqual(aVal, bVal);
        }

        return String(aVal) === String(bVal);
    });
}

const START = '/';
const trailingSlashRE = /\/?$/;

/**
 * Extracted method from Vue Router. That is how
 * they compare two routes
 *
 * @param routeA
 * @param routeB
 *
 * @returns boolean
 */
export function isSameRoute(routeA, routeB) {
    if (routeB === START) {
        return routeA === routeB;
    } else if (!routeB) {
        return false;
    } else if (routeA.path && routeB.path) {
        return (
            routeA.path.replace(trailingSlashRE, '') === routeB.path.replace(trailingSlashRE, '') &&
            routeA.hash === routeB.hash &&
            isObjectEqual(routeA.query, routeB.query)
        );
    } else if (routeA.name && routeB.name) {
        return (
            routeA.name === routeB.name &&
            routeA.hash === routeB.hash &&
            isObjectEqual(routeA.query, routeB.query) &&
            isObjectEqual(routeA.params, routeB.params)
        );
    } else {
        return false;
    }
}

export { extractClientIdParam, extractWorkflowIdParam };
